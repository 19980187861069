import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAccessToken } from "redux/user/user-selector";

const PrivateRoute = ({ accessToken, children, ...props }) => (
  <Route
    {...props}
    render={({ location }) => {
      return accessToken ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: "/auth",
            state: { from: location },
          }}
        />
      );
    }}
  />
);

const mapStateToProps = createStructuredSelector({
  accessToken: selectAccessToken,
});

export default connect(mapStateToProps)(PrivateRoute);
