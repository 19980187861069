import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { fetchPrimeStart } from "redux/shop/shop-actions.js";
import { selectPrime } from "redux/shop/shop-selector.js";
import SpinnerOverlay from "components/with-spinner/spinner-overlay.jsx";
import PrimeCard from "components/prime-card/prime-card.jsx";
import {
  PrimeContainer,
  Head,
  Logo,
  CheckIcon,
  PrimeLogo,
  Title,
  Body,
} from "./prime.styles.jsx";

const Prime = ({ fetchPrime, prime }) => {
  useEffect(() => {
    fetchPrime();
  }, [fetchPrime]);

  if (!prime || prime?.isFetchingPrime) return <SpinnerOverlay />;
  if (prime?.plans) {
    return (
      <PrimeContainer>
        <Head>
          <Logo />
          <PrimeLogo>
            <CheckIcon /> <span>Prime</span>
          </PrimeLogo>
        </Head>
        <Title>Select a plan for you</Title>
        <Body>
          {prime.plans.map((plan) => (
            <PrimeCard key={plan.id} plan={plan} />
          ))}
        </Body>
      </PrimeContainer>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  prime: selectPrime,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPrime: () => dispatch(fetchPrimeStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Prime);
