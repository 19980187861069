import styled, { css } from "styled-components";

export const ProductsOverviewConatiner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1.5rem 0;
  ${(props) =>
    props.highlight &&
    css`
      justify-content: center;
    `}
  & > * {
    flex: 0 0 calc(20% - 1rem);
    margin: 1rem 0.5rem;
    ${(props) =>
      props.highlight &&
      css`
        margin: 1rem;
        flex: 0 0 calc(25% - 2rem);
      `}
  }

  @media screen and (max-width: 480px) {
    align-items: strech;
    margin: 0.5rem 0;
    & > * {
      flex: 0 0 calc(50% - 1rem);
      margin: 1rem 0.5rem;
      ${(props) =>
        props.highlight &&
        css`
          &:nth-child(odd) {
            margin: 0 0.5rem 1rem 0;
          }
          &:nth-child(even) {
            margin: 0 0 1rem 0.5rem;
          }
          flex: 0 0 calc(50% - 1.5rem);
        `}
    }
  }

  @media screen and (min-width: 480px) and (max-width: 1024px) {
    align-items: strech;
    margin: 0.5rem 0;
    & > * {
      flex: 0 0 calc(25% - 1rem);
      margin: 1rem 0.5rem;
    }
  }
`;
