import styled from "styled-components";
import { ReactComponent as WarningSVG } from "assets/caution.svg";
import { ReactComponent as RefreshSVG } from "assets/refresh.svg";

export const RetryBoxContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  padding: 1rem 0.5rem;
  cursor: pointer;
  justify-content: center;
`;
export const WarningMessage = styled.p`
  font-size: 1.8rem;
  margin: 0 1.2rem;
`;

export const WarningIcon = styled(WarningSVG)`
  height: 20px;
  width: 20px;
`;

export const RefreshIcon = styled(RefreshSVG)`
  height: 18px;
  width: 18px;
  fill: #2ec370;
`;

