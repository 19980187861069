import styled from "styled-components";

export const ProductsByBrandContainer = styled.div`
  margin: 3rem 0;
  @media screen and (max-width: 480px) {
    margin: 0;
  }
`;

export const ErrorContainer = styled.div`
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
