import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import { persistStore } from "redux-persist";
import rootReducer from "./root-reducer.js";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./root-saga.js";

const sagaMiddleWare = createSagaMiddleware();
const middleWares = [sagaMiddleWare];

if (process.env.NODE_ENV === "development") {
  middleWares.push(logger);
}

export const store = createStore(rootReducer, applyMiddleware(...middleWares));
export const persistor = persistStore(store);

sagaMiddleWare
  .run(rootSaga, store.dispatch)
  .toPromise()
  .catch((e) => {
    logger.error({
      message: e.message,
      source: "sagaError",
      stacktrace: e.sagaStack,
    });
  });
