import styled from "styled-components";

export const PageContainer = styled.div`
	background: #fff;
	border-radius: 7px;
	padding: 6rem;
	font-size: 1.6rem;
	max-width:1170px;
	margin: 0 auto;
	
	@media screen and (max-width: 480px){
		padding: 3rem;
		font-size: 1.7;
	}

	h1 {
		letter-spacing: 1.7;
		text-align:center;
		font-size:3.15rem
		margin-bottom: 3rem;

		@media screen and (max-width: 480px){
			font-size: 2.7rem;
		}
	}
	h3{
		font-size: 2.5rem;
		margin: 2rem 0 .7rem;

		@media screen and (max-width: 480px){
			font-size: 2.25rem;
		}
	}	
	p{
		line-height:1.5
		margin-bottom: 2.5rem;
	}
	ol{
		margin-bottom: 2rem;
		li{
			margin-bottom: .7rem;
		}
	}
`;
