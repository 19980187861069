import React from "react";
import { PageContainer } from "./common.styles.jsx";

const AboutPage = () => (
  <PageContainer>
    <h1>ABOUT US</h1>
    <p>
      Goodoor is Kerala's quickest developing on the web market.We are a
      portable android application commercial center for staple goods, produce,
      prepared to-eat groceries, homeware, wellbeing and magnificence items, pet
      consideration basics, fish and meat , natural vegetables and foods grown
      from the ground more. We are focused on streamlining your day by day
      shopping background with crisp items, top brands and spot-on conveyance.
      At Goodoor, every one of our customers are ensured quality, dependable
      coordinations and chose conveyance space client administration. Our online
      shop features in excess of 2000 items at low edges, sliced costs and
      offers. These items are prepared for checkout with money down and online
      installment through banks or prevalent e-wallets. Goodoor is bringing
      quality, innovation and openness under one stage for all your home needs.
      Goodoor Initially operates in several areas. Our serviceable delivery
      locations include Thodupuzha,Muvattupuzha and Vazhakulam. The Goodoor
      online basic food item application guarantees a huge amount of value items
      made accessible for buy at a touch.This just methods you never again need
      to experience the repetitive procedure of shopping at your neighborhood
      comfort store. With a couple of minutes spent on the application, you can
      without much of a stretch settle on your decisions, request them to your
      location and pay in a split second or at the season of conveyance. At
      Goodoor, timely delivery is something that we strictly adhere to. Our
      zero-compromise policies of service assure a high quality of products and
      customer understanding.
    </p>
    <h3>How It Works</h3>
    <p>
      {" "}
      Most importantly, download the Goodoor application or log in to where you
      can peruse through different items or quest for a specific thing. A choice
      to add items to the shopping basket is constantly unmistakable on screen.
      When you are done, you will be coordinated to the location and conveyance
      screen which contains fields to include a location. Here, you will
      likewise have the option to pick an appropriate time for conveyance
      dependent on your booking. For booking of 7.00am to 9.00 am client can
      pick conveyance delivery time of 10.00am to 1.00 pm and 3.00pm to 6.00pm.
      The booking after 9.00am, the clients can pick just 3.00pm to 6.00pm
      conveyance space. Goodoor will convey to your home a similar day as your
      request. This gives you a chance to continue ahead with the remainder of
      your day's worth of effort without the need to race to the close-by
      general store. Our quick conveyance times likewise deal with undesirable
      problems, for example, queueing at the counter, managing clumsy shop floor
      laborers and fuel costs for congested driving conditions.
    </p>
    <h3>Overview Of Team</h3>
    <p>
      The workforce at Goodoor is submitted towards consistent activity of both
      the client saw front end stage and backend schedules. With complete
      spotlight on giving open staple readily available, the Goodoor group has
      been working energetically for accomplishing a 100% consumer loyalty,
      following severe rules and nourishment industry benchmarks. Back-end
      processes such as stock management, logistics, app development and website
      maintenance are carefully looked into by experienced professionals. Apps
      are always swamped with a large amount of data from users. This is managed
      through state-of-the-art servers and efficient techniques of data
      management by Goodoor’s coding team. Ensuring the availability of popular
      products to customers during peak times is a difficult task. This is made
      easy with the help of management personnel, warehouse workers and a fleet
      of drivers.
    </p>
    <h3>Why Choose Us</h3>
    <p>
      Goodoor is changing the manner in which you shop, a single tick at any
      given moment. With the Goodoor application, you can helpfully peruse
      through an assortment of items and request to your doorstep from any place
      you are. The Goodoor application is dependably the simpler choice
      contrasted with venturing out to the neighborhood store. You shouldn't be
      stuck in rush hour gridlock or conveying packs that burden your shoulders.
      Neither will you be representing hours trusting that the request will be
      checked, paid for and stuffed. As Kerala's quickest developing on the
      web/app grocery store, we have everything that you are searching for and
      that's just the beginning! Goodoor's rundown is more than 2000 distinct
      items from a portion of the world's driving brands. Be it meat, fish and
      dairy items or kitchen and home basics, Goodoor has adequate supply of all
      things in plain view. Unmatched item quality at minor costs is an
      assurance for every one of our clients. Additionally, with conveyance
      areas covering the whole areas in Thodupuzha , Vazhakulam and
      Muvattupuzha, Goodoor is making the following huge wave in Kerala's online
      shopping for food showcase.
    </p>
    <h3>Careers</h3>
    <p>
      At Goodoor, we have a group of devoted people that include stock
      specialists, drivers, software engineers, originators, administrators, and
      so forth., every one of whom are sacrificially bound to the organization's
      legacy and vision. These representatives cooperate for making an incentive
      to the business and its customers, realizing a reasonable biological
      system that sustains in-house ability and advances organization culture.
      As an impression of the quickly changing business sector situation, the
      open doors inside Goodoor are continually testing, driving the workforce
      to the best of their ability, hard working attitude and steadiness. The
      profession ways of a significant number of our representatives is
      verification of their responsibility and our direction over the long haul.
      On the off chance that you are hoping to work in a quickly developing,
      multicultural and eager environment,we clearly have a spot for you.
      Goodoor is helping youthful, talented people make progress through a far
      reaching expectation to learn and adapt in the retail area.
    </p>
  </PageContainer>
);

export default AboutPage;
