import { createSelector } from "reselect";

const selectCheckout = (state) => state.checkout;

export const selectVoucher = createSelector(
  [selectCheckout],
  (checkout) => checkout.voucher
);

export const selectIsFetchingCustomerAddress = createSelector(
  [selectCheckout],
  (checkout) => checkout.isFetchingCustomerAddress
);

export const selectCustomerAddress = createSelector(
  [selectCheckout],
  (checkout) => (checkout.customerAddress ? checkout.customerAddress : [])
);

export const selectActiveAddressID = createSelector(
  [selectCheckout],
  (checkout) => checkout.activeAddressID
);

export const selectCustomerAddressError = createSelector(
  [selectCheckout],
  (checkout) => checkout.customerAddressError
);

export const selectAddressField = createSelector(
  [selectCheckout],
  (checkout) => checkout.addressField
);

export const selectDeliveryTime = createSelector(
  [selectCheckout],
  (checkout) => checkout.deliveryTime?.slice(0, 3) || []
);

export const selectActiveDeliveryTime = createSelector(
  [selectCheckout],
  (checkout) => checkout.activeDeliveryTime || checkout.deliveryTime?.[0]
);

export const selectActivePaymentMethodID = createSelector(
  [selectCheckout],
  (checkout) => checkout.activePaymentMethodID || 1
);

export const selectIsProcessingOrder = createSelector(
  [selectCheckout],
  (checkout) =>
    checkout.isProcessingOrder ? checkout.isProcessingOrder : false
);

export const selectOrderStatus = createSelector([selectCheckout], (checkout) =>
  checkout.orderStatusCode ? checkout.orderStatusCode : 0
);

export const selectOrderID = createSelector(
  [selectCheckout],
  (checkout) => checkout?.orderID
);

export const selectRazorypayOrderID = createSelector(
  [selectCheckout],
  (checkout) => checkout?.razorpayOrderID
);

export const selectRazorpayPaymentID = createSelector(
  [selectCheckout],
  (checkout) => checkout.rzpPaymentID
);

export const selectEarnedSilverCoins = createSelector(
  [selectCheckout],
  (checkout) => checkout?.earnedSilverCoins
);

export const selectIsFetchingUserOrders = createSelector(
  [selectCheckout],
  (checkout) => checkout.isFetchingUserOrders
);

export const selectUserOrders = createSelector(
  [selectCheckout],
  (checkout) => checkout?.userOrders || []
);

export const selectOrderDetail = (orderID) =>
  createSelector(
    [selectCheckout],
    (checkout) => checkout?.orderDetails?.[orderID]
  );
