import React from "react";
import { createStructuredSelector } from "reselect";
import {
  CartIconContainer,
  CartIconSVG,
  CartIconText,
} from "./cart-icon.styles.jsx";
import { connect } from "react-redux";
import { toggleCartDropdown } from "redux/cart/cart-actions.js";
import { selectCartCount } from "redux/cart/cart-selector.js";

const CartIcon = ({ itemCount, toggleCart }) => (
  <CartIconContainer onClick={toggleCart}>
    <CartIconSVG />
    <CartIconText>{itemCount}</CartIconText>
  </CartIconContainer>
);

const mapStateToProps = createStructuredSelector({
  itemCount: selectCartCount,
});
const mapDispatchToProps = (dispatch) => ({
  toggleCart: () => dispatch(toggleCartDropdown()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartIcon);
