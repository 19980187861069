import styled, { css } from "styled-components";

export const CheckoutPage = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CheckoutContainer = styled.div`
  width: 90rem;
  flex: 1;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.05);
  padding: 3rem;
  margin-left: 37rem;
  @media screen and (max-width: 768px) {
    margin-left: 0;
    width: 100%;
    padding: 2rem;
  }
  @media screen and (min-width: 1200px) {
    & > button:last-child {
      margin-left: auto;
      margin-top: -4.5rem;
    }
  }
  @media screen and (max-width: 768px) {
    & > button:last-child {
      width: 100%;
      margin: 1.5rem 0;
    }
  }
`;

export const Title = styled.div`
  font-size: 1.7rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 1.5rem 0;
  color: #777;
  letter-spacing: 1px;
`;

export const SelectBoxGroup = styled.div`
  display: flex;
  button {
    margin-left: auto;
    @media screen and (max-width: 768px) {
      margin-top: 0.5rem;
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SelectBoxItem = styled.div`
  cursor: pointer;
  user-select: none;
  border: 1.5px solid #d7d7d7;
  border-radius: 10px;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: 400;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  color: #aaa;

  span {
    margin-left: 1.5rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    flex: 1;
    margin-bottom: 1rem;
    margin-right: 0;
  }

  ${(props) =>
    props.round &&
    css`
      border-radius: 15px;
    `}

  ${(props) =>
    props.selected &&
    css`
      border: 1px solid #2ec370;
      color: #777;
    `}

  ${(props) =>
    props.active &&
    css`
      color: #777;
      font-size: 1.75rem;
    `}

  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}

	${(props) =>
    props.disabled &&
    css`
      color: #ccc;
      border: 1px solid #e7e7e7;
      cursor: not-allowed;
    `}
`;
