import styled, { css } from "styled-components";
import { ReactComponent as NameSVG } from "assets/name.svg";
import { ReactComponent as PhoneSVG } from "assets/phone.svg";
import { ReactComponent as LocationSVG } from "assets/location.svg";
import { ReactComponent as LockSVG } from "assets/lock.svg";

export const SignInSignUpContainer = styled.div`
  max-width: 40rem;
  margin: 2rem 0;
  background-color: #fff;
  background: rgba(255, 255, 255, 1);
  padding: 3rem 4rem 1.5rem;
  border-radius: 15px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  @media screen and (max-width: 1366px) {
  }

  @media screen and (max-width: 480px) {
    padding: 2rem 2rem 0.5rem;
  }
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  jusify-content: center;
  align-items: center;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
	}


  button {
    min-width: 20rem;
    margin: 1rem 0;
    @media screen and (max-width: 480px) {
			margin: 0 0 1rem;
			width: 100%;
    }
	}
	
	}

`;
export const Title = styled.h1`
  font-size: 2.25rem;
  letter-spacing: 1.5px;
  margin: 1rem 0 2.5rem;
  @media screen and (max-width: 480px) {
    font-size: 2.3rem;
    display: none;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  &:not(last-of-type) {
    margin-bottom: 2rem;
    @media screen and (max-width: 480px) {
      margin-bottom: 0.5rem;
    }
  }

  &:last-of-type {
    margin-bottom: 1.5rem;
    @media screen and (max-width: 480px) {
      margin-bottom: 1rem;
    }
  }

  &:nth-child(1),
  & > * {
    width: 100%;
  }
`;

export const PhoneNumber = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid #d6d6d6;
  select {
    margin-right: 0.5rem;
    width: 15rem;
    color: #777575;
  }
  input {
    border-bottom: none;
  }
`;

const IconCSS = css`
  height: 32px;
  width: 42px;
  margin-right: 2rem;
`;

export const NameIcon = styled(NameSVG)`
  ${IconCSS}
`;

export const PhoneIcon = styled(PhoneSVG)`
  ${IconCSS}
`;

export const LocationIcon = styled(LocationSVG)`
  ${IconCSS}
`;

export const LockIcon = styled(LockSVG)`
  ${IconCSS}
`;
