import React from "react";
import { Link } from "react-router-dom";
import {
  FooterContainer,
  Container,
  Column,
  Logo,
  LogoText,
  Info,
  LinkItem,
  ExternalLink,
  MailIcon,
  InstagramIcon,
  WhatsAppIcon,
  FacebookIcon,
} from "./footer.styles.jsx";

const Footer = () => (
  <FooterContainer>
    <Container>
      <Column>
        <Link to="/">
          <Logo />
          <LogoText />
        </Link>
        <Info>
          1/95 Nanoos Tower, <br />
          Opp Pineapple Market Muvattupuzha, <br />
          Kerala, 686670
        </Info>
        <ExternalLink href="mailto:info@goodoor.in">
          <MailIcon /> info@goodoor.in
        </ExternalLink>
      </Column>
      <Column>
        <LinkItem to="/orders">Orders</LinkItem>
        <LinkItem to="/prime">Prime</LinkItem>
        <LinkItem to="/contact">Contact</LinkItem>
      </Column>
      <Column>
        <LinkItem to="/about">About</LinkItem>
        <LinkItem to="/conditions">Terms &amp; Conditions</LinkItem>
        <LinkItem to="/privacy">Privacy Policy</LinkItem>
      </Column>
      <Column>
        <ExternalLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://instagram.com/goodoor_online_supermarket"
        >
          <InstagramIcon /> Instagram
        </ExternalLink>
        <ExternalLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/Goodoor-The-Online-Super-Market-378936369405648"
        >
          <FacebookIcon />
          Facebook
        </ExternalLink>
        <ExternalLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://wa.me/917902669900"
        >
          <WhatsAppIcon />
          +91 7902669900
        </ExternalLink>
      </Column>
    </Container>
  </FooterContainer>
);

export default Footer;
