import { useState, useEffect } from "react";
import useWindowSize from "../hooks/useWindowSize.js";

const GetProductsOverviewCount = () => {
  const [count, setCount] = useState(0);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width < 480) {
      setCount(2);
    } else if (width < 1024) {
      setCount(4);
    } else {
      setCount(5);
    }
  }, [width]);

  return count;
};

export default GetProductsOverviewCount;
