export const fetchUserCartStart = () => ({
  type: "FETCH_USER_CART_START",
});

export const fetchUserCartSuccess = (userCart) => ({
  type: "FETCH_USER_CART_SUCCESS",
  payload: userCart,
});

export const toggleCartDropdown = () => ({
  type: "TOGGLE_CART_VIEW",
});

export const addItemToCart = (item) => ({
  type: "ADD_TO_CART",
  payload: item,
});

export const addItemToCartSuccess = (updatedCart) => ({
  type: "ADD_TO_CART_SUCCESS",
  payload: updatedCart,
});

export const addCartIDSuccess = (cartID, cartItem) => ({
  type: "ADD_CART_ID_SUCCESS",
  payload: { cartID, cartItem },
});

export const reduceItemFromCartStart = (item) => ({
  type: "REDUCE_FROM_CART_START",
  payload: item,
});

export const updateCartItemCount = (cartItemToUpdate) => ({
  type: "UPDATE_CART_ITEM_COUNT",
  payload: cartItemToUpdate,
});

export const clearCart = () => ({
  type: "CLEAR_CART",
});
