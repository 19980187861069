import React from 'react';
import {RetryBoxContainer,WarningIcon,RefreshIcon,WarningMessage} from  './retry-box.styles.jsx';


const RetryBox = ({handleErrorRetry,...otherProps}) => (
	<RetryBoxContainer onClick={handleErrorRetry} {...otherProps}>
		<WarningIcon />
		<WarningMessage>Network Error, Click To Retry</WarningMessage>
		<RefreshIcon />
	</RetryBoxContainer>
)


export default RetryBox
