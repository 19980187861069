import styled from "styled-components";
import { ReactComponent as LocationSVG } from "assets/pin.svg";
import { ReactComponent as SwapSVG } from "assets/swap.svg";

export const ActiveLocaleContainer = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0.55rem 1.5rem;
  border-radius: 15px;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    padding: 1rem 1.5rem;
    border-radius: 0;
  }
`;

export const Locality = styled.h2`
  margin: 0;
  font-size: 1.8rem;
  letter-spacing: 1px;
  color: #fff;
  @media screen and (max-width: 480px) {
    margin: 0 auto;
    font-weight: 400;
  }
`;

export const LocationIcon = styled(LocationSVG)`
  height: 20px;
  width: 20px;
  margin-right: 1rem;
  path {
    fill: #fff !important;
  }
  @media screen and (max-width: 480px) {
    height: 14px;
    margin-right: 0.5rem;
    margin-left: 1rem;
  }
`;

export const SwapIcon = styled(SwapSVG)`
  display: none;
  height: 20px;
  width: 20px;
  fill: #fff;
  @media screen and (max-width: 480px) {
    display: none;
  }
`;
