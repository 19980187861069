import React, { useState } from "react";
import {
  AuthContainer,
  Header,
  AuthBox,
  RegisterToggle,
  Text,
  SignInUpContainer,
} from "./auth.styles.jsx";
import SignInSignUp from "components/sign-in-sign-up/sign-in-up.jsx";
import CustomButton from "components/custom-button/custom-button.jsx";

const Auth = () => {
  const [isSignUpShown, setIsSignUpShown] = useState(false);
  const [isSignInShown, setIsSignInShown] = useState(true);

  return (
    <AuthContainer>
      <Header>
        <AuthBox>
          <RegisterToggle
            onClick={() => {
              setIsSignUpShown(true);
              setIsSignInShown(false);
            }}
            show={!isSignUpShown}
          >
            <Text>Don't Have an Account?</Text>
            <CustomButton secondary green small>
              Register
            </CustomButton>
          </RegisterToggle>
          <SignInUpContainer show={isSignInShown}>
            <SignInSignUp type="Login" />
          </SignInUpContainer>

          <RegisterToggle
            onClick={() => {
              setIsSignUpShown(false);
              setIsSignInShown(true);
            }}
            show={!isSignInShown}
          >
            <Text>Already Have An Account</Text>
            <CustomButton secondary green small>
              Login
            </CustomButton>
          </RegisterToggle>
          <SignInUpContainer show={isSignUpShown}>
            <SignInSignUp type="SignUp" />
          </SignInUpContainer>
        </AuthBox>
      </Header>
    </AuthContainer>
  );
};

export default Auth;
