import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCart } from "redux/cart/cart-selector.js";
import CartListItem from "components/cart-list-item/cart-list-item.jsx";
import CheckoutVoucher from "components/checkout-voucher/checkout-voucher";
import {
  CartContainer,
  CartItemsContainer,
  CartItems,
  CartStatus,
  CartListItemBorder,
  CartInfo,
  CartInfoItem,
} from "./checkout-cart.styles.jsx";

const CheckoutCart = ({ cart }) => {
  const {
    deliveryCharge,
    gatewayCharge,
    coinRedeem,
    subTotal,
    savings,
    grandTotal,
    items: cartItems,
  } = cart;
  return (
    <CartContainer>
      <CartItemsContainer>
        {cartItems.length ? (
          cartItems.map((cartItem) => (
            <CartItems key={cartItem.variationID}>
              <CartListItem cartItem={cartItem} wide />
            </CartItems>
          ))
        ) : (
          <CartStatus>Your Cart is Empty</CartStatus>
        )}
      </CartItemsContainer>
      <CartInfo>
        <CartListItemBorder />
        <CheckoutVoucher />
        <CartInfoItem>
          Sub Total:<span>₹{subTotal}</span>
        </CartInfoItem>
        <CartInfoItem>
          Delivery Charge:<span>₹{deliveryCharge}</span>
        </CartInfoItem>
        <CartInfoItem>
          Payment Fees:<span>₹{gatewayCharge}</span>
        </CartInfoItem>
        {coinRedeem > 0 && (
          <CartInfoItem>
            Coin Savings: <span>₹{coinRedeem}</span>
          </CartInfoItem>
        )}
        {savings > 0 && (
          <CartInfoItem>
            Total Savings: <span> - ₹{savings}</span>
          </CartInfoItem>
        )}
        <CartInfoItem>
          Total:<span>₹{grandTotal}</span>
        </CartInfoItem>
      </CartInfo>
    </CartContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  cart: selectCart,
});

export default connect(mapStateToProps)(CheckoutCart);
