import React from "react";
import { withRouter } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./hero-slider.css";

const HeroSlider = ({ heroBanner, single, short, history }) => {
  const responsive = {
    0: { items: 1 },
    480: { items: 2 },
    1024: { items: 3 },
  };

  if (single) heroBanner = [heroBanner];

  return (
    <div className={`carousel-parent ${single && `single`}`}>
      <AliceCarousel
        responsive={responsive}
        buttonsDisabled={true}
        infinite={!single}
        autoPlay={!single}
        autoPlayInterval={3000}
        fadeOutAnimation={true}
      >
        {heroBanner.map((banner) => (
          <div key={banner.url} className="slider-container">
            <img
              className={`carousel-img ${single && `single`} ${
                short && `short`
              }`}
              onClick={() => history.push(banner.url)}
              src={banner.image}
              alt={banner.name}
            />
          </div>
        ))}
      </AliceCarousel>
    </div>
  );
};

export default withRouter(HeroSlider);
