import React from "react";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  CartDropdownContainer,
  CartItemsContainer,
  CartItems,
  CartListItemBorder,
  CartStatus,
} from "./cart-dropdown.styles.jsx";
import CustomButton from "components/custom-button/custom-button.jsx";
import CartListItem from "components/cart-list-item/cart-list-item.jsx";
import {
  selectCartItems,
  selectIsCartEmpty,
} from "redux/cart/cart-selector.js";
import { toggleCartDropdown } from "redux/cart/cart-actions.js";
import { checkoutOrderStart } from "redux/checkout/checkout-actions.js";

const CartDropdown = ({
  cart,
  isDisabled,
  history,
  toggleCart,
  checkoutOrder,
}) => (
  <CartDropdownContainer>
    <CartItemsContainer>
      {cart.length ? (
        cart.map((cartItem) => (
          <CartItems key={cartItem.variationID}>
            <CartListItem cartItem={cartItem} />
            <CartListItemBorder />
          </CartItems>
        ))
      ) : (
        <CartStatus>
          Your Cart is Empty
          <span onClick={() => toggleCart()}>✖</span>
        </CartStatus>
      )}
    </CartItemsContainer>
    {!isDisabled && (
      <CustomButton
        secondary
        onClick={() => {
          history.push("/checkout");
          toggleCart();
          checkoutOrder();
        }}
      >
        Checkout
      </CustomButton>
    )}
  </CartDropdownContainer>
);

const mapStateToProps = createStructuredSelector({
  cart: selectCartItems,
  isDisabled: selectIsCartEmpty,
});

const mapDispatchToProps = (dispatch) => ({
  toggleCart: () => dispatch(toggleCartDropdown()),
  checkoutOrder: () => dispatch(checkoutOrderStart()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CartDropdown)
);
