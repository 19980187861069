export const fmtLocaleList = ({ data: cities, locations }) => {
  return cities.reduce((acc, item) => {
    acc[item.id] = { ...item, locations: locations[item.id] };
    return acc;
  }, {});
};

export const fmtHomePageFields = ({ data: homePage }) => {
  const homePageFields = {
    PB: "primeBanner",
    B1: "mainBanner",
    B2: "fullBanner",
    B3: "twoColBannerBg",
    B4: "extAdvertBanner",
    P1: "bestdeals",
    P2: "featured",
    C1: "categoryList",
    C2: "popularItems",
    C4: "featuredCategory",
    C5: "offerSale",
    M1: "featuredBrands",
  };
  return homePage.reduce((acc, item) => {
    let field = homePageFields[item.type];
    if (!field) return acc;
    acc[field] = item.data;
    if (field === "featuredBrands") {
      acc[field] = convArrayToObj(item.data); // Handle Featured Brands -- Refactor
    }
    return acc;
  }, {});
};

const convArrayToObj = (arr) =>
  arr.reduce((acc, item) => {
    acc[item.slug] = item;
    return acc;
  }, {});

export const fmtCategoriesList = ({
  data: parentCategories,
  sub_categories: subCategories,
}) => {
  return parentCategories.reduce((acc, parentCategory) => {
    let parentCategoryID = parentCategory.id;
    let subCategory = subCategories[parentCategoryID];
    let subCategoriesMap = subCategory.reduce((acc, subCategory) => {
      acc[subCategory.slug] = {
        ...subCategory,
        parentCategoryID,
        parentCategoryURL: subCategory.parent_slug,
        url: subCategory.slug,
      };
      return acc;
    }, {});
    acc[parentCategory.slug] = {
      ...parentCategory,
      url: parentCategory.slug,
      subCategoriesMap,
    };
    return acc;
  }, {});
};

export const fmtProductList = ({ data: productsList, variations }) => {
  return productsList.reduce((acc, product) => {
    const slug = product.slug || slugify(product.name); // Remove when backend adds slug
    acc[slug] = {
      ...product,
      variations: variations[product.id],
    };
    return acc;
  }, {});
};

const slugify = (string) =>
  string
    .replace(/[^\u0020-\u007E]/g, "")
    .replace(/[^A-Za-z]/g, "-")
    .replace(/^-+|-+$|-(?=-)/g, "")
    .toLowerCase();
