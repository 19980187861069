import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { selectRazorpayKey } from "redux/shop/shop-selector.js";
import { selectRazorypayOrderID } from "redux/checkout/checkout-selector.js";
import { selectGrandTotal } from "redux/cart/cart-selector";
import { selectUserPhoneNumber } from "redux/user/user-selector.js";
import useScript from "hooks/usescript.js";
import { fetchPaymentStatus } from "redux/checkout/checkout-actions.js";

const RazorpayCompononent = ({
  razorpayKey,
  razorpayOrderID,
  totalAmount,
  PhoneNumber,
  getPaymentStatus,
  history,
}) => {
  const amountFmt = Math.floor(totalAmount * 100);

  const options = {
    key: razorpayKey,
    amount: amountFmt,
    currency: "INR",
    name: "Goodoor",
    description: "Online Super Market",
    image: "/assets/logo.svg",
    order_id: razorpayOrderID,
    handler: function (response) {
      if (!response.error) {
        const { razorpay_payment_id } = response;
        getPaymentStatus(razorpay_payment_id, history);
        history.push("/orderstatus");
      } else {
        console.log("Payment Failed Please Try Again", response.error);
      }
    },
    prefill: {
      name: "",
      email: "",
      contact: PhoneNumber,
    },
    notes: {
      address: "note value",
    },
    theme: {
      color: "#2EC370",
    },
  };

  const [loaded, error] = useScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  );

  if (loaded && !error) {
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  }

  return <div></div>;
};

const mapStateToProps = createStructuredSelector({
  razorpayKey: selectRazorpayKey,
  razorpayOrderID: selectRazorypayOrderID,
  totalAmount: selectGrandTotal,
  PhoneNumber: selectUserPhoneNumber,
});

const mapsDispatchToProps = (dispatch) => ({
  getPaymentStatus: (rzpPaymentID, history) =>
    dispatch(fetchPaymentStatus(rzpPaymentID, history)),
});

export default withRouter(
  connect(mapStateToProps, mapsDispatchToProps)(RazorpayCompononent)
);
