import React, { useState } from "react";
import {
  ProductPreviewCardContainer,
  DiscountBox,
  Image,
  Body,
  Title,
  PriceContainer,
  Price,
  SalePrice,
  PrimePrice,
  PrimeLink,
  SilverCoin,
} from "./product-preview-card.styles.jsx";
import ContentLoader from "react-content-loader";
import ProductVariations from "components/product-variations/product-variations.jsx";
import ProductCardFooter from "components/product-card-footer/product-card-footer.jsx";
import SilverCoinImage from "assets/silver-coin.png";

const ProductPreviewCard = ({
  variation_id,
  name,
  images,
  offer_price,
  prime_price,
  mrp,
  silver_coins,
  offer_percentage,
  quantity,
  max_quantity,
  variations,
  out_of_stock,
  highlight,
  brand_name,
  vendor_name,
}) => {
  if (!variations)
    variations = [
      {
        id: variation_id,
        mrp,
        offer_price,
        prime_price,
        offer_percentage,
        silver_coins,
        out_of_stock,
        quantity,
        max_quantity,
      },
    ]; // API Data from Featured Products, Best Deals and Popular Items are structured differntly.

  const [activeVariationIndex, setActiveVariationIndex] = useState(0);
  const activeVariation = variations[activeVariationIndex];
  const productDataForCart = {
    productName: name,
    productImage: images[0],
    sellingPrice: activeVariation.offer_price,
    primePrice: activeVariation.prime_price,
    silverCoins: activeVariation.silver_coins,
    variationID: activeVariation.id,
    isOutOfStock: activeVariation.out_of_stock,
    maxQuantity: activeVariation.max_quantity,
  };

  return (
    <ProductPreviewCardContainer highlight={highlight}>
      {activeVariation?.offer_percentage > 0 && (
        <DiscountBox unavailable={out_of_stock ? 1 : 0}>
          {`${activeVariation?.offer_percentage}%`}
        </DiscountBox>
      )}
      <Image
        src={images[0]}
        alt={name}
        unavailable={out_of_stock ? 1 : 0}
        loader={
          <ContentLoader
            height={240}
            width={320}
            speed={2}
            primaryColor="#dfdcdc"
            secondaryColor="#ecebeb"
          >
            <rect x="0" y="0" rx="3" ry="3" width="320" height="250" />
          </ContentLoader>
        }
      />
      {/*
					<Vendor>
        <img src={RestaurantImage} alt="RestaurantImage" />
        {brand_name || vendor_name}
			</Vendor>
			*/}
      <Body>
        <Title>{name}</Title>
        <PriceContainer>
          <SalePrice>₹{activeVariation.offer_price}</SalePrice>
          {Number(activeVariation.mrp) >
            Number(activeVariation.offer_price) && (
            <Price>
              ₹<span>{activeVariation.mrp}</span>
            </Price>
          )}
          {activeVariation.silver_coins > 0 && (
            <SilverCoin>
              <span>{activeVariation.silver_coins}</span>
              <img alt="silver_coin" src={SilverCoinImage} />
            </SilverCoin>
          )}
        </PriceContainer>
        {activeVariation.prime_price < activeVariation.offer_price && (
          <PrimePrice>
            ₹{activeVariation.prime_price}
            <PrimeLink to="/prime">with prime</PrimeLink>
          </PrimePrice>
        )}

        <ProductVariations
          productVariations={variations}
          activeVariation={activeVariation}
          setActiveVariationIndex={setActiveVariationIndex}
        />
        <ProductCardFooter
          productDataForCart={productDataForCart}
          highlight={highlight}
        />
      </Body>
    </ProductPreviewCardContainer>
  );
};

export default ProductPreviewCard;
