import styled from "styled-components";

export const SubCategoryContainer = styled.div``;

export const BreadCrumbs = styled.h3`
  margin-bottom: 0.5rem;
  background: #fff;
  border-radius: 35px;
  padding: 0.8rem 1.15rem;
  display: inline-block;

  span {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1.8rem;
    letter-spacing: 0.5px;
    padding: 0.25rem 1.25rem;
    border-radius: 15px;

    &:after {
      content: "/";
    }
    &:last-child:after {
      content: "";
    }
    &:hover {
      //background: #2ec370;
      //color: #fff;
    }
    &:hover::after {
      text-decoration: initial;
    }
  }

  @media screen and (max-width: 480px) {
    margin-top: -1rem;
    display: block;
    font-weight: 400;
    span {
      font-size: 1.6rem;
      padding: 0 0.7rem;
    }
  }
`;
