import {all,call} from 'redux-saga/effects'
import shopSagas from './shop/shop-sagas.js';
import userSagas from './user/user-sagas.js';
import cartSagas from './cart/cart-sagas.js';
import checkoutSagas from './checkout/checkout-sagas.js';

export default function* rootSaga(){
	yield all([
		call(shopSagas),
		call(cartSagas),
		call(userSagas),
		call(checkoutSagas)
	])
}