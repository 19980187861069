import styled from "styled-components";

export const CartListItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
`;
export const Image = styled.div`
  display: flex;
  justify-content: center;
  width: 20%;
  margin-right: 1.5rem;

  & img {
    height: 56px;
    width: 56px;
    object-fit: contain;
  }
`;

export const Info = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	font-size: 1.6rem;
	flex: 1;
	width: 80%:
	
	& > * {
		margin: 0.3rem;
		display: flex;
		align-items: center;
	}
`;

export const Title = styled.h4`
  line-height: 1.2;
  margin: 0 0 1rem;
  flex: 100%;
  text-transform: capitalize;
  word-spacing: 3px;
  letter-spacing: 0.5px;
`;

export const Qty = styled.h5`
  span {
    margin: 0 0.3rem;
    cursor: pointer;
    font-weight: 400;
  }
`;
export const Price = styled.h4`
  margin-left: 1rem;
  font-weight: 400;
`;

export const RemoveButtonContainer = styled.div`
  align-self: center;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const RemoveButton = styled.h5`
  color: #a9a9a9;
  margin-right: 2rem;
  padding: 0.3rem 0.5rem 0.3rem 1rem;
`;

