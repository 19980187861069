import React from "react";
import { InputGroup, InputField, InputLabel } from "./form-input.styles.jsx";

const FormInput = ({ handleChange, label, ...otherProps }) => (
  <InputGroup>
    <InputField {...otherProps} onChange={handleChange} />
    {label ? <InputLabel {...otherProps}>{label}</InputLabel> : null}
  </InputGroup>
);

export default FormInput;
