const countryCodeData = [
  {
    "country": "Andorra",
    "id": "AD",
    "code": "+376"
  },
  {
    "country": "United Arab Emirates",
    "id": "AE",
    "code": "+971"
  },
  {
    "country": "Afghanistan",
    "id": "AF",
    "code": "+93"
  },
  {
    "country": "Antigua and Barbuda",
    "id": "AG",
    "code": "+1"
  },
  {
    "country": "Anguilla",
    "id": "AI",
    "code": "+1"
  },
  {
    "country": "Albania",
    "id": "AL",
    "code": "+355"
  },
  {
    "country": "Armenia",
    "id": "AM",
    "code": "+374"
  },
  {
    "country": "Angola",
    "id": "AO",
    "code": "+244"
  },
  {
    "country": "Argentina",
    "id": "AR",
    "code": "+54"
  },
  {
    "country": "American Samoa",
    "id": "AS",
    "code": "+1"
  },
  {
    "country": "Austria",
    "id": "AT",
    "code": "+43"
  },
  {
    "country": "Australia",
    "id": "AU",
    "code": "+61"
  },
  {
    "country": "Aruba",
    "id": "AW",
    "code": "+297"
  },
  {
    "country": "Åland Islands",
    "id": "AX",
    "code": "+358"
  },
  {
    "country": "Azerbaijan",
    "id": "AZ",
    "code": "+994"
  },
  {
    "country": "Bosnia and Herzegovina",
    "id": "BA",
    "code": "+387"
  },
  {
    "country": "Barbados",
    "id": "BB",
    "code": "+1"
  },
  {
    "country": "Bangladesh",
    "id": "BD",
    "code": "+880"
  },
  {
    "country": "Belgium",
    "id": "BE",
    "code": "+32"
  },
  {
    "country": "Burkina Faso",
    "id": "BF",
    "code": "+226"
  },
  {
    "country": "Bulgaria",
    "id": "BG",
    "code": "+359"
  },
  {
    "country": "Bahrain",
    "id": "BH",
    "code": "+973"
  },
  {
    "country": "Burundi",
    "id": "BI",
    "code": "+257"
  },
  {
    "country": "Benin",
    "id": "BJ",
    "code": "+229"
  },
  {
    "country": "Saint Barthélemy",
    "id": "BL",
    "code": "+590"
  },
  {
    "country": "Bermuda",
    "id": "BM",
    "code": "+1"
  },
  {
    "country": "Brunei Darussalam",
    "id": "BN",
    "code": "+673"
  },
  {
    "country": "Bolivia",
    "id": "BO",
    "code": "+591"
  },
  {
    "country": "Bonaire",
    "id": "BQ",
    "code": "+599"
  },
  {
    "country": "Brazil",
    "id": "BR",
    "code": "+55"
  },
  {
    "country": "Bahamas",
    "id": "BS",
    "code": "+1"
  },
  {
    "country": "Bhutan",
    "id": "BT",
    "code": "+975"
  },
  {
    "country": "Botswana",
    "id": "BW",
    "code": "+267"
  },
  {
    "country": "Belarus",
    "id": "BY",
    "code": "+375"
  },
  {
    "country": "Belize",
    "id": "BZ",
    "code": "+501"
  },
  {
    "country": "Canada",
    "id": "CA",
    "code": "+1"
  },
  {
    "country": "Cocos (Keeling) Islands",
    "id": "CC",
    "code": "+61"
  },
  {
    "country": "Congo, Democratic Republic of the (Zaire)",
    "id": "CD",
    "code": "+243"
  },
  {
    "country": "Central African Republic",
    "id": "CF",
    "code": "+236"
  },
  {
    "country": "Congo",
    "id": "CG",
    "code": "+242"
  },
  {
    "country": "Switzerland",
    "id": "CH",
    "code": "+41"
  },
  {
    "country": "Ivory Coast",
    "id": "CI",
    "code": "+225"
  },
  {
    "country": "Cook Islands",
    "id": "CK",
    "code": "+682"
  },
  {
    "country": "Chile",
    "id": "CL",
    "code": "+56"
  },
  {
    "country": "Cameroon",
    "id": "CM",
    "code": "+237"
  },
  {
    "country": "China",
    "id": "CN",
    "code": "+86"
  },
  {
    "country": "Colombia",
    "id": "CO",
    "code": "+57"
  },
  {
    "country": "Costa Rica",
    "id": "CR",
    "code": "+506"
  },
  {
    "country": "Cuba",
    "id": "CU",
    "code": "+53"
  },
  {
    "country": "Cape Verde",
    "id": "CV",
    "code": "+238"
  },
  {
    "country": "Curaçao",
    "id": "CW",
    "code": "+599"
  },
  {
    "country": "Christmas Island",
    "id": "CX",
    "code": "+61"
  },
  {
    "country": "Cyprus",
    "id": "CY",
    "code": "+357"
  },
  {
    "country": "Czech Republic",
    "id": "CZ",
    "code": "+420"
  },
  {
    "country": "Germany",
    "id": "DE",
    "code": "+49"
  },
  {
    "country": "Djibouti",
    "id": "DJ",
    "code": "+253"
  },
  {
    "country": "Denmark",
    "id": "DK",
    "code": "+45"
  },
  {
    "country": "Dominica",
    "id": "DM",
    "code": "+1"
  },
  {
    "country": "Dominican Republic",
    "id": "DO",
    "code": "+1"
  },
  {
    "country": "Algeria",
    "id": "DZ",
    "code": "+213"
  },
  {
    "country": "Ecuador",
    "id": "EC",
    "code": "+593"
  },
  {
    "country": "Estonia",
    "id": "EE",
    "code": "+372"
  },
  {
    "country": "Egypt",
    "id": "EG",
    "code": "+20"
  },
  {
    "country": "Eritrea",
    "id": "ER",
    "code": "+291"
  },
  {
    "country": "Spain",
    "id": "ES",
    "code": "+34"
  },
  {
    "country": "Ethiopia",
    "id": "ET",
    "code": "+251"
  },
  {
    "country": "Finland",
    "id": "FI",
    "code": "+358"
  },
  {
    "country": "Fiji",
    "id": "FJ",
    "code": "+679"
  },
  {
    "country": "Falkland Islands",
    "id": "FK",
    "code": "+500"
  },
  {
    "country": "Micronesia",
    "id": "FM",
    "code": "+691"
  },
  {
    "country": "Faroe Islands",
    "id": "FO",
    "code": "+298"
  },
  {
    "country": "France",
    "id": "FR",
    "code": "+33"
  },
  {
    "country": "Gabon",
    "id": "GA",
    "code": "+241"
  },
  {
    "country": "Great Britian",
    "id": "GB",
    "code": "+44"
  },
  {
    "country": "Grenada",
    "id": "GD",
    "code": "+1"
  },
  {
    "country": "Georgia",
    "id": "GE",
    "code": "+995"
  },
  {
    "country": "French Guiana",
    "id": "GF",
    "code": "+594"
  },
  {
    "country": "Guernsey",
    "id": "GG",
    "code": "+44"
  },
  {
    "country": "Ghana",
    "id": "GH",
    "code": "+233"
  },
  {
    "country": "Gibraltar",
    "id": "GI",
    "code": "+350"
  },
  {
    "country": "Greenland",
    "id": "GL",
    "code": "+299"
  },
  {
    "country": "Gambia",
    "id": "GM",
    "code": "+220"
  },
  {
    "country": "Guinea",
    "id": "GN",
    "code": "+224"
  },
  {
    "country": "Guadeloupe",
    "id": "GP",
    "code": "+590"
  },
  {
    "country": "Equatorial Guinea",
    "id": "GQ",
    "code": "+240"
  },
  {
    "country": "Greece",
    "id": "GR",
    "code": "+30"
  },
  {
    "country": "South Georgia and the South Sandwich Islands",
    "id": "GS",
    "code": "+500"
  },
  {
    "country": "Guatemala",
    "id": "GT",
    "code": "+502"
  },
  {
    "country": "Guam",
    "id": "GU",
    "code": "+1"
  },
  {
    "country": "Guinea-Bissau",
    "id": "GW",
    "code": "+245"
  },
  {
    "country": "Guyana",
    "id": "GY",
    "code": "+592"
  },
  {
    "country": "Hong Kong",
    "id": "HK",
    "code": "+852"
  },
  {
    "country": "Honduras",
    "id": "HN",
    "code": "+504"
  },
  {
    "country": "Croatia",
    "id": "HR",
    "code": "+385"
  },
  {
    "country": "Haiti",
    "id": "HT",
    "code": "+509"
  },
  {
    "country": "Hungary",
    "id": "HU",
    "code": "+36"
  },
  {
    "country": "Indonesia",
    "id": "ID",
    "code": "+62"
  },
  {
    "country": "Ireland",
    "id": "IE",
    "code": "+353"
  },
  {
    "country": "Israel",
    "id": "IL",
    "code": "+972"
  },
  {
    "country": "Isle of Man",
    "id": "IM",
    "code": "+44"
  },
  {
    "country": "India",
    "id": "IN",
    "code": "+91"
  },
  {
    "country": "British Indian Ocean Territory",
    "id": "IO",
    "code": "+246"
  },
  {
    "country": "Iraq",
    "id": "IQ",
    "code": "+964"
  },
  {
    "country": "Iran",
    "id": "IR",
    "code": "+98"
  },
  {
    "country": "Iceland",
    "id": "IS",
    "code": "+354"
  },
  {
    "country": "Italy",
    "id": "IT",
    "code": "+39"
  },
  {
    "country": "Jersey",
    "id": "JE",
    "code": "+44"
  },
  {
    "country": "Jamaica",
    "id": "JM",
    "code": "+1"
  },
  {
    "country": "Jordan",
    "id": "JO",
    "code": "+962"
  },
  {
    "country": "Japan",
    "id": "JP",
    "code": "+81"
  },
  {
    "country": "Kenya",
    "id": "KE",
    "code": "+254"
  },
  {
    "country": "Kyrgyzstan",
    "id": "KG",
    "code": "+996"
  },
  {
    "country": "Cambodia",
    "id": "KH",
    "code": "+855"
  },
  {
    "country": "Kiribati",
    "id": "KI",
    "code": "+686"
  },
  {
    "country": "Comoros",
    "id": "KM",
    "code": "+269"
  },
  {
    "country": "Saint Kitts and Nevis",
    "id": "KN",
    "code": "+1"
  },
  {
    "country": "North Korea",
    "id": "KP",
    "code": "+850"
  },
  {
    "country": "South Korea",
    "id": "KR",
    "code": "+82"
  },
  {
    "country": "Kuwait",
    "id": "KW",
    "code": "+965"
  },
  {
    "country": "Cayman Islands",
    "id": "KY",
    "code": "+1"
  },
  {
    "country": "Kazakhstan",
    "id": "KZ",
    "code": "+7"
  },
  {
    "country": "Laos",
    "id": "LA",
    "code": "+856"
  },
  {
    "country": "Lebanon",
    "id": "LB",
    "code": "+961"
  },
  {
    "country": "Saint Lucia",
    "id": "LC",
    "code": "+1"
  },
  {
    "country": "Liechtenstein",
    "id": "LI",
    "code": "+423"
  },
  {
    "country": "Sri Lanka",
    "id": "LK",
    "code": "+94"
  },
  {
    "country": "Liberia",
    "id": "LR",
    "code": "+231"
  },
  {
    "country": "Lesotho",
    "id": "LS",
    "code": "+266"
  },
  {
    "country": "Lithuania",
    "id": "LT",
    "code": "+370"
  },
  {
    "country": "Luxembourg",
    "id": "LU",
    "code": "+352"
  },
  {
    "country": "Latvia",
    "id": "LV",
    "code": "+371"
  },
  {
    "country": "Libya",
    "id": "LY",
    "code": "+218"
  },
  {
    "country": "Morocco",
    "id": "MA",
    "code": "+212"
  },
  {
    "country": "Monaco",
    "id": "MC",
    "code": "+377"
  },
  {
    "country": "Moldova",
    "id": "MD",
    "code": "+373"
  },
  {
    "country": "Montenegro",
    "id": "ME",
    "code": "+382"
  },
  {
    "country": "Saint Martin (France)",
    "id": "MF",
    "code": "+590"
  },
  {
    "country": "Madagascar",
    "id": "MG",
    "code": "+261"
  },
  {
    "country": "Marshall Islands",
    "id": "MH",
    "code": "+692"
  },
  {
    "country": "North Macedonia",
    "id": "MK",
    "code": "+389"
  },
  {
    "country": "Mali",
    "id": "ML",
    "code": "+223"
  },
  {
    "country": "Myanmar",
    "id": "MM",
    "code": "+95"
  },
  {
    "country": "Mongolia",
    "id": "MN",
    "code": "+976"
  },
  {
    "country": "Macau",
    "id": "MO",
    "code": "+853"
  },
  {
    "country": "Northern Mariana Islands",
    "id": "MP",
    "code": "+1"
  },
  {
    "country": "Martinique",
    "id": "MQ",
    "code": "+596"
  },
  {
    "country": "Mauritania",
    "id": "MR",
    "code": "+222"
  },
  {
    "country": "Montserrat",
    "id": "MS",
    "code": "+1"
  },
  {
    "country": "Malta",
    "id": "MT",
    "code": "+356"
  },
  {
    "country": "Mauritius",
    "id": "MU",
    "code": "+230"
  },
  {
    "country": "Maldives",
    "id": "MV",
    "code": "+960"
  },
  {
    "country": "Malawi",
    "id": "MW",
    "code": "+265"
  },
  {
    "country": "Mexico",
    "id": "MX",
    "code": "+52"
  },
  {
    "country": "Malaysia",
    "id": "MY",
    "code": "+60"
  },
  {
    "country": "Mozambique",
    "id": "MZ",
    "code": "+258"
  },
  {
    "country": "Namibia",
    "id": "NA",
    "code": "+264"
  },
  {
    "country": "New Caledonia",
    "id": "NC",
    "code": "+687"
  },
  {
    "country": "Niger",
    "id": "NE",
    "code": "+227"
  },
  {
    "country": "Norfolk Island",
    "id": "NF",
    "code": "+672"
  },
  {
    "country": "Nigeria",
    "id": "NG",
    "code": "+234"
  },
  {
    "country": "Nicaragua",
    "id": "NI",
    "code": "+505"
  },
  {
    "country": "Netherlands",
    "id": "NL",
    "code": "+31"
  },
  {
    "country": "Norway",
    "id": "NO",
    "code": "+47"
  },
  {
    "country": "Nepal",
    "id": "NP",
    "code": "+977"
  },
  {
    "country": "Nauru",
    "id": "NR",
    "code": "+674"
  },
  {
    "country": "Niue",
    "id": "NU",
    "code": "+683"
  },
  {
    "country": "New Zealand",
    "id": "NZ",
    "code": "+64"
  },
  {
    "country": "Oman",
    "id": "OM",
    "code": "+968"
  },
  {
    "country": "Panama",
    "id": "PA",
    "code": "+507"
  },
  {
    "country": "Peru",
    "id": "PE",
    "code": "+51"
  },
  {
    "country": "French Polynesia",
    "id": "PF",
    "code": "+689"
  },
  {
    "country": "Papua New Guinea",
    "id": "PG",
    "code": "+675"
  },
  {
    "country": "Philippines",
    "id": "PH",
    "code": "+63"
  },
  {
    "country": "Pakistan",
    "id": "PK",
    "code": "+92"
  },
  {
    "country": "Poland",
    "id": "PL",
    "code": "+48"
  },
  {
    "country": "Saint Pierre and Miquelon",
    "id": "PM",
    "code": "+508"
  },
  {
    "country": "Pitcairn Islands",
    "id": "PN",
    "code": "+64"
  },
  {
    "country": "Puerto Rico",
    "id": "PR",
    "code": "+1"
  },
  {
    "country": "Palestine",
    "id": "PS",
    "code": "+970"
  },
  {
    "country": "Portugal",
    "id": "PT",
    "code": "+351"
  },
  {
    "country": "Palau",
    "id": "PW",
    "code": "+680"
  },
  {
    "country": "Paraguay",
    "id": "PY",
    "code": "+595"
  },
  {
    "country": "Qatar",
    "id": "QA",
    "code": "+974"
  },
  {
    "country": "Réunion",
    "id": "RE",
    "code": "+262"
  },
  {
    "country": "Romania",
    "id": "RO",
    "code": "+40"
  },
  {
    "country": "Serbia",
    "id": "RS",
    "code": "+381"
  },
  {
    "country": "Russia",
    "id": "RU",
    "code": "+7"
  },
  {
    "country": "Rwanda",
    "id": "RW",
    "code": "+250"
  },
  {
    "country": "Saudi Arabia",
    "id": "SA",
    "code": "+966"
  },
  {
    "country": "Solomon Islands",
    "id": "SB",
    "code": "+677"
  },
  {
    "country": "Seychelles",
    "id": "SC",
    "code": "+248"
  },
  {
    "country": "Sudan",
    "id": "SD",
    "code": "+249"
  },
  {
    "country": "Sweden",
    "id": "SE",
    "code": "+46"
  },
  {
    "country": "Singapore",
    "id": "SG",
    "code": "+65"
  },
  {
    "country": "Saint Helena",
    "id": "SH",
    "code": "+290"
  },
  {
    "country": "Slovenia",
    "id": "SI",
    "code": "+386"
  },
  {
    "country": "Svalbard",
    "id": "SJ",
    "code": "+47"
  },
  {
    "country": "Slovakia",
    "id": "SK",
    "code": "+421"
  },
  {
    "country": "Sierra Leone",
    "id": "SL",
    "code": "+232"
  },
  {
    "country": "San Marino",
    "id": "SM",
    "code": "+378"
  },
  {
    "country": "Senegal",
    "id": "SN",
    "code": "+221"
  },
  {
    "country": "Somalia",
    "id": "SO",
    "code": "+252"
  },
  {
    "country": "Suriname",
    "id": "SR",
    "code": "+597"
  },
  {
    "country": "South Sudan",
    "id": "SS",
    "code": "+211"
  },
  {
    "country": "São Tomé and Príncipe",
    "id": "ST",
    "code": "+239"
  },
  {
    "country": "El Salvador",
    "id": "SV",
    "code": "+503"
  },
  {
    "country": "Sint Maarten (Netherlands)",
    "id": "SX",
    "code": "+1"
  },
  {
    "country": "Syria",
    "id": "SY",
    "code": "+963"
  },
  {
    "country": "eSwatini",
    "id": "SZ",
    "code": "+268"
  },
  {
    "country": "Turks and Caicos Islands",
    "id": "TC",
    "code": "+1"
  },
  {
    "country": "Chad",
    "id": "TD",
    "code": "+235"
  },
  {
    "country": "Togo",
    "id": "TG",
    "code": "+228"
  },
  {
    "country": "Thailand",
    "id": "TH",
    "code": "+66"
  },
  {
    "country": "Tajikistan",
    "id": "TJ",
    "code": "+992"
  },
  {
    "country": "Tokelau",
    "id": "TK",
    "code": "+690"
  },
  {
    "country": "East Timor",
    "id": "TL",
    "code": "+670"
  },
  {
    "country": "Turkmenistan",
    "id": "TM",
    "code": "+993"
  },
  {
    "country": "Tunisia",
    "id": "TN",
    "code": "+216"
  },
  {
    "country": "Tonga",
    "id": "TO",
    "code": "+676"
  },
  {
    "country": "Turkey",
    "id": "TR",
    "code": "+90"
  },
  {
    "country": "Trinidad and Tobago",
    "id": "TT",
    "code": "+1"
  },
  {
    "country": "Tuvalu",
    "id": "TV",
    "code": "+688"
  },
  {
    "country": "Taiwan",
    "id": "TW",
    "code": "+886"
  },
  {
    "country": "Tanzania",
    "id": "TZ",
    "code": "+255"
  },
  {
    "country": "Ukraine",
    "id": "UA",
    "code": "+380"
  },
  {
    "country": "Uganda",
    "id": "UG",
    "code": "+256"
  },
  {
    "country": "USA",
    "id": "US",
    "code": "+1"
  },
  {
    "country": "Uruguay",
    "id": "UY",
    "code": "+598"
  },
  {
    "country": "Uzbekistan",
    "id": "UZ",
    "code": "+998"
  },
  {
    "country": "Vatican",
    "id": "VA",
    "code": "+379"
  },
  {
    "country": "Saint Vincent and the Grenadines",
    "id": "VC",
    "code": "+1"
  },
  {
    "country": "Venezuela",
    "id": "VE",
    "code": "+58"
  },
  {
    "country": "Virgin Islands British",
    "id": "VG",
    "code": "+1"
  },
  {
    "country": "Virgin Islands, USA",
    "id": "VI",
    "code": "+1"
  },
  {
    "country": "Vietnam",
    "id": "VN",
    "code": "+84"
  },
  {
    "country": "Vanuatu",
    "id": "VU",
    "code": "+678"
  },
  {
    "country": "Wallis and Futuna",
    "id": "WF",
    "code": "+681"
  },
  {
    "country": "Samoa",
    "id": "WS",
    "code": "+685"
  },
  {
    "country": "Yemen",
    "id": "YE",
    "code": "+967"
  },
  {
    "country": "Mayotte",
    "id": "YT",
    "code": "+262"
  },
  {
    "country": "South Africa",
    "id": "ZA",
    "code": "+27"
  },
  {
    "country": "Zambia",
    "id": "ZM",
    "code": "+260"
  },
  {
    "country": "Zimbabwe",
    "id": "ZW",
    "code": "+263"
  }
]

export default countryCodeData

