import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { searchStart } from "redux/shop/shop-actions.js";
import {
  selectIsFetchingSearchResult,
  selectProductsSearchResultArray,
} from "redux/shop/shop-selector.js";
import SpinnerOverlay from "components/with-spinner/spinner-overlay.jsx";
import ProductsOverview from "components/products-overview/products-overview.jsx";
import {
  SearchResultsContainer,
  ErrorMsg,
  ForkLiftIcon,
} from "./search.styles.jsx";

const SearchPage = ({
  match,
  searchStart,
  isFetchingSearchResult,
  productsList,
}) => {
  const { searchQuery } = match.params;

  useEffect(() => {
    searchStart(searchQuery);
  }, [searchStart, searchQuery]);

  if (isFetchingSearchResult) return <SpinnerOverlay />;

  return (
    <SearchResultsContainer>
      {productsList.length === 0 ? (
        <ErrorMsg>
          <ForkLiftIcon />
          <h2>Unable to find matching items</h2>
        </ErrorMsg>
      ) : (
        <ProductsOverview
          isFetching={isFetchingSearchResult}
          products={productsList}
        />
      )}
    </SearchResultsContainer>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { searchQuery },
    },
  }
) => ({
  isFetchingSearchResult: selectIsFetchingSearchResult(searchQuery)(state),
  productsList: selectProductsSearchResultArray(searchQuery)(state),
});

const mapDispatchToProps = (dispatch) => ({
  searchStart: (searchQuery) => dispatch(searchStart(searchQuery)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchPage)
);
