import styled from "styled-components";

export const HomePageContainer = styled.div`
  & > * {
    margin-bottom: 5rem;
    @media screen and (max-width: 480px) {
      margin-bottom: 2rem;
    }
  }
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

export const WideBanner = styled.div`
  background-color: #bde4f5;
  background-image: url("/assets/grocery.png");
  background-size: 800px 420px;
  background-position: 100% -9%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem;
  height: 30rem;
  h1 {
    color: rgb(41 41 41);
    font-size: 3.6rem;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
  }
  p {
    margin-top: 1rem;
    font-size: 1.6rem;
    font-family: "Open Sans", sans-serif;
  }

  @media screen and (max-width: 480px) {
		order: -1;
		display: none;
		justify-content: flex-start;
		padding: 2rem 2.5rem;
		background-size: 430px 240px;
		background-position: 50% 700%;
		border-radius: 10px;
		height 25rem;
		margin-top: -1rem;
    border-radius: 5px;
    h1 {
      font-size: 2.5rem;
    }
    p {
      display: none;
    }
  }
`;
