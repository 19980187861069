import styled, { css, keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
`;
export const ProductVariationsContainer = styled.div`
  position: relative;
  user-select: none;
  max-width: 85%;
  min-width: 45%;
  @media screen and (max-width: 480px) {
    min-width: 50%;
  }
`;
export const SingleVariation = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
  background: #f4f4f4;
  padding: 0.6rem 2.5rem;
  border-radius: 8px;
  color: #5c5555;
  letter-spacing: 0.4px;
  text-align: center;
  ${(props) =>
    props.skeleton &&
    css`
      width: 40%;
      border-radius: 15px;
      height: 2.5rem;
      background: linear-gradient(
        -90deg,
        #efefef 0%,
        #fcfcfc 50%,
        #efefef 100%
      );
      background-size: 400% 400%;
      animation: ${pulseAnimation} 1.2s ease-in-out infinite;
    `}
`;
export const Select = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
export const SelectTrigger = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 0 1.5rem;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #5c5555;
  height: 3rem;
  /*line-height: 60px;*/
  background: #ffffff;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #76b54170;
  z-index: 3;
  span {
    margin-right: 1rem;
  }
`;
export const SelectOptions = styled.div`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid lightgray;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
  border-top: 0;
  background: #fff;
  transition: all 0.15s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  margin-top: -0.4rem;
  padding-top: 0.4rem;
  border-radius: 0 0 8px 8px;

  z-index: 2;

  ${(props) =>
    props.show &&
    `
		opacity: 1;
     	visibility: visible;
     	pointer-events: all;
     `}
`;
export const Option = styled.span`
  position: relative;
  display: block;
  padding: 0 1.5rem 0 2rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #6b6b6b;
  line-height: 3rem;
  cursor: pointer;
  transition: all 0.25s;
  text-transform: uppercase;

  @media screen and (max-width: 480px) {
    padding: 1rem 1.5rem 0.5rem 2rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #efffdd;
    color: #777;
  }

  ${(props) =>
    props.selected &&
    `
		color: color: #777;
     	background-color: #efffdd;
     `}
`;

export const Arrow = styled.div`
  position: relative;
  height: 10px;
  width: 10px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 0.25rem;
    height: 100%;
    transition: all 0.3s;
  }

  &::before {
    left: -3.333333px;
    transform: rotate(-45deg);
    background-color: rgb(118, 181, 65);
  }

  &::after {
    left: 3.333333px;
    transform: rotate(45deg);
    background-color: #2ec370;
  }

  ${(props) =>
    props.open &&
    `
		 &::before {
		     left: -3.333333px;
		     transform: rotate(45deg);
		}
	`}

  ${(props) =>
    props.open &&
    `
		 &::after {
		     left: 3.333333px;
		     transform: rotate(-45deg);
		}
	`}
`;
