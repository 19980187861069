import styled from "styled-components";

export const CartDropdownContainer = styled.div`
  position: absolute;
  width: 290px;
  max-height: 440px;
  display: flex;
  flex-direction: column;
  justify-items: space-between;
  padding: 20px 15px 10px;
  border: 1px solid #e7e7e7;
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: white;
  top: 75px;
  right: 50px;
  z-index: 5;

  & button {
    align-self: center;
    margin: 1rem 0 0.5rem;
  }

  @media screen and (max-width: 480px) {
    width: 95vw;
    max-height: 70vh;
    right: 2.5vw;
    left: 2.5vw;
    top: 12rem;
    border-radius: 0 0 5px 5px;
    button {
      width: 20rem;
    }
  }
`;
export const CartItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const CartItems = styled.div`
  margin-bottom: 1rem;
`;
export const CartListItemBorder = styled.hr`
  color: #e7e7e7;
  width: 90%;
  margin: 0 auto;
  border: none;
  border-bottom: 1px solid;
`;

export const CartStatus = styled.span`
  font-size: 1.7rem;
  text-align: center;
  display: flex;
  margin: auto 20px 10px;
  align-items: center;
  span {
    cursor: pointer;
    margin-left: auto;
    color: #f88379;
    color: #a9a9a9;
    font-size: 1.4rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
  }
`;
