import styled, { css } from "styled-components";

export const Button = styled.button`
  font-family: 'Open Sans Condensed'
  letter-spacing: 0.5px;
  font-size: 1.7rem;
  font-weight: 600;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 3rem;
  padding: .8rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s;
	

  &::active{
    outline: none;
  }

  ${(props) => props.loader && ButtonLoader}
	${(props) => props.success && ButtonSuccess}
	${(props) => props.error && ButtonError}
  ${(props) => props.secondary && SecondaryButton}
	${(props) => props.orange && OrangeButton}
	${(props) => props.golden && GoldenButton}
	${(props) => props.gray && GrayButton}
  ${(props) => props.green && GreenButton}
	${(props) => props.red && RedButton}
	${(props) => props.pastelGreen && PastelGreenButton}
  ${(props) => props.small && SmallButton}
  ${(props) => props.disabled && DisabledButton}
	${(props) => props.restricted && RestrictedButton}
`;

const DisabledButton = css`
  cursor: not-allowed;
  background: #c7c7c7;
  text-decoration: line-through;
`;

const RestrictedButton = css`
  cursor: not-allowed;
`;

const SecondaryButton = css`
  background: #43a1fe;
  color: #fff;
  padding: 1rem 4rem;
  line-height: 1.5;
`;
const SmallButton = css`
  font-size: 1.65rem;
  letter-spacing: 0.7px;
  padding: 0.9rem 3rem;
  @media screen and (max-width: 480px) {
    padding: 0.7rem 2rem;
		font-size: 1.5rem;
		padding .8rem 3.5rem;
  }
`;

const GreenButton = css`
  background: #2ec370;
  color: #fff;
`;

const RedButton = css`
  background: #f44336;
  color: #fff;
`;

const OrangeButton = css`
  color: #fff;
  background: linear-gradient(90deg, #ff5e01 0, #ff1313);
`;

export const GoldenButton = css`
  background: #ffa500;
  color: #fff;
`;

const GrayButton = css`
  color: #fff;
  background: #444;
`;

const PastelGreenButton = css`
  background: #afffde;
`;

const ButtonWithState = css`
  cursor: not-allowed;
  color: #b6b6b6;
  &::before {
    content: "";
    display: block;
    background-position: center;
    height: 18px;
    width: 18px;
    background-repeat: no-repeat;
    margin-right: 1.5rem;
  }
`;

const ButtonLoader = css`
  ${ButtonWithState}
  &::before {
    background-image: url("/assets/button-loader.svg");
    background-size: 40px 40px;
  }
`;

const ButtonSuccess = css`
  ${ButtonWithState}
  &::before {
    background-size: 15px 20px;
    background-image: url("/assets/check.svg");
  }
`;

const ButtonError = css`
  ${ButtonWithState}
  &::before {
    background-size: 18px 18px;
    background-image: url("/assets/error.svg");
  }
`;
