import styled, { css } from "styled-components";
import { ReactComponent as OrderSuccessSVG } from "assets/order_success.svg";
import { ReactComponent as OrderErrorSVG } from "assets/error.svg";
import { ReactComponent as PaymentFailedSVG } from "assets/payment_failed.svg";
import { ReactComponent as OrderFailedSVG } from "assets/payment_success_order_fail.svg";

export const OrderStatusContainer = styled.div`
  height: 75vh;
  display: flex;
  align-items: center;
  width: 60vw;
  margin: auto;
  @media screen and (max-width: 480px) {
    width: 80vw;
    flex-direction: column;
    margin-bottom: 3rem;
  }
  ${(props) =>
    props.column &&
    `
		flex-direction: column;
    `}
`;
export const Image = css`
  width: 512px;
  @media screen and (max-width: 480px) {
    width: 256px;
  }
`;
export const OrderSuccessVector = styled(OrderSuccessSVG)`
  ${Image}
`;
export const PaymentFailedVector = styled(PaymentFailedSVG)`
  ${Image}
`;

export const PaymentSuccessOrderFail = styled(OrderFailedSVG)`
  ${Image}
`;

export const OrderErrorVector = styled(OrderErrorSVG)`
  height: 384px;
  @media screen and (max-width: 480px) {
    width: 256px;
  }
`;

export const Body = styled.div`
  button {
    margin: 3rem auto 0;
  }
`;

export const Title = styled.h3`
  font-size: 2.8rem;
  margin: 1rem 0;
  font-weight: 400;
  text-align: center;
  @media screen and (max-width: 480px) {
    font-weight: 400;
    margin: 2.5rem 0;
    font-size: 2.5rem;
    text-align: center;
  }
`;

export const Text = styled.p`
  font-size: 2rem;
  text-align: center;
`;
