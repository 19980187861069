import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  HomePageProductsContainer,
  ErrorContainer,
} from "./homepage-products.styles.jsx";
import {
  selectIsFetchingHomePage,
  selectHomePageFetchError,
  selectHomePageProducts,
} from "redux/shop/shop-selector.js";
import ProductsOverview from "components/products-overview/products-overview.jsx";
import { homePageFetchStart } from "redux/shop/shop-actions.js";
import RetryBox from "components/retry-box/retry-box.jsx";
import SpinnerOverlay from "components/with-spinner/spinner-overlay.jsx";

const FeaturedProducts = ({
  productsList,
  isFetchingHomePage,
  homePageFetch,
  homePageFetchError,
}) => {
  useEffect(() => {
    if (productsList.length === 0) homePageFetch();
  }, [homePageFetch, productsList.length]);

  if (isFetchingHomePage) return <SpinnerOverlay />;

  return (
    <HomePageProductsContainer>
      {homePageFetchError ? (
        <ErrorContainer>
          <RetryBox handleErrorRetry={homePageFetch} />
        </ErrorContainer>
      ) : (
        <ProductsOverview products={productsList} />
      )}
    </HomePageProductsContainer>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { url },
    },
  }
) => ({
  productsList: selectHomePageProducts(url)(state),
  isFetchingHomePage: selectIsFetchingHomePage(state),
  homePageFetchError: selectHomePageFetchError(state),
});

const mapDispatchToProps = (dispatch) => ({
  homePageFetch: () => dispatch(homePageFetchStart()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeaturedProducts)
);
