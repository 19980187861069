import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { setActivePaymentMethodID } from "redux/checkout/checkout-actions.js";
import { selectActivePaymentMethodID } from "redux/checkout/checkout-selector.js";
import { selectIsCartPrimeOnly } from "redux/cart/cart-selector";
import {
  Title,
  SelectBoxGroup,
  SelectBoxItem,
} from "pages/checkout/checkout.styles.jsx";
import { CashIcon, DebitCardIcon } from "./checkout-payment.styles.jsx";

const CheckoutPayment = ({
  setActivePaymentMethodID,
  activePaymentMethodID,
  isCartPrimeOnly,
}) => {
  useEffect(() => {
    if (isCartPrimeOnly) setActivePaymentMethodID(2);
  }, [isCartPrimeOnly, setActivePaymentMethodID]);

  return (
    <>
      <Title>Payment Method</Title>
      <SelectBoxGroup>
        <SelectBoxItem
          key={1}
          selected={1 === activePaymentMethodID}
          disabled={isCartPrimeOnly}
          onClick={() => {
            if (!isCartPrimeOnly) setActivePaymentMethodID(1);
          }}
        >
          <CashIcon />
          <span>COD</span>
        </SelectBoxItem>
        <SelectBoxItem
          key={2}
          selected={2 === activePaymentMethodID}
          onClick={() => setActivePaymentMethodID(2)}
        >
          <DebitCardIcon />
          <span>Online Payment</span>
        </SelectBoxItem>
      </SelectBoxGroup>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  activePaymentMethodID: selectActivePaymentMethodID,
  isCartPrimeOnly: selectIsCartPrimeOnly,
});

const mapDispatchToProps = (dispatch) => ({
  setActivePaymentMethodID: (paymentMethodID) =>
    dispatch(setActivePaymentMethodID(paymentMethodID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPayment);
