import styled from "styled-components";
import { ReactComponent as ForkLiftSVG } from "assets/forklift.svg";

export const SearchContainer = styled.div``;

export const ErrorMsg = styled.div`
  display: flex;
  height: 60vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    margin: 3rem 0;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
  }
`;

export const ForkLiftIcon = styled(ForkLiftSVG)`
  display: block;
  height: 148px;
  width: 148px;
`;
export const SearchResultsContainer = styled.div``;
