import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectSilverCoin } from "redux/user/user-selector";
import SilverCoinImage from "assets/silver-coin.png";
import GoldCoinImage from "assets/gold-coin.png";
import {
  CoinBannerContainer,
  Text,
  CoinIcon,
  Action,
} from "./coin-banner.styles.jsx";

const CoinBanner = ({ silverCoins }) => {
  if (silverCoins > 10)
    return (
      <CoinBannerContainer>
        <Text>
          <CoinIcon src={SilverCoinImage} /> You've Got {silverCoins} Silver
          Coins
        </Text>
        <Action to="/account">
          <CoinIcon src={GoldCoinImage} />
          Manage Coins
        </Action>
      </CoinBannerContainer>
    );
  return null;
};

const mapStateToProps = createStructuredSelector({
  silverCoins: selectSilverCoin,
});

export default connect(mapStateToProps)(CoinBanner);
