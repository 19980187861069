import styled from "styled-components";
import { ReactComponent as OrderSVG } from "assets/view-details.svg";

export const NoActiveOrders = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
`;
export const NoActiveOrdersText = styled.h2`
  margin: 2rem 0;
  font-size: 3rem;
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;
export const OrderIcon = styled(OrderSVG)`
  height: 96px;
  width: 96px;
`;

export const OngoingOrderContainer = styled.div`
  max-width: 80rem;
  margin: 0 auto;
`;

export const OngoingOrderItem = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.05);
  margin: 2rem 1rem;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;
export const ID = styled.h3`
  font-size: 1.7rem;
  // background: #ffe7e7;
  color: #333;
  border-radius: 15px;
  padding: 0.6rem 0;

  span {
    color: #ff460a;
    margin-left: 0.5rem;
    font-weight: 400;
  }

  ${(props) => props.large && `font-size: 2rem;`}
`;
export const Time = styled.h3`
  font-size: 1.4rem;
  margin: 0 0 0 7rem;
  text-transform: uppercase;
  font-weight: 400;
  @media screen and (max-width: 480px) {
    flex: 0 0 100%;
    order: 1;
    margin: 2rem 0rem 0;
  }
`;

export const OrderState = styled.h3`
  font-size: 1.6rem;
  margin-left: auto;
  border-radius: 25px;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  color: #fff;
  padding: 0.6rem 2rem;
  letter-spacing: 0.5px;

  ${(props) => props.large && `font-size: 1.8rem;`}

  //yellow
  ${(props) =>
    props.ID === 4 &&
    `
		background-color: #FFD159;
		color: #444;
	`}
  // cyan
  ${(props) =>
    props.ID === 3 &&
    `
		background-color: #49CFAE;
	`}
	// blue
	${(props) =>
    props.ID === 2 &&
    `
		background-color: #43A1FE;
	`}
	// green
	${(props) =>
    (props.ID === 5 || props.ID === 7) &&
    `
		background-color: #2ec370;
	`}
	// red
	${(props) =>
    props.ID === 1 &&
    `
		background-color: #FB5252;
	`}
	// orange
	${(props) =>
    props.ID === 6 &&
    `
		background-color: #FF9456;
	`}
`;
export const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 3rem;
  border-top: 1px solid #e7e7e7;
`;

export const ViewOrder = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  cursor: pointer;
  color: #666;
  font-weight: 600;
`;
export const ViewIcon = styled(OrderSVG)`
  height: 18px;
  width: 18px;
  margin-right: 0.8rem;
`;
export const OrderText = styled.h3`
  margin-left: 5.5rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: #333;
  flex: 0 0 60%;
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const Total = styled.h3`
  margin-left: auto;
  font-size: 1.8rem;
  font-weight: 400;
`;
