import styled from "styled-components";
import { Link } from "react-router-dom";

export const CoinBannerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  padding: 0.5rem;
  border-radius: 0 25px 25px;
  background: linear-gradient(45deg, #fafad2, #2ec370);
  //background: linear-gradient(45deg, #faf3d2, #ff9900);
  @media screen and (max-width: 480px) {
    margin: 0 -1.5rem;
  }
`;

export const Text = styled.p`
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  @media screen and (max-width: 480px) {
    font-size: 1.4rem;
  }
`;
export const Action = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.3rem 3rem;
  font-size: 1.7rem;
  font-weight: 700;
  border-radius: 25px;
  background: #fff;
  color: #777;
  @media screen and (max-width: 480px) {
    padding: 0.2rem 1rem 0.2rem 0.1rem;
    font-size: 1.5rem;
  }
`;

export const CoinIcon = styled.img`
  height: 36px;
  width: 36px;
  margin-right: 1rem;
  @media screen and (max-width: 480px) {
    margin-right: 0.2rem;
  }
`;
