import styled from "styled-components";
import { ReactComponent as LogoSVG } from "assets/logo.svg";
import { ReactComponent as LocationSVG } from "assets/location.svg";

export const LocationPickerContainer = styled.div`
  height: 100vh;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 0 15rem;
  align-items: center;
  height: 100vh;
  background-image: url("/assets/grocery-shopping.svg");
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 1440px) {
    background-position: -15rem -23rem;
  }

  @media screen and (max-width: 1280px) {
    background-position: -18rem -23rem;
  }

  @media screen and (max-width: 768px) {
    background-position: -14rem -4rem;
  }

  @media screen and (max-width: 480px) {
    align-items: center;
    padding: 0 2rem;
    background-position: -14rem -5rem;
    align-items: flex-end;
    justify-content: center;
  }
`;

export const Body = styled.div`
  @media screen and (max-width: 480px) {
    margin-bottom: 10em;
  }
`;

export const LocationBox = styled.div`
  margin: 2rem 0;
  background-color: #fff;
  background: rgba(255, 255, 255, 1);
  padding: 1rem 3rem;
  border-radius: 15px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  @media screen and (max-width: 1366px) {
  }

  @media screen and (max-width: 480px) {
    padding: 2rem;
  }

  select {
    direction: rtl;
    padding-right: 2rem;
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
      margin: 0;
      letter-spacing: 0;
    }
  }

  & + button {
    margin: 0 auto;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 1.5rem 0;
  @media screen and (max-width: 480px) {
    margin: 1rem 0;
  }
`;

export const Text = styled.p`
  font-size: 2rem;
  margin: 0 2rem 0 1rem;
  width: 100%;
  @media screen and (max-width: 480px) {
    font-size: 1.6rem;
    margin: 0 1.5rem 0 0;
  }
`;

export const Logo = styled(LogoSVG)`
  z-index: 1;
  position: absolute;
  top: 3rem;
  left: 2.5rem;
  width: 96px;
  height: 64px;
  @media screen and (max-width: 480px) {
    top: 1.5rem;
    left: 2.5rem;
    width: 48px;
  }
`;

export const AppIcon = styled.div`
  position: absolute;
  top: 4rem;
  left: 12rem;
  img {
    cursor: pointer;
    width: auto;
    height: 44px;
    margin: 0 0.5rem;
  }
  @media screen and (max-width: 480px) {
    top: 1.5rem;
    left: auto;
    right: 1rem;
    width: auto;
  }
`;

export const AppIconLink = styled.a`
  @media screen and (max-width: 480px) {
    display: none;
    ${(props) => props.show && `display: block`}
  }
`;

export const LocationIcon = styled(LocationSVG)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
  flex-shrink: 0;
  @media screen and (max-width: 480px) {
    margin-right: 1rem;
  }
`;
