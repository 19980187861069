import React from "react";
import { withRouter } from "react-router-dom";
import {
  MenuContainer,
  MenuItemsContainer,
  MenuItem,
  Image,
  Text,
} from "./brand-menu.styles.jsx";
import ContentLoader from "react-content-loader";
import useWindowSize from "hooks/useWindowSize.js";

const BrandMenu = ({ menuItems, history, match }) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 480;
  const menuItemsArr = Object.keys(menuItems).map((key) => menuItems[key]);
  return (
    <MenuContainer>
      <MenuItemsContainer>
        {menuItemsArr.map(({ name, image, color, slug, id }) => (
          <MenuItem
            key={id}
            onClick={() => history.push(`${match.url}brands/${slug}`)}
            color={color}
          >
            <Image
              src={`${image}`}
              alt={name}
              loader={
                <ContentLoader
                  height={128}
                  width={200}
                  speed={4}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <circle cx="100" cy="64" r="32" />
                </ContentLoader>
              }
            />
            <Text>
              <h3>
                {isMobile && name.length > 7
                  ? name.slice(0, 7).concat("..")
                  : name.length > 15
                  ? name.slice(0, 15).concat("..")
                  : name}
              </h3>
            </Text>
          </MenuItem>
        ))}
      </MenuItemsContainer>
    </MenuContainer>
  );
};

export default withRouter(BrandMenu);
