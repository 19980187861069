import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router";
import { fetchUserOrdersStart } from "redux/checkout/checkout-actions.js";
import {
  selectIsFetchingUserOrders,
  selectUserOrders,
} from "redux/checkout/checkout-selector.js";
import SpinnerOverlay from "components/with-spinner/spinner-overlay";
import {
  NoActiveOrders,
  NoActiveOrdersText,
  OrderIcon,
  OngoingOrderContainer,
  OngoingOrderItem,
  Head,
  ID,
  Time,
  OrderState,
  Footer,
  ViewOrder,
  ViewIcon,
  OrderText,
  Total,
} from "./orders.styles.jsx";

const Orders = ({
  fetchUserOrders,
  isFetchingUserOrders,
  userOrders,
  history,
}) => {
  useEffect(() => {
    fetchUserOrders();
  }, [fetchUserOrders]);

  if (isFetchingUserOrders) return <SpinnerOverlay />;

  return (
    <div>
      {userOrders.length === 0 ? (
        <NoActiveOrders>
          <OrderIcon />
          <NoActiveOrdersText>No Active Orders</NoActiveOrdersText>
        </NoActiveOrders>
      ) : (
        <OngoingOrderContainer>
          {userOrders.map(
            ({
              id,
              order_id: orderID,
              total_amount: orderTotal,
              created,
              status,
              status_text: statusText,
              item_text: itemText,
            }) => (
              <OngoingOrderItem key={id}>
                <Head>
                  <ID>
                    Order No<span>{orderID}</span>
                  </ID>
                  <Time>{`Order Placed, ${created}`}</Time>
                  <OrderState ID={status}>{statusText}</OrderState>
                </Head>
                <Footer>
                  <ViewOrder onClick={() => history.push(`/orders/${id}`)}>
                    <ViewIcon />
                    View Order
                  </ViewOrder>
                  <OrderText>{itemText.slice(0, 60)}</OrderText>
                  <Total>Total: ₹{orderTotal}</Total>
                </Footer>
              </OngoingOrderItem>
            )
          )}
        </OngoingOrderContainer>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserOrders: () => dispatch(fetchUserOrdersStart()),
});

export const mapStateToProps = createStructuredSelector({
  isFetchingUserOrders: selectIsFetchingUserOrders,
  userOrders: selectUserOrders,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));
