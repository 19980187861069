import styled from "styled-components";
import { ReactComponent as SearchSVG } from "assets/magnifying-glass.svg";

export const SearchContainer = styled.form`
  display: flex;
  align-items: center;
  flex: 0 1 28%;
  background-color: #fff;
  border-radius: 10px;
  @media screen and (min-width: 1024px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 768px) {
    border-radius: 5px;
    order: 1;
    margin-top: 1.5rem;
    flex: 0 0 100%;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 4.4rem;
  padding: 0 2rem;
  font-size: 1.5rem;
  &::focus,
  &::active {
    border-radius: 10px 0 0 10px;
  }
  @media screen and (max-width: 480px) {
    height: 3.5rem;
    padding: 0 1rem;
    margin-left: 0.7rem;
  }
`;

export const SearchIcon = styled(SearchSVG)`
  fill: #777;
  box-sizing: content-box;
  cursor: pointer;
  height: 22px;
  width: 22px;
  padding: 1.1rem 1.5rem 1.1rem 1.1rem;
  @media screen and (max-width: 480px) {
    padding: 0.65rem 1rem 0.65rem 0;
    margin-left: auto;
  }
`;
