import React from "react";
import {
  CollectionPreviewContainer,
  Head,
  Title,
  Button,
  CircleArrowIcon,
  CollectionPreviewError,
} from "./collection-preview.style.jsx";
import { withRouter } from "react-router-dom";
import ProductsOverview from "components/products-overview/products-overview.jsx";
import RetryBox from "components/retry-box/retry-box.jsx";
import useTruncate from "hooks/truncate.js";

const CollectionPreview = ({
  title,
  url,
  history,
  productsList,
  highlight,
  isProductsListFetchError,
  handleErrorRetry,
}) => {
  return (
    <CollectionPreviewContainer highlight={highlight}>
      <Head>
        <Title onClick={() => history.push(`${url}`)}>
          {useTruncate(title, 25)}
        </Title>
        <Button onClick={() => history.push(`${url}`)}>
          <span>See All</span>
          <CircleArrowIcon />
        </Button>
      </Head>
      {isProductsListFetchError ? (
        <CollectionPreviewError>
          <RetryBox handleErrorRetry={handleErrorRetry} />
        </CollectionPreviewError>
      ) : (
        <ProductsOverview products={productsList} highlight={highlight} />
      )}
    </CollectionPreviewContainer>
  );
};

export default withRouter(CollectionPreview);
