import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectIsCartEmpty } from "redux/cart/cart-selector";
import Checkout from "./checkout.jsx";

const CheckoutContainer = ({ isCartEmpty }) => {
  return !isCartEmpty ? <Checkout /> : <Redirect to="/" />;
};

const mapStateToProps = createStructuredSelector({
  isCartEmpty: selectIsCartEmpty,
});

export default connect(mapStateToProps)(CheckoutContainer);
