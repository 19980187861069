import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { categoriesListFetchStart } from "redux/shop/shop-actions.js";
import { selectActiveLocale } from "redux/shop/shop-selector";
import { setReffererCode } from "redux/user/user-actions";
import { Container } from "./App.styles.jsx";
import LocationPicker from "pages/location-picker/location-picker.jsx";
import Navbar from "components/navbar/navbar.jsx";
import Routes from "./Routes.jsx";
import Footer from "components/footer/footer.jsx";

import ReactGA from "react-ga";
ReactGA.initialize("UA-159450204-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const App = ({
  location,
  setReffererCode,
  activeLocale,
  categoriesListFetchStart,
}) => {
  const reffererCode = location.search.match(/refferal_code=([^&]*)/)?.[1];
  useEffect(() => {
    if (reffererCode) setReffererCode(reffererCode);
  }, [reffererCode, setReffererCode]);

  useEffect(() => {
    categoriesListFetchStart();
  }, [categoriesListFetchStart]);

  if (
    !activeLocale &&
    !location?.pathname.match(/conditions|privacy|about|contact/)
  ) {
    return <LocationPicker />;
  }
  return (
    <>
      <Navbar />
      <Container>
        <Routes activeLocale={activeLocale} />
      </Container>
      <Footer />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  activeLocale: selectActiveLocale,
});

const mapDispatchToProps = (dispatch) => ({
  setReffererCode: (reffererCode) => dispatch(setReffererCode(reffererCode)),
  categoriesListFetchStart: () => dispatch(categoriesListFetchStart()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
