import React from "react";
import { connect } from "react-redux";
import {
  Footer,
  AddToCartButton,
  AddToCartIcon,
  ErrorIcon,
  CartButtonOptions,
  PlusIcon,
  MinusIcon,
} from "./product-card-footer.styles.jsx";
import {
  addItemToCart,
  reduceItemFromCartStart,
} from "redux/cart/cart-actions.js";
import { selectCartItemCount } from "redux/cart/cart-selector.js";

const ProductCardFooter = ({
  cartItemCount,
  addToCart,
  reduceFromCart,
  productDataForCart,
  highlight,
}) => (
  <Footer highlight={highlight}>
    {cartItemCount === 0 ? (
      !productDataForCart.isOutOfStock ? (
        <AddToCartButton
          onClick={() => addToCart({ ...productDataForCart, cartItemCount: 1 })}
        >
          <AddToCartIcon />
          <span>Add to Cart</span>
        </AddToCartButton>
      ) : (
        <AddToCartButton unavailable="true">
          <ErrorIcon />
          <span>Out Of Stock</span>
        </AddToCartButton>
      )
    ) : (
      <CartButtonOptions>
        <MinusIcon onClick={() => reduceFromCart(productDataForCart)} />
        <span>{cartItemCount}</span>
        {productDataForCart.isOutOfStock ||
        cartItemCount === productDataForCart.maxQuantity ? (
          <PlusIcon unavailable="true" />
        ) : (
          <PlusIcon
            onClick={() => addToCart({ ...productDataForCart, cartItemCount })}
          />
        )}
      </CartButtonOptions>
    )}
  </Footer>
);

const mapStateToProps = (state, { productDataForCart: { variationID } }) => ({
  cartItemCount: selectCartItemCount(variationID)(state),
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (item) => dispatch(addItemToCart(item)),
  reduceFromCart: (item) => dispatch(reduceItemFromCartStart(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardFooter);
