import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  redeemVoucherStart,
  clearVoucherState,
} from "redux/checkout/checkout-actions.js";
import { selectVoucher } from "redux/checkout/checkout-selector";
import CustomButton from "components/custom-button/custom-button.jsx";
import {
  CheckoutVoucherContainer,
  RedeemVoucher,
} from "./checkout-voucher.styles.jsx";

const CheckoutVoucher = ({ redeemVoucher, clearVoucher, voucher }) => {
  const { isProcessing, voucherCode, success, error } = voucher || {};
  const [voucherInput, setVoucherInput] = useState("");
  const [
    isVoucherCodePulledFromRedux,
    setIsVoucherCodePulledFromRedux,
  ] = useState(false);

  useEffect(() => {
    if (voucherInput === "") {
      setVoucherInput(voucherCode);
      setIsVoucherCodePulledFromRedux(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      isVoucherCodePulledFromRedux &&
      (success || error) &&
      voucherInput !== voucherCode
    )
      clearVoucher();
  }, [
    isVoucherCodePulledFromRedux,
    success,
    error,
    voucherInput,
    voucherCode,
    clearVoucher,
  ]);

  let inputMatchVoucher = voucherInput === voucherCode;
  return (
    <CheckoutVoucherContainer>
      <RedeemVoucher>
        <input
          placeholder="Redeem Voucher"
          name="voucher"
          value={voucherInput}
          spellCheck="false"
          onChange={(e) => setVoucherInput(e.target.value)}
        />
        <CustomButton
          round
          gray
          loader={isProcessing}
          success={success && inputMatchVoucher}
          green={success && inputMatchVoucher}
          error={error && inputMatchVoucher}
          red={error && inputMatchVoucher}
          onClick={() => redeemVoucher(voucherInput)}
        >
          {success && inputMatchVoucher
            ? "Applied"
            : error && inputMatchVoucher
            ? "Invalid"
            : "Redeem"}
        </CustomButton>
      </RedeemVoucher>
    </CheckoutVoucherContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  voucher: selectVoucher,
});

const mapDispatchToProps = (dispatch) => ({
  redeemVoucher: (voucherCode) => dispatch(redeemVoucherStart(voucherCode)),
  clearVoucher: () => dispatch(clearVoucherState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutVoucher);
