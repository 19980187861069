import styled, { css } from "styled-components";
import { ReactComponent as DeliverySVG } from "assets/delivery-truck.svg";

export const DeliveryIcon = styled(DeliverySVG)`
  height: 22px;
  width: 22px;
  ${(props) =>
    props.disabled &&
    css`
      display: none;
    `}
`;
