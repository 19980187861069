import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectActiveLocale } from "redux/shop/shop-selector";
import { clearActiveLocale } from "redux/shop/shop-actions.js";
import {
  ActiveLocaleContainer,
  Locality,
  LocationIcon,
  SwapIcon,
} from "./active-locale.styles.jsx";

export const ActiveLocale = ({ activeLocale, clearActiveLocale }) => {
  return (
    <ActiveLocaleContainer onClick={clearActiveLocale}>
      {activeLocale?.city && <LocationIcon />}
      <Locality>{activeLocale?.city?.name}</Locality>
      <SwapIcon />
    </ActiveLocaleContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  activeLocale: selectActiveLocale,
});

const mapDispatchToProps = (dispatch) => ({
  clearActiveLocale: () => dispatch(clearActiveLocale()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveLocale);
