import React,{useState,useEffect,useRef} from 'react';
import {SearchContainer,SearchInput,SearchIcon} from './search-bar.styles.jsx'
import {withRouter} from 'react-router-dom';

const SearchBar = ({history}) => {

	const [searchQuery,setSearchQuery] = useState('');
	const [searchFocus,setSearchFocus] = useState(false)

	const handleChange = event => {
		const {value} = event.target;
		setSearchQuery(value)
	}
	
	const handleSearchQuery = event => {
		event.preventDefault();
		if(searchQuery !== ''){
			history.push(`/search/${searchQuery}`)
		}
	} 

	const toggleSearchFocus = () => {
		setSearchFocus(true)
	}

	const useOutsideClick = (ref) => {
	
	  const handleClickOutside = (event) => {
	    if (ref.current && !ref.current.contains(event.target)) {
	     	setSearchFocus(false);
	     	setSearchQuery('');
	    }
	  }

	  useEffect(() => {
	    document.addEventListener("mousedown", handleClickOutside);
	    return () => {
	      document.removeEventListener("mousedown", handleClickOutside);
	    };
	  });
	}

	const wrapperRef = useRef(null);
  	useOutsideClick(wrapperRef);

	return (
		<SearchContainer ref={wrapperRef} onSubmit={handleSearchQuery}>
			<SearchInput 
				onClick={toggleSearchFocus}
				focus={searchFocus}
				name="searchQuery" 
				type="text"
				value={searchQuery}
				onChange={handleChange}
				placeholder="Search Products"/>
			<SearchIcon onClick={handleSearchQuery} />
		</SearchContainer>
	)
}

export default withRouter(SearchBar);
