import styled from "styled-components";
import Img from "react-image";

export const ParentCategoriesMenuContainer = styled.div`
  background: #fff;
  padding: 1rem 0;
  margin: -3rem calc(-50vw + var(--container-width) / 2) 4rem; // Extend out of parent container
  @media screen and (max-width: 768px) {
    padding: 0;
    background: none;
    margin: 0 auto;
  }
`;

export const MenuItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1550px;
  margin: auto;
  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 10%;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 6px;

  @media screen and (max-width: 480px) {
    &:nth-child(even) {
      margin: 0 0 1rem 0.5rem;
    }
    &:nth-child(odd) {
      margin: 0 0.5rem 1rem 0;
    }
    flex-direction: row-reverse;
    justify-content: space-between;
    background: #fff;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    flex: 0 1 calc(50% - 1rem);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
    // ${(props) => props.color && `background: ${props.color}`};
  }
`;

export const Image = styled(Img)`
  height: 80px;
  display: block;
  object-fit: contain;

  @media screen and (max-width: 768px) {
    height: 60px;
  }

  @media screen and (max-width: 480px) {
    height: 56px;
    margin: 0;
  }
`;

export const Text = styled.div`
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  letter-spacing: 0.5px;
  margin: 1.5rem 0 0.75rem;

  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.3rem;
    margin: 0 0 0 0.5rem;
    h3 {
      font-weight: 400;
    }
  }
`;
