import { createSelector } from "reselect";

export const selectUser = (state) => state.user;

export const selectAccessToken = createSelector(
  [selectUser],
  (user) => user.access_token
);

export const selectUserPhoneNumber = createSelector(
  [selectUser],
  (user) => user.mobile
);

export const selectIsPrimeUser = createSelector(
  [selectUser],
  (user) => user.prime
);

export const selectSilverCoin = createSelector(
  [selectUser],
  (user) => user.silver_coins
);

export const selectAuthError = createSelector(
  [selectUser],
  (user) => user.error
);
