const INITIAL_STATE = {};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_REFFERER_CODE":
      return {
        ...state,
        reffererCode: action.payload,
      };
    case "FETCH_USER_PROFILE_START":
      return {
        ...state,
        isFetchingUserProfile: true,
      };
    case "LOGIN_SUCCESS":
    case "FETCH_USER_PROFILE_SUCCESS": {
      const accessToken = action?.payload?.access_token || state?.access_token;
      const userDetail = action.payload;
      return {
        ...state,
        ...userDetail,
        access_token: accessToken,
        isFetchingUserProfile: false,
        error: null,
      };
    }
    case "LOGIN_FAILURE":
      return {
        ...state,
        error: {
          type: "LOGIN_ERROR",
          err: action.payload,
        },
      };
    case "VERIFY_OTP_FAILURE":
      return {
        ...state,
        error: {
          type: "OTP_ERROR",
          err: action.payload,
        },
      };
    case "CLEAR_AUTH_ERROR":
      return {
        ...state,
        error: null,
      };
    case "SIGN_OUT":
    case "INVALID_ACCESS_TOKEN":
      return {};
    case "SET_USER_PRIME":
      return {
        ...state,
        prime: true,
      };
    case "REDEEM_COIN_START":
      return {
        ...state,
        isConvertingCoin: true,
      };
    case "REDEEM_COIN_SUCCESS":
      return {
        ...state,
        ...action.payload,
        isConvertingCoin: false,
      };
    case "REEDEEM_COIN_FAILURE":
      return {
        ...state,
        isConvertingCoin: false,
        coinConvertError: action.payload.message,
      };
    default:
      return state;
  }
};

export default userReducer;
