import React, { useEffect } from "react";
import { connect } from "react-redux";
import { productsListFetchStart } from "redux/shop/shop-actions.js";
import {
  selectProductsListArrayPreview,
  selectProductsListFetchNoResult,
  selectProductsListFetchError,
} from "redux/shop/shop-selector.js";
import CollectionPreview from "components/collection-preview/collection-preview.jsx";

const SubCategoryPreview = ({
  subCategory,
  productsOverviewCount,
  productsListFetchStart,
  productsList,
  productsListFetchNoResult,
  isProductsListFetchError,
}) => {
  const parentCategoryURL =
    subCategory.parentCategoryURL || subCategory.parent_slug;
  const subCategoryURL = subCategory.url || subCategory.slug;
  const categoryName = subCategory.name;
  useEffect(() => {
    if (productsOverviewCount !== 0) {
      productsListFetchStart(
        subCategoryURL,
        parentCategoryURL,
        productsOverviewCount
      );
    }
  }, [
    productsListFetchStart,
    parentCategoryURL,
    subCategoryURL,
    productsOverviewCount,
  ]);

  if (productsListFetchNoResult) return null;

  return (
    <CollectionPreview
      title={categoryName}
      url={`/shop/${parentCategoryURL}/${subCategoryURL}`}
      productsList={productsList}
      isProductsListFetchError={isProductsListFetchError}
      handleErrorRetry={() =>
        productsListFetchStart(subCategoryURL, parentCategoryURL)
      }
    />
  );
};

const mapStateToProps = (
  state,
  {
    subCategory: { url: subCategoryURL, parentCategoryURL, slug, parent_slug },
    productsOverviewCount,
  }
) => ({
  productsList: selectProductsListArrayPreview(
    subCategoryURL || slug,
    parentCategoryURL || parent_slug,
    productsOverviewCount
  )(state),
  productsListFetchNoResult: selectProductsListFetchNoResult(
    subCategoryURL || slug,
    parentCategoryURL || parent_slug
  )(state),
  isProductsListFetchError: selectProductsListFetchError(
    subCategoryURL || slug,
    parentCategoryURL || parent_slug
  )(state),
});

const mapDispatchToProps = (dispatch) => ({
  productsListFetchStart: (
    subCategoryURL,
    parentCategoryURL,
    productsOverviewCount
  ) =>
    dispatch(
      productsListFetchStart(
        subCategoryURL,
        parentCategoryURL,
        productsOverviewCount
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubCategoryPreview);
