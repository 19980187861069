import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as LogoTextSVG } from "assets/logo-text.svg";
import { ReactComponent as LogoSVG } from "assets/logo.svg";

export const Nav = styled.div`
  box-sizing: border-box;
  margin: 0 auto 1.5rem;
  padding: 1.2rem 8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #f0501a;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  transition: all 0.5s;
  @media screen and (max-width: 768px) {
    padding: 0.8rem 1.5rem 1.2rem;
    ${(props) => props.open && `box-shadow: 0 0.5rem 1.5rem rgba(0,0,0,0.25);`}
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

export const Logo = styled(LogoSVG)`
  g {
    fill: #fff;
  }
  @media screen and (max-width: 480px) {
    height: 36px;
  }
`;

export const LogoText = styled(LogoTextSVG)`
  height: 3.2rem;
  width: 12rem;
  margin-left: 1rem;
  path {
    fill: #fff;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const MenuToggle = styled.div`
  height: 22px;
  width: 32px;
  display: none;
  margin-left: 1rem;
  transition: all 0.2s;

  div {
    content: "";
    display: block;
    height: 2.5px;
    width: 100%;
    background: #fff;
    border-radius: 25px;
  }

  ${(props) =>
    props.open &&
    `
		transform: rotate(45deg);
		div:nth-child(1){
			transform: rotate(90deg) translateX(1.2rem);
			transform-origin: 50%;
		}
		div:nth-child(2){
			display: none;
		}
		div:nth-child(3){
			transform: translateY(-1rem);
			transform-origin: 50%;
		}

	`}

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 0;
  }
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1.5rem;
  margin-left: auto;
  @media screen and (max-width: 768px) {
    display: none;
    opacity: 0;
    height: 0;
    flex-direction: column;
    flex: 0 0 100%;
    margin: 1.5rem;
    align-items: flex-start;
    display: none;
    ${(props) =>
      props.show &&
      `
			display: flex;
			opacity: 1;
			height: auto;
		`}
  }
`;

export const MenuItemLink = styled(Link)`
  font-size: 2.2rem;
  font-weight: 400;
  cursor: pointer;
  text-transform: uppercase;
  list-style-type: none;
  color: #fff;
  margin-right: 3rem;

  @media screen and (max-width: 768px) {
    margin: 0.5rem 0;
    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;
