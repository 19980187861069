import { createSelector } from "reselect";

const selectShop = (state) => state.shop;

export const selectIsFetchingLocaleList = createSelector(
  [selectShop],
  (shop) => shop.isFetchingLocaleList
);

export const selectLocaleMap = createSelector(
  [selectShop],
  (shop) => shop.localeMap
);

export const selectLocaleList = createSelector([selectLocaleMap], (localeMap) =>
  localeMap ? Object.keys(localeMap).map((key) => localeMap[key]) : []
);

export const selectActiveLocale = createSelector(
  [selectShop],
  (shop) => shop.activeLocale
);

export const selectRazorpayKey = createSelector(
  [selectActiveLocale],
  (activeLocale) => activeLocale.razorpay_key
);

export const selectPrime = createSelector([selectShop], (shop) => shop.prime);

export const selectIsFetchingHomePage = createSelector(
  [selectShop],
  (shop) => shop.isFetchingHomePage
);

export const selectHomePage = createSelector(
  [selectShop],
  (shop) => shop.homePage
);

export const selectHomePageFetchError = createSelector(
  [selectShop],
  (shop) => shop?.homePageFetchError
);

export const selectHomePageProducts = (url) =>
  createSelector([selectShop], (shop) => shop?.homePage?.[url] || []);

export const selectHomePageProductsPreview = (url) =>
  createSelector([selectHomePageProducts(url)], (homePageProductsArray) => {
    if (homePageProductsArray) {
      if (homePageProductsArray.length < 12) {
        return homePageProductsArray.slice(0, 6);
      } else {
        return homePageProductsArray.slice(0, 12);
      }
    } else {
      return [];
    }
  });

export const selectIsFetchingCategoriesList = createSelector(
  [selectShop],
  (shop) => shop.isFetchingCategoriesList
);

export const selectCategoriesListFetchError = createSelector(
  [selectShop],
  (shop) => shop.categoriesListFetchError
);

export const selectIsFetchingSearchResult = (searchQuery) =>
  createSelector(
    [selectShop],
    (shop) => shop?.search?.[searchQuery]?.isFetchingSearchResult
  );

export const selectSubCategoriesSearchResult = (searchQuery) =>
  createSelector(
    [selectShop],
    (shop) => shop?.search?.[searchQuery]?.subCategoriesList || []
  );

export const selectProductsSearchResult = (searchQuery) =>
  createSelector(
    [selectShop],
    (shop) => shop?.search?.[searchQuery]?.productsMap || {}
  );

export const selectProductsSearchResultArray = (searchQuery) =>
  createSelector([selectProductsSearchResult(searchQuery)], (productsMap) =>
    productsMap ? Object.keys(productsMap).map((key) => productsMap[key]) : []
  );

export const selectParentCategoriesMap = createSelector(
  [selectShop],
  (shop) => shop?.categoriesMap || {}
);

export const selectParentCategoriesArray = createSelector(
  [selectShop],
  (shop) => shop.homePage?.categoryList || []
);

export const selectSubCategoriesMap = (parentCategoryURL) =>
  createSelector(
    [selectParentCategoriesMap],
    (parentCategoriesMap) =>
      parentCategoriesMap[parentCategoryURL]?.subCategoriesMap || {}
  );

export const selectSubCategoriesArray = (parentCategoryURL) =>
  createSelector(
    [selectSubCategoriesMap(parentCategoryURL)],
    (subCategoriesMap) =>
      subCategoriesMap
        ? Object.keys(subCategoriesMap).map((key) => subCategoriesMap[key])
        : []
  );

export const selectCategoryName = (parentCategoryURL) =>
  createSelector(
    [selectParentCategoriesMap],
    (categoriesMap) => categoriesMap[parentCategoryURL]?.name || null
  );

export const selectSubCategoryName = (parentCategoryURL, subCategoryURL) =>
  createSelector(
    [selectParentCategoriesMap],
    (categoriesMap) =>
      categoriesMap[parentCategoryURL]?.subCategoriesMap[subCategoryURL]?.name
  );
export const selectIsFetchingProductsList = (
  subCategoryURL,
  parentCategoryURL
) =>
  createSelector([selectParentCategoriesMap], (parentCategoriesMap) => {
    if (!parentCategoriesMap) {
      return true;
    }
    if (parentCategoriesMap[parentCategoryURL]) {
      let subCategoriesMap =
        parentCategoriesMap[parentCategoryURL].subCategoriesMap;
      if (!subCategoriesMap || !subCategoriesMap[subCategoryURL]) {
        return true;
      } else {
        return subCategoriesMap[subCategoryURL].isFetchingProductsList;
      }
    } else {
      return true;
    }
  });

export const selectProductsListFetchNoResult = (
  subCategoryURL,
  parentCategoryURL
) =>
  createSelector(
    [selectSubCategoriesMap(parentCategoryURL)],
    (subCategoriesMap) =>
      subCategoriesMap[subCategoryURL]?.productsListFetchNoResult
  );

export const selectProductsListFetchError = (
  subCategoryURL,
  parentCategoryURL
) =>
  createSelector(
    [selectSubCategoriesMap(parentCategoryURL)],
    (subCategoriesMap) =>
      subCategoriesMap[subCategoryURL]?.productsListFetchError
  );

export const selectProductsListMap = (subCategoryURL, parentCategoryURL) =>
  createSelector(
    [selectSubCategoriesMap(parentCategoryURL)],
    (subCategoriesMap) => subCategoriesMap[subCategoryURL]?.productsList || {}
  );

export const selectProductsListArray = (subCategoryURL, parentCategoryURL) =>
  createSelector(
    [selectProductsListMap(subCategoryURL, parentCategoryURL)],
    (productsListMap) => {
      if (productsListMap) {
        const arr = Object.keys(productsListMap).map(
          (key) => productsListMap[key]
        );
        return arr;
      } else {
        return [];
      }
    }
  );

export const selectProductsListArrayPreview = (
  subCategoryURL,
  parentCategoryURL,
  productOverviewCount
) =>
  createSelector(
    [selectProductsListArray(subCategoryURL, parentCategoryURL)],
    (productsListArray) => {
      let data = productsListArray.slice(0, productOverviewCount);
      return data;
    }
  );

export const selectFeaturedBrands = createSelector(
  [selectShop],
  (shop) => shop?.homePage?.featuredBrands || {}
);

export const selectIsFetchingProductsByBrand = (brandURL) =>
  createSelector(
    [selectShop],
    (shop) => shop.homePage?.featuredBrands[brandURL]?.isFetchingProductsByBrand
  );

export const selectProductsByBrandError = (brandURL) =>
  createSelector(
    [selectShop],
    (shop) =>
      shop.homePage?.featuredBrands[brandURL]?.productsByBrandFetchError || null
  );

export const selectProductsByBrandMap = (brandURL) =>
  createSelector(
    [selectShop],
    (shop) => shop.homePage?.featuredBrands[brandURL]?.productsList || []
  );

export const selectProductsByBrandList = (brandURL) =>
  createSelector([selectProductsByBrandMap(brandURL)], (productsListMap) => {
    if (productsListMap) {
      const arr = Object.keys(productsListMap).map(
        (key) => productsListMap[key]
      );
      return arr;
    } else {
      return [];
    }
  });
