import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  fetchOrderDetailStart,
  cancelOrderStart,
} from "redux/checkout/checkout-actions";
import { selectOrderDetail } from "redux/checkout/checkout-selector";
import SpinnerOverlay from "components/with-spinner/spinner-overlay.jsx";
import {
  OrderDetailContainer,
  Flex,
  Title,
  OrderCart,
  CartItem,
  Image,
  Text,
  Address,
} from "./order-detail.styles.jsx";
import { ID, OrderState } from "pages/orders/orders.styles.jsx";
import { DeliveryIcon } from "components/checkout-time/checkout-time.styles";
import { SelectBoxItem } from "pages/checkout/checkout.styles";
import CustomButton from "components/custom-button/custom-button";

const OrderDetail = ({ match, fetchOrderDetail, orderDetail, cancelOrder }) => {
  const orderID = match.params.orderID;

  useEffect(() => {
    fetchOrderDetail(orderID);
  }, [fetchOrderDetail, orderID]);

  if (!orderDetail || orderDetail.isFetchingOrderDetail) {
    return <SpinnerOverlay />;
  } else {
    return (
      <OrderDetailContainer>
        <Flex>
          <ID large>
            Order No <span>{orderDetail.order_id}</span>
          </ID>
          <CustomButton
            pastelGreen
            disabled={!orderDetail.cancellable}
            loader={orderDetail.isCancellingOrder}
            onClick={() => {
              if (orderDetail.cancellable) cancelOrder(orderID);
            }}
          >
            <span>Cancel Order</span>
          </CustomButton>
          <OrderState large ID={orderDetail.status_id}>
            {orderDetail.status}
          </OrderState>
        </Flex>
        <Title>Delivery Slot</Title>
        <SelectBoxItem selected round>
          <DeliveryIcon />
          <span>
            {`${orderDetail.slot_date} ${orderDetail.start_time} - ${orderDetail.end_time}`}
          </span>
        </SelectBoxItem>
        <Title>Shoppping Cart</Title>
        <OrderCart>
          {orderDetail.product_data.map((product) => (
            <CartItem key={product.id}>
              <Image src={product.images[0]} />
              <Text>{product.name}</Text>
              <Text>{product.variant}</Text>
              <Text>{` x  ${product.quantity}`}</Text>
              <Text>₹{product.sell_price}</Text>
            </CartItem>
          ))}
          <CartItem>
            <Text>₹{orderDetail.payment_data["Total Amount"]}</Text>
          </CartItem>
        </OrderCart>
        <Title>Delivery Address</Title>
        <Address>
          {orderDetail.address.name}
          <br />
          {orderDetail.address.address_1}
          <br />
          {orderDetail.address.address_2}
          {orderDetail.address.address_2 && <br />}
          {`${orderDetail.address.address_location}, ${orderDetail.address.city}`}
          <br />
          {orderDetail.address.mobile}
        </Address>
        <Title>Payment Method</Title>
        <SelectBoxItem selected round>
          {orderDetail.payment_method_text}
        </SelectBoxItem>
      </OrderDetailContainer>
    );
  }
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { orderID },
    },
  }
) => ({
  orderDetail: selectOrderDetail(orderID)(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrderDetail: (orderID) => dispatch(fetchOrderDetailStart(orderID)),
  cancelOrder: (orderID) => dispatch(cancelOrderStart(orderID)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetail)
);
