import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  fetchUserProfileStart,
  redeemCoinStart,
  signOut,
} from "redux/user/user-actions";
import { selectUser } from "redux/user/user-selector";
import CustomButton from "components/custom-button/custom-button.jsx";
import SilverCoinImage from "assets/silver-coin-double.png";
import GoldCoinImage from "assets/gold-coin-double.png";
import AvatarImage from "assets/avatar.png";
import {
  AccountPageContainer,
  User,
  Avatar,
  SignOut,
  LogoutIcon,
  Rewards,
  Coin,
  CoinIncrement,
  PlusIcon,
  MinusIcon,
  ConvertCoin,
  Referral,
  RefferIcon,
  Code,
  ShareIcon,
} from "./account.styles.jsx";

const AccountPage = ({ fetchUserProfile, redeemCoin, user, signOut }) => {
  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  const [coinCount, setCoinCount] = useState(0);

  return (
    <AccountPageContainer>
      <User>
        <Avatar>
          <img src={AvatarImage} alt="avatar" />
        </Avatar>
        <h3>{user.name.split(/(\s+)/).slice(0, 3)}</h3>
        <SignOut onClick={() => signOut()}>
          <LogoutIcon /> <span>Sign Out</span>
        </SignOut>
      </User>
      <Referral>
        <h3>Refer &amp; Earn Gold</h3>
        <RefferIcon />
        <Code>{user.referral_code}</Code>
        <a
          href={`https://goodoor.page.link/?link=https://www.goodoor.in/?referral_code=${user.referral_code}&apn=in.goodoor.android&ibi=com.goodoor.user&isi=1564702191`}
        >
          <ShareIcon />
        </a>
      </Referral>

      <Rewards>
        <Coin>
          <img src={SilverCoinImage} alt="Silver Coin" />
          <span>
            {user?.isFetchingUserProfile ? (
              <img src="/assets/button-loader.svg" alt="loading-spinner" />
            ) : (
              user.silver_coins
            )}
          </span>
        </Coin>
        <ConvertCoin>
          <CoinIncrement>
            <MinusIcon
              onClick={() => {
                if (coinCount !== 0)
                  setCoinCount((prevCount) => prevCount - 10);
              }}
            />
            <span>{coinCount}</span>
            <PlusIcon
              onClick={() => {
                if (coinCount + 10 < user.silver_coins)
                  setCoinCount((prevCount) => prevCount + 10);
              }}
            />
          </CoinIncrement>
          <CustomButton
            secondary
            golden
            small
            loader={user?.isConvertingCoin}
            restricted={coinCount < 10}
            onClick={() => {
              if (coinCount >= 10) redeemCoin(coinCount);
            }}
          >
            Convert To Gold
          </CustomButton>
        </ConvertCoin>
        <Coin>
          <img src={GoldCoinImage} alt="Gold Coin" />
          <span>
            {user?.isFetchingUserProfile ? (
              <img src="/assets/button-loader.svg" alt="loading-spinner" />
            ) : (
              user.gold_coins
            )}
          </span>
        </Coin>
        <p>
          {user?.coinConvertError ||
            "You can convert 10 silver coins for a gold coin"}
        </p>
      </Rewards>
    </AccountPageContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserProfile: () => dispatch(fetchUserProfileStart()),
  redeemCoin: (count) => dispatch(redeemCoinStart(count)),
  signOut: () => dispatch(signOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
