import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import WithSpinner from "../with-spinner/with-spinner.component.jsx";
import { selectIsFetchingCategoriesList } from "../../redux/shop/shop-selector.js";
import ParentCategoriesMenu from "./parent-categories-menu.jsx";

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsFetchingCategoriesList
});

const ParentCategoriesMenuContainer = connect(mapStateToProps)(
  WithSpinner(ParentCategoriesMenu)
);

export default ParentCategoriesMenuContainer;
