import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  signInStart,
  signUpStart,
  verifyOTPStart,
  clearAuthError,
} from "redux/user/user-actions.js";
import { selectAccessToken, selectAuthError } from "redux/user/user-selector";
import Select from "components/select/select.jsx";
import FormInput from "components/form-input/form-input.jsx";
import CustomButton from "components/custom-button/custom-button.jsx";
import countryCodeData from "assets/countryCode.js";
import {
  SignInSignUpContainer,
  FormContainer,
  Title,
  InputGroup,
  NameIcon,
  PhoneNumber,
  PhoneIcon,
  LockIcon,
} from "./sign-in-sign-up.styles.jsx";

const SignInSignUp = ({
  type,
  userToken,
  authError,
  clearAuthError,
  signIn,
  signUp,
  verifyOTP,
}) => {
  useEffect(() => {
    clearAuthError();
  }, [clearAuthError]); //Clear Auth Error On Remount

  const { state } = useLocation();
  const [redirectToRef, setRedirectToRef] = useState(false);
  const [resendOTPLoader, setResendOTPLoader] = useState(false);

  useEffect(() => {
    userToken && setRedirectToRef(true);
  }, [userToken]);

  const isInvalidOTP = authError?.type === "OTP_ERROR";

  useEffect(() => {
    if (isInvalidOTP) {
      setAuthForm((prevState) => ({ ...prevState, loader: false }));
    }
  }, [authError, isInvalidOTP]);

  const [authForm, setAuthForm] = useState({
    step: 1,
    userName: "",
    countryCode: "+91",
    phoneNo: "",
    OTP: "",
    loader: false,
  });

  const { step, userName, countryCode, phoneNo, OTP, loader } = authForm;
  const phoneNoWithCountryCode = countryCode + phoneNo;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAuthForm({ ...authForm, [name]: value });
  };

  const handleDetailSubmit = async (event) => {
    event && event.preventDefault();
    clearAuthError();
    setAuthForm({ ...authForm, loader: true });
    if (type === "Login") signIn(phoneNoWithCountryCode);
    if (type === "SignUp") signUp(userName, phoneNoWithCountryCode);
    setAuthForm({ ...authForm, OTP: "", loader: false, step: 2 });
  };

  const handleOTPSubmit = async (event) => {
    event.preventDefault();
    setAuthForm({ ...authForm, loader: true });
    verifyOTP(phoneNoWithCountryCode, OTP);
  };

  if (redirectToRef) {
    return <Redirect to={state ? state.from : "/"} />;
  }

  return (
    <SignInSignUpContainer>
      {step === 1 && (
        <FormContainer>
          {type === "SignUp" && <Title>Create New Account</Title>}
          <form onSubmit={handleDetailSubmit}>
            {type === "SignUp" && (
              <InputGroup>
                <NameIcon />
                <FormInput
                  label="Name"
                  name="userName"
                  type="text"
                  value={userName}
                  handleChange={handleChange}
                  required
                />
              </InputGroup>
            )}
            <InputGroup>
              <PhoneIcon />
              <PhoneNumber>
                <Select
                  name="countryCode"
                  onChange={handleChange}
                  defaultValue="+91"
                >
                  {countryCodeData.map((countryCode) => (
                    <option key={countryCode.id} value={countryCode.code}>
                      {countryCode.id} {countryCode.code}
                    </option>
                  ))}
                </Select>
                <FormInput
                  label="Phone Number"
                  name="phoneNo"
                  type="tel"
                  value={phoneNo}
                  pattern="^[1-9][0-9]{9}"
                  handleChange={handleChange}
                  required
                />
              </PhoneNumber>
            </InputGroup>
            <CustomButton secondary type="submit" loader={loader}>
              {type}
            </CustomButton>
          </form>
        </FormContainer>
      )}
      {step === 2 && (
        <FormContainer>
          <form onSubmit={handleOTPSubmit}>
            <InputGroup>
              <LockIcon />
              <FormInput
                label={!isInvalidOTP ? "OTP" : "OTP Error"}
                name="OTP"
                type="text"
                value={OTP}
                handleChange={handleChange}
                required
                error={isInvalidOTP}
              />
            </InputGroup>
            <CustomButton
              secondary
              green
              loader={loader}
              disabled={isInvalidOTP}
              type="submit"
            >
              Confirm OTP
            </CustomButton>
            <CustomButton
              secondary
              loader={resendOTPLoader}
              onClick={(e) => {
                e.preventDefault();
                setResendOTPLoader(true);
                handleDetailSubmit();
                setTimeout(() => setResendOTPLoader(false), 500);
              }}
            >
              Resend OTP
            </CustomButton>
          </form>
        </FormContainer>
      )}
    </SignInSignUpContainer>
  );
};

export const mapStateToProps = createStructuredSelector({
  userToken: selectAccessToken,
  authError: selectAuthError,
});

export const mapDispatchToProps = (dispatch) => ({
  signIn: (phoneNo) => dispatch(signInStart(phoneNo)),
  signUp: (userName, phoneNo) => dispatch(signUpStart(userName, phoneNo)),
  verifyOTP: (phoneNo, OTP) => dispatch(verifyOTPStart(phoneNo, OTP)),
  clearAuthError: () => dispatch(clearAuthError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInSignUp);
