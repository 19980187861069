export const fmtCartResponse = (cart) => {
  /*const cartArr = cart.cart.map((cartItem) => ({
    variationID: cartItem.variation_id,
    productName: cartItem.name,
    productImage: cartItem.images[0],
    cartItemCount: cartItem.count,
    sellingPrice: cartItem.offer_price,
	}));*/
  return {
    cartID: cart.cart_id,
    subTotal: cart.subtotal,
    coinRedeem: cart.coin_redeem,
    deliveryCharge: cart.delivery_charge,
    gatewayCharge: cart.gateway_charges,
    savings: cart.savings,
    grandTotal: cart.amount_to_pay,
  };
};

/*export const addCartID = (cartItems,{cart_id,variationID}) => {
	return cartItems.reduce((cartItems,cartItem) => {
		if(cartItem.variationID === variationID){
			return [...cartItems,{...cartItem,cart_id}]
		} else {
			return [...cartItems,cartItem]
		}
	},[])
}
# Use this code if invidual cartID value is needed */

export const updateCartCount = (cart, cartItemToUpdate) => {
  let updatedCartItems = cart.items.reduce((cartItems, cartItem) => {
    if (cartItem.variationID === cartItemToUpdate.variationID) {
      if (cartItemToUpdate.cartItemCount > 0) {
        return [
          ...cartItems,
          { ...cartItem, cartItemCount: cartItemToUpdate.cartItemCount },
        ];
      } else {
        return cartItems;
      }
    } else {
      return [...cartItems, cartItem];
    }
  }, []);
  if (updatedCartItems.length > 0) {
    return { ...cart, items: updatedCartItems };
  } else {
    return { shown: false, items: [] }; // Clear Cart When No Item Left In Cart
  }
};
