import React, { useState, useEffect, useRef } from "react";
import {
  ProductVariationsContainer,
  SingleVariation,
  Select,
  SelectTrigger,
  Arrow,
  SelectOptions,
  Option,
} from "./product-variations.styles.jsx";

const ProductVariations = ({
  productVariations,
  activeVariation,
  setActiveVariationIndex,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const useOutsideClick = (ref) => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  const getActiveVariationQty = () => {
    return activeVariation.quantity.length > 18
      ? activeVariation.quantity.slice(0, 18).concat("..")
      : activeVariation.quantity;
  };

  return (
    <ProductVariationsContainer>
      {productVariations.length === 1 ? (
        <SingleVariation>{getActiveVariationQty()}</SingleVariation>
      ) : (
        <Select ref={wrapperRef}>
          <SelectTrigger
            onClick={() => setIsDropdownOpen((prevState) => !prevState)}
          >
            <span>{getActiveVariationQty()}</span>
            <Arrow open={isDropdownOpen} />
          </SelectTrigger>
          <SelectOptions show={isDropdownOpen}>
            {productVariations.map((variation, index) => (
              <Option
                onClick={() => {
                  setIsDropdownOpen(false);
                  setActiveVariationIndex(index);
                }}
                key={variation.id}
              >
                {variation.quantity}
              </Option>
            ))}
          </SelectOptions>
        </Select>
      )}
    </ProductVariationsContainer>
  );
};

export default ProductVariations;
