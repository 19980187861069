import React from "react";
export default function useIOSDetect() {
  const [isIOS, setIsIOS] = React.useState(true);

  React.useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const ios = Boolean(userAgent.match(/iPhone|iPad|iPod/i));
    setIsIOS(ios);
  }, []);
  return { isIOS };
}
