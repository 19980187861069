export const fmtDeliveryTime = (deliveryTime) => {
  return deliveryTime.reduce((acc, item) => {
    acc.push(
      ...item.time_slots.map((slot) => ({
        ...slot,
        dateString: item.date_string,
        date: item.date,
        month: item.month,
      }))
    );
    return acc;
  }, []);
};
