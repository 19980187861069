import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import WithSpinner from '../../components/with-spinner/with-spinner.component.jsx';
import OrderStatus from './orderstatus.jsx';
import {selectIsProcessingOrder} from '../../redux/checkout/checkout-selector.js';

const mapStateToProps = createStructuredSelector({
	isLoading: selectIsProcessingOrder
})

const OrderStatusContainer = connect(mapStateToProps)(WithSpinner(OrderStatus))

export default OrderStatusContainer;

