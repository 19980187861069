import styled, { css } from "styled-components";
import { ReactComponent as ShareSVG } from "assets/share.svg";
import { ReactComponent as PlusSVG } from "assets/plus-thin.svg";
import { ReactComponent as MinusSVG } from "assets/minus-thin.svg";
import { ReactComponent as LogoutSVG } from "assets/logout.svg";
import { ReactComponent as RefferSVG } from "assets/refer.svg";

export const AccountPageContainer = styled.div`
  max-width: 950px;
  margin: 4rem auto;
  display: flex;
  flex-wrap: wrap;
  & > * {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
  }

  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }

  @media screen and (max-width: 480px) {
    margin: -1rem auto;
  }
`;
export const User = styled.div`
  background: #fff;
  padding: 1rem;
  margin: 1rem 1rem 1rem 0rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/assets/blob-inverted.svg");
  h3 {
    font-size: 2.2em;
    margin: 1rem;
  }

  @media screen and (max-width: 480px) {
    background-image: url("/assets/blob.svg");
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem;
    flex: 0 0 100%;
    h3 {
      font-size: 1.9rem;
      margin: 0;
    }
  }
`;
export const Avatar = styled.div`
  border-radius: 50%;
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: contain;
    height: 48px;
    width: 48px;
  }
  @media screen and (max-width: 480px) {
    height: 48px;
    width: 48px;
    margin-bottom: 0;
    background: none;
    img {
      height: 32px;
      width: 32px;
    }
  }
`;

export const SignOut = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem 3rem;
  border-radius: 25px;
  color: #333;
  background: none;
  span {
    margin-left: 0.5rem;
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: 1px;
  }
`;

export const LogoutIcon = styled(LogoutSVG)`
  height: 18px;
  width: 18px;
`;
// ff5e00 ff4412
export const Rewards = styled.div`
  background-color: #fff;
  background-image: url("/assets/blob-inverted.svg");
  background-repeat: no-repeat;
  background-position: 0% 92%;
  background-size: cover;
  border-radius: 10px;
  padding: 3rem 3rem 2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  flex: 0 0 100%;

  p {
    font-size: 2rem;
    flex: 0 0 100%;
    margin-top: 1.5rem;
    text-align: center;
  }
  & > * {
    user-select: none;
  }
  @media screen and (max-width: 768px) {
    p {
      font-size: 1.8rem;
    }
  }
  @media screen and (max-width: 480px) {
    padding: 2rem 0.5rem;
    //background-image: none;
    p {
      font-size: 1.5rem;
      margin-top: 1rem;
      color: #444;
    }
  }
`;

export const Coin = styled.div`
  position: relative;
  img {
    height: 112px;
    width: 128px;
  }
  span {
    font-family: "Montserrat", sans-serif;
    position: absolute;
    top: 0;
    right: 0;
    height: 32px;
    width: 32px;
    background: #555;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      height: 26px;
    }
  }
  @media screen and (max-width: 480px) {
    margin-top: 3rem;
    img {
      height: 52px;
      width: 58px;
    }
    span {
      height: 22px;
      width: 22px;
      font-size: 1.7rem;
    }
  }
`;

export const ConvertCoin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const CoinIncrement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  span {
    font-family: "Montserrat", sans-serif;
    font-size: 3rem;
    font-weight: 700;
    margin: 0 3.5rem;
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 1rem;
    span {
      font-size: 2rem;
      font-size: 2.3rem;
      margin: 0 3rem;
    }
  }
`;

const IconCSS = css`
  cursor: pointer;
  height: 28px;
  width: 28px;
  @media screen and (max-width: 480px) {
    height: 22px;
    width: 22px;
  }
`;

export const PlusIcon = styled(PlusSVG)`
  ${IconCSS}
  fill: green;
`;
export const MinusIcon = styled(MinusSVG)`
  ${IconCSS}
  fill: #f33;
`;
export const Referral = styled.div`
  background: #fff;
  flex: 1;
  order-radius: 10px;
  padding: 1rem 5rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  h3 {
    font-size: 2rem;
    margin-right: 2rem;
  }
  @media screen and (max-width: 768px) {
    padding: 1rem 2rem;
    h3 {
      font-size: 1.6rem;
      margin-right: 1rem;
    }
  }
  @media screen and (max-width: 480px) {
    //order: 1;
    flex: 0 0 100%;
    padding: 1.7rem 2.5rem;
    background: url("/assets/blob.svg");
    background-size: cover;
    background-position: 100% 100%;
    margin: 0 0 2rem;
    h3 {
      font-size: 2rem;
      margin-right: 2rem;
    }
  }
`;
export const RefferIcon = styled(RefferSVG)`
  height: 32px;
  width: 32px;
  fill: #555;
  @media screen and (max-width: 480px) {
    height: 26px;
    width: 26px;
  }
`;
export const Code = styled.h3`
  font-size: 3rem;
  padding: 0.5rem 2rem;
  background: #ff5e01;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  @media screen and (max-width: 480px) {
    font-size: 2.5rem;
    background: none;
    color: #ff5e01;
  }
`;

export const ShareIcon = styled(ShareSVG)`
  height: 32px;
  width: 32px;
  cursor: pointer;
  fill: #555;
  @media screen and (max-width: 480px) {
    height: 26px;
    width: 26px;
  }
`;
