import styled from "styled-components";

export const AuthContainer = styled.div`
  margin-top: -5.5rem;
  height: 100vh;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 0 15rem;
  align-items: center;
  height: 100vh;
  background-image: url("/assets/grocery-shopping.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -18rem -23rem;

  @media screen and (max-width: 480px) {
    align-items: center;
    padding: 0 1.5rem;
    background-image: url("/assets/grocery-shopping.svg");
    background-position: -14rem -5rem;
    align-items: flex-end;
    justify-content: center;
  }

  @media screen and (min-width: 480px and max-width: 1366px) {
    background-position: -15rem -23rem;
  }
`;

export const AuthBox = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column-reverse;
  @media screen and (max-width: 480px) {
    margin-bottom: 19rem;
    flex-direction: column;
  }
`;
export const RegisterToggle = styled.div`
  display: none;
  background: rgba(255, 255, 255, 1);
  justify-content: center;
  align-items: center;
  padding: 0.6rem 0.5rem;
  margin: 0 1rem;
  border-radius: 25px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 480px) {
    margin: 0 0.2rem;
    padding: 0.4rem 0.5rem;
  }

  ${(props) =>
    props.show &&
    `
			display:flex;
	`}
`;

export const Text = styled.p`
  font-size: 1.6rem;
  color: #444;
  width: 100%;
  margin-left: 3rem;
  @media screen and (max-width: 480px) {
    font-size: 1.6rem;
  }
`;

export const SignInUpContainer = styled.div`
  display: none;
  ${(props) => props.show && `display: flex`}
`;

export const PlaystoreIcon = styled.img`
  cursor: pointer;
  position: absolute;
  top: 2.9rem;
  left: 12rem;
  width: auto;
  height: 64px;
  @media screen and (max-width: 480px) {
    top: 1.5rem;
    left: auto;
    right: 1rem;
    width: auto;
  }
`;
