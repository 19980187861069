// Order Status Code Reference
// 0 - No Active Orders
// 1 - Failed To Process COD Order or Failed To Create Razorapay Order ID
// 2 - Razorpay Payment Failed
// 3 - Razorpay Payment Successful, Failed To Verify & Update Payment in Server
// 4 - Order Successful

const INITIAL_STATE = {
  isProcessingOrder: false,
  orderStatusCode: 0,
  activePaymentMethodID: 1,
  orderDetails: {},
  addressField: {
    firstName: "",
    lastName: "",
    address: "",
    phoneNumber: "",
    isNewCustomerAddress: false,
  },
  voucher: {
    voucherCode: "",
    isProcessing: false,
    success: false,
    error: false,
  },
};

const checkoutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CHECKOUT_ORDER_SUCCESS":
      const { deliveryTime } = action.payload;
      return {
        ...state,
        deliveryTime,
      };
    case "REDEEM_VOUCHER_START":
      const { voucherCode } = action.payload;
      return {
        ...state,
        voucher: {
          voucherCode,
          isProcessing: true,
        },
      };
    case "REDEEM_VOUCHER_SUCCESS":
      return {
        ...state,
        voucher: {
          ...state.voucher,
          isProcessing: false,
          success: true,
          error: false,
        },
      };
    case "REEDEEM_VOUCHER_FAILURE":
      return {
        ...state,
        voucher: {
          ...state.voucher,
          isProcessing: false,
          success: false,
          error: true,
        },
      };
    case "CLEAR_VOUCHER_STATE":
      return {
        ...state,
        voucher: {
          voucherCode: "",
          isProcessing: false,
          success: false,
          error: false,
        },
      };
    case "FETCH_CUSTOMER_ADDRESS_START":
      return {
        ...state,
        isFetchingCustomerAddress: true,
      };

    case "FETCH_CUSTOMER_ADDRESS_SUCCESS":
      return {
        ...state,
        isFetchingCustomerAddress: false,
        customerAddress: action.payload,
        customerAddressError: null,
      };

    case "FETCH_CUSTOMER_ADDRESS_FAILURE":
      return {
        ...state,
        isFetchingCustomerAddress: false,
        customerAddress: null,
        customerAddressError: action.payload,
      };
    case "SET_ACTIVE_ADDRESS_ID":
      return {
        ...state,
        activeAddressID: action.payload,
      };
    case "SET_CUSTOMER_ADDRESS_FIELD":
      return {
        ...state,
        addressField: {
          ...state.addressField,
          ...action.payload,
        },
      };
    case "CLEAR_CUSTOMER_ADDRESS_FIELD":
      return {
        ...state,
        addressField: {
          firstName: "",
          lastName: "",
          address: "",
          phoneNumber: "",
          isNewCustomerAddress: false,
        },
      };
    case "SET_ACTIVE_DELIVERY_TIME":
      return {
        ...state,
        activeDeliveryTime: action.payload,
      };

    case "SET_ACTIVE_PAYMENT_METHOD_ID":
      return {
        ...state,
        activePaymentMethodID: action.payload,
      };

    case "COMPLETE_ORDER_START":
      return {
        ...state,
        isProcessingOrder: true,
      };
    case "COMPLETE_ORDER_SUCCESS":
      const { orderID, razorpayOrderID, earnedSilverCoins } = action.payload;
      return {
        ...state,
        isProcessingOrder: false,
        orderID,
        razorpayOrderID,
        razorpayPaymentVerificationDetail: {},
        earnedSilverCoins,
      };

    case "SET_ORDER_STATUS":
      return {
        ...state,
        isProcessingOrder: false,
        orderStatusCode: action.payload,
      };

    case "ONLINE_PAYMENT_FAILURE_RECOVER_STATE":
      return {
        ...state,
        rzpPaymentID: action.payload,
      };

    case "FETCH_USER_ORDERS_START":
      return {
        ...state,
        isFetchingUserOrders: true,
      };
    case "FETCH_USER_ORDERS_SUCCESS":
      return {
        ...state,
        userOrders: action.payload,
        isFetchingUserOrders: false,
      };

    case "FETCH_ORDER_DETAIL_START": {
      const orderID = action.payload;
      const orderDetails = state.orderDetails;
      return {
        ...state,
        orderDetails: {
          ...orderDetails,
          [orderID]: {
            isFetchingOrderDetail: true,
          },
        },
      };
    }
    case "FETCH_ORDER_DETAIL_SUCCESS": {
      const { orderID, orderDetail } = action.payload;
      const orderDetails = state.orderDetails;
      return {
        ...state,
        orderDetails: {
          ...orderDetails,
          [orderID]: {
            ...orderDetail,
            isFetchingOrderDetail: false,
          },
        },
      };
    }

    case "CANCEL_ORDER_START": {
      const { orderID } = action.payload;
      const orderDetails = state.orderDetails;
      return {
        ...state,
        orderDetails: {
          ...orderDetails,
          [orderID]: {
            ...orderDetails[orderID],
            isCancellingOrder: true,
          },
        },
      };
    }
    case "CANCEL_ORDER_SUCCESS": {
      const { orderID } = action.payload;
      const orderDetails = state.orderDetails;
      return {
        ...state,
        orderDetails: {
          ...orderDetails,
          [orderID]: {
            ...orderDetails[orderID],
            isCancellingOrder: false,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default checkoutReducer;
