import React from "react";
import {
  ProductPreviewCardContainer,
  Body,
  Title,
  PriceContainer,
} from "../product-preview-card/product-preview-card.styles.jsx";
import { Footer } from "components/product-card-footer/product-card-footer.styles.jsx";
import { SingleVariation } from "components/product-variations/product-variations.styles";
import ContentLoader from "react-content-loader";

const SkeletonProductPreviewCard = () => (
  <ProductPreviewCardContainer>
    <ContentLoader
      height={240}
      width={320}
      speed={3}
      primaryColor="#dfdcdc"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="320" height="250" />
    </ContentLoader>

    <Body>
      <Title skeleton></Title>
      <PriceContainer skeleton></PriceContainer>
      <SingleVariation skeleton></SingleVariation>
    </Body>
    <Footer skeleton></Footer>
  </ProductPreviewCardContainer>
);

export default SkeletonProductPreviewCard;
