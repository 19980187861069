import {
  takeLatest,
  takeEvery,
  take,
  select,
  call,
  all,
  put,
} from "redux-saga/effects";
import {
  getCitiesAndLocations,
  getPrime,
  getHomePage,
  getCategoriesList,
  getProductsList,
  getProductsListByBrand,
  getSearchResult,
} from "backend/api.js";
import {
  localeListFetchSuccess,
  fetchPrimeSuccess,
  homePageFetchSuccess,
  homePageFetchFailure,
  categoriesListFetchSuccess,
  categoriesListFetchFailure,
  productsListFetchSuccess,
  productsListFetchNoResult,
  productsListFetchFailure,
  productsByBrandFetchSuccess,
  productsByBrandFetchFailure,
  searchSuccess,
} from "./shop-actions.js";

import {
  fmtLocaleList,
  fmtHomePageFields,
  fmtCategoriesList,
  fmtProductList,
} from "./shop-utils.js";

const selectCategories = (state) => state.shop.categoriesMap;
const selectCityID = (state) => state.shop.activeLocale.city.id;
const selectBrands = (state) => state.shop.homePage.featuredBrands;

export function* localeListFetch() {
  try {
    const localeList = yield getCitiesAndLocations();
    yield put(localeListFetchSuccess(fmtLocaleList(localeList)));
  } catch (error) {}
}

export function* onLocaleListFetch() {
  yield takeLatest("LOCALE_LIST_FETCH_START", localeListFetch);
}
export function* homePageFetch() {
  try {
    const cityID = yield select(selectCityID);
    const homePage = yield getHomePage(cityID);
    yield put(homePageFetchSuccess(fmtHomePageFields(homePage)));
  } catch (error) {
    yield put(homePageFetchFailure(error));
  }
}

export function* onHomePageFetch() {
  yield takeLatest("HOMEPAGE_FETCH_START", homePageFetch);
}

export function* primeFetch() {
  try {
    const cityID = yield select(selectCityID);
    const { data: primePlan } = yield getPrime(cityID);
    yield put(fetchPrimeSuccess(primePlan));
  } catch (e) {
    console.log(e);
  }
}

export function* onPrimeFetch() {
  yield takeLatest("FETCH_PRIME_START", primeFetch);
}

export function* categoriesFetch() {
  try {
    const cityID = yield select(selectCityID);
    const categoriesList = yield getCategoriesList(cityID);
    yield put(categoriesListFetchSuccess(fmtCategoriesList(categoriesList)));
  } catch (error) {
    yield put(categoriesListFetchFailure(error));
  }
}

export function* onCategoriesListFetch() {
  yield takeLatest("CATEGORIES_LIST_FETCH_START", categoriesFetch);
}

export function* productsListFetch({
  payload: { subCategoryURL, parentCategoryURL, perPageCount },
}) {
  try {
    let cityID = yield select(selectCityID);
    let categoriesMap = yield select(selectCategories);
    if (!categoriesMap[parentCategoryURL].id) {
      yield take("CATEGORIES_LIST_FETCH_SUCCESS");
      categoriesMap = yield select(selectCategories);
    }
    const parentCategoryID = categoriesMap[parentCategoryURL].id;
    const subCategoryID =
      categoriesMap[parentCategoryURL].subCategoriesMap[subCategoryURL].id;
    const productsData = yield getProductsList(
      cityID,
      subCategoryID,
      parentCategoryID,
      perPageCount
    );
    const productListMap = fmtProductList(productsData);
    yield put(
      productsListFetchSuccess({
        productsList: productListMap,
        subCategoryURL,
        parentCategoryURL,
      })
    );
  } catch (error) {
    if (error.code === 400 && error.total_products === 0) {
      yield put(productsListFetchNoResult(subCategoryURL, parentCategoryURL));
    } else {
      yield put(
        productsListFetchFailure(error, subCategoryURL, parentCategoryURL)
      );
    }
  }
}

export function* onProductsListFetch() {
  yield takeEvery("PRODUCTS_LIST_FETCH_START", productsListFetch);
}

export function* productsByBrandFetch({ payload: { brandURL } }) {
  try {
    const cityID = yield select(selectCityID);
    let brands = yield select(selectBrands);
    if (!brands[brandURL].id) {
      yield take("HOMEPAGE_FETCH_SUCCESS");
      brands = yield select(selectBrands);
    }
    const brandID = brands[brandURL].id;
    const productsData = yield getProductsListByBrand(cityID, brandID);
    const productsList = fmtProductList(productsData);
    yield put(productsByBrandFetchSuccess(brandURL, productsList));
  } catch (error) {
    console.log(error);
    yield put(productsByBrandFetchFailure(brandURL));
  }
}
export function* onProductsByBrandFetch() {
  yield takeLatest("PRODUCTS_BY_BRAND_FETCH_START", productsByBrandFetch);
}

export function* search({ payload: { searchQuery } }) {
  try {
    const cityID = yield select(selectCityID);
    const {
      data,
      variations,
      subcategory_data: subCategoriesList,
    } = yield getSearchResult(searchQuery, cityID);
    const productsMap = fmtProductList({ data, variations });
    yield put(searchSuccess({ productsMap, subCategoriesList, searchQuery }));
  } catch (error) {
    console.log(error);
  }
}

export function* onSearch() {
  yield takeEvery("SEARCH_START", search);
}

export default function* shopSagas() {
  yield all([
    call(onLocaleListFetch),
    call(onPrimeFetch),
    call(onHomePageFetch),
    call(onCategoriesListFetch),
    call(onSearch),
    call(onProductsListFetch),
    call(onProductsByBrandFetch),
  ]);
}
