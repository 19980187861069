import styled, { css } from "styled-components";
import { ReactComponent as ArrowSVG } from "assets/right-arrow.svg";

export const CollectionPreviewContainer = styled.div`
  margin-bottom: 4rem;
  min-height: 38rem;
  ${(props) =>
    props.highlight &&
    css`
      background-color: #fff;
      background-image: url("/assets/blob-inverted.svg");
      background-size: cover;
      background-repeat: no-repeat;
      padding: 3rem;
      border-radius: 10px;
      ${Head} {
        justify-content: space-between;
      }
      ${Title} {
        color: #fff;
        padding: 0.5rem 2rem;
        background: #222;
        border-radius: 7px;
        font-size: 2rem;
      }
      @media screen and (max-width: 768px) {
        padding: 3rem 1.5rem;
      }
      @media screen and (max-width: 480px) {
        padding: 2rem 0.2rem;
        background-position: 4% 0;
        ${Title} {
          background: none;
          border-radius: none;
          padding: 0.3rem 0.25rem;
          font-size: 1.8rem;
        }
      }
    `}
  @media screen and (max-width: 480px) {
    margin-bottom: 2rem;
  }
`;

export const Head = styled.div`
  display: flex;
  padding: 0 2rem;
  margin-bottom: 3rem;
  align-items: flex-start;
  @media screen and (max-width: 480px) {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
`;

export const Title = styled.h3`
  cursor: pointer;
  text-transform: uppercase;
  font-size: 2.2rem;
  user-select: none;
  color: #444;
  @media screen and (max-width: 480px) {
    font-size: 1.8rem;
    font-weight: 400;
  }
`;

export const Button = styled.button`
	cursor: pointer;
	text-transform: uppercase;
	outine: none;
	font-weight: 700;
	font-size: 1.4rem;
	color: #fff;
	background: linear-gradient(90deg,#ff5e01 0,#ff1313);
	border:none;
	border-radius: 20px;
	padding: .6rem .5rem .6rem 2rem;
	margin-left: 4rem
	display: flex;
	align-items: center;
	flex: 0 0 auto;
	transition: all .15s ease-out;

	span {
		margin-right: 1rem;
	}

	&:hover{
		padding: .6rem .5rem .6rem 4rem;

		span{
			margin-right: 2rem;
		}

	}
  @media screen and (max-width: 768px) {
		margin-left: auto;
  }
	@media screen and (max-width: 480px){
		margin-left: auto;
		padding: .4rem .5rem .4rem 2rem;
	}
`;

// #43A1FE - blue

export const CircleArrowIcon = styled(ArrowSVG)`
  height: 24px;
`;

export const CollectionPreviewError = styled.div`
  height: 34rem;
  display: flex;
  justify-content: center;
`;
