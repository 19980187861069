import styled from "styled-components";

export const CheckoutVoucherContainer = styled.div``;

export const RedeemVoucher = styled.div`
  position: relative;
  border: 1px solid #c7c7c7;
  border-radius: 25px;
  margin: 2rem -1rem;
  input {
    border: none;
    padding: 1.15rem 1.75rem;
    font-size: 1.4rem;
  }
  button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 !important;
  }
`;

export const VoucherResponse = styled.div`
  background-color: #ff594d;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 1rem;
  border-radius: 10px;
  margin: 2rem 0;
  ${(props) => props.success && `background-color: #2ec370`}
`;
