import React, { useState } from "react";
import FormInput from "../../components/form-input/form-input.jsx";
import CustomButton from "../../components/custom-button/custom-button.jsx";
import {
  ContactPageContainer,
  Split,
  Title,
  ContactForm,
  FormMessage,
  ContactItem,
  Type,
  Info,
  PhoneIcon,
  LocationIcon,
  MailIcon,
} from "./contact.styles.jsx";

const ContactPage = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [status, setStatus] = useState(0); // 0 - intial, 1 - sending, 2 - sent, 3 - failed

  const handleChange = (e) => {
    setStatus(0);
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (status === 1 || status === 2) return;
    setStatus(1);
    let formData = new FormData();
    formData = { "form-name": "contact", ...form };
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setStatus(2);
        setForm({ name: "", email: "", subject: "", message: "" });
      })
      .catch((_) => setStatus(3));
  };

  return (
    <ContactPageContainer>
      <Split>
        <ContactForm name="contact">
          <FormInput
            label="Name"
            name="name"
            required
            value={form.name}
            handleChange={handleChange}
          />
          <FormInput
            label="Email"
            name="email"
            type="email"
            required
            value={form.email}
            handleChange={handleChange}
          />
          <FormInput
            label="Subject"
            name="subject"
            required
            value={form.subject}
            handleChange={handleChange}
          />
          <FormMessage
            label="Message"
            name="message"
            required
            value={form.message}
            rows="6"
            onChange={handleChange}
          />
          <CustomButton
            secondary
            orange
            loader={status === 1 ? 1 : 0}
            success={status === 2 ? 1 : 0}
            error={status === 3 ? 1 : 0}
            onClick={handleSubmit}
          >
            {status === 2
              ? "Submitted"
              : status === 3
              ? "Failed, Send Again"
              : "Submit"}
          </CustomButton>
        </ContactForm>
      </Split>
      <Split>
        <Title>Contact Information</Title>
        <ContactItem>
          <LocationIcon />
          <Type>Address</Type>
        </ContactItem>
        <Info>
          1/95 Nanoos Tower,
          <br />
          Opp Pineapple Market
          <br />
          Muvattupuzha, Kerala, 686670
        </Info>
        <ContactItem>
          <PhoneIcon />
          <Type>Phone</Type>
        </ContactItem>
        <Info>
          <a href="tel:7902669900">7902669900</a>
        </Info>
        <ContactItem>
          <MailIcon />
          <Type>Email</Type>
        </ContactItem>
        <Info>
          <a href="mailto:info@goodoor.in"> info@goodoor.in </a>
        </Info>
      </Split>
    </ContactPageContainer>
  );
};

export default ContactPage;
