import { createSelector } from "reselect";

export const selectCart = (state) => state.cart;

export const selectCartVisibility = createSelector(
  [selectCart],
  (cart) => cart.shown
);

export const selectCartItems = createSelector(
  [selectCart],
  (cart) => cart.items
);

export const selectIsCartEmpty = createSelector(
  [selectCartItems],
  (cartItems) => (cartItems.length ? false : true)
);

export const selectCartCount = createSelector(
  [selectCartItems],
  (cartItems) => cartItems.length
);

export const selectIsCartPrimeOnly = createSelector(
  [selectCartItems],
  (cartItems) => !cartItems.some((cartItem) => !cartItem.isPrime)
);

export const selectIsPrimeInCart = createSelector(
  [selectCartItems],
  (cartItems) => cartItems.some((cartItem) => cartItem.isPrime)
);

export const selectCartItemCount = (variationID) =>
  createSelector([selectCartItems], (cartItems) => {
    const itemMatch = cartItems.find(
      (cartItem) => cartItem.variationID === variationID
    );
    return itemMatch?.cartItemCount || 0;
  });

export const selectPrimeSavings = createSelector(
  [selectCartItems],
  (cartItems) =>
    cartItems.reduce(
      (acc, item) => acc + item.sellingPrice - item?.primePrice || 0,
      0
    )
);

export const selectCartTotal = createSelector([selectCartItems], (cartItems) =>
  cartItems.reduce(
    (acc, item) => acc + item.cartItemCount * item.sellingPrice,
    0
  )
);

export const selectCartSavings = createSelector(
  [selectCart],
  (cart) => cart.savings
);

export const selectGrandTotal = createSelector(
  [selectCart],
  (cart) => cart.selectGrandTotal
);
