import React from 'react';
import {Select} from './select.styles.jsx';


const CustomSelect = (props) => (
  <Select {...props}>
    {props.children}
  </Select>
)


export default CustomSelect
