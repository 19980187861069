import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { localeListFetchStart, setActiveLocale } from "redux/shop/shop-actions";
import { selectLocaleList } from "redux/shop/shop-selector";
import useIOSDetect from "hooks/detectDevice.js";
import Select from "components/select/select.jsx";
import CustomButton from "components/custom-button/custom-button.jsx";
import {
  LocationPickerContainer,
  Logo,
  Header,
  Body,
  LocationBox,
  InputGroup,
  Text,
  AppIcon,
  AppIconLink,
  LocationIcon,
} from "./location-picker.styles";

const LocationPicker = ({ fetchLocaleList, localeList, setActiveLocale }) => {
  const [selectedCity, setSelectedCity] = useState();
  const [selectedLocation, setSelectedLocation] = useState();

  useEffect(() => {
    fetchLocaleList();
  }, [fetchLocaleList]);

  useEffect(() => {
    if (localeList.length > 0) {
      setSelectedCity(localeList[0]);
      setSelectedLocation(localeList[0].locations[0]);
    }
  }, [localeList]);

  const handleCitySelect = (e) => {
    const cityID = Number(e.target.value);
    let selectedCity = localeList.find((city) => city.id === cityID);
    setSelectedCity(selectedCity);
    setSelectedLocation(selectedCity.locations[0]);
  };

  const handleLocationSelect = (e) => {
    const locationID = Number(e.target.value);
    let selectedLocation = selectedCity.locations.find(
      (location) => location.id === locationID
    );
    setSelectedLocation(selectedLocation);
  };

  const { isIOS } = useIOSDetect();

  return (
    <LocationPickerContainer>
      <Header>
        <Logo />
        <AppIcon>
          <AppIconLink
            show={isIOS ? 0 : 1}
            href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=in.goodoor.android"
          >
            <img src="/assets/google-play-badge.png" alt="playstore" />
          </AppIconLink>
          <AppIconLink
            show={isIOS ? 1 : 0}
            href="https://apps.apple.com/in/app/goodoor/id1564702191"
          >
            <img src="/assets/apple-logo.png" alt="ios-appstore" />
          </AppIconLink>
        </AppIcon>
        <Body>
          <LocationBox>
            <InputGroup>
              <LocationIcon />
              <Text>Select City</Text>
              <Select name="city-select" onChange={handleCitySelect}>
                {localeList.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Select>
            </InputGroup>
            <InputGroup>
              <LocationIcon />
              <Text>Select Locality</Text>
              <Select name="locality-select" onChange={handleLocationSelect}>
                {selectedCity?.locations.map((location) => (
                  <option key={location.id} value={location.id}>
                    {location.name}
                  </option>
                ))}
              </Select>
            </InputGroup>
          </LocationBox>
          <CustomButton
            secondary
            green
            onClick={() => setActiveLocale(selectedCity, selectedLocation)}
          >
            Open Store
          </CustomButton>
        </Body>
      </Header>
    </LocationPickerContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  localeList: selectLocaleList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchLocaleList: () => dispatch(localeListFetchStart()),
  setActiveLocale: (cityID, locationID) =>
    dispatch(setActiveLocale(cityID, locationID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationPicker);
