import React from "react";
import {
  SpinnerOverlayContainer,
  SpinnerContainer,
} from "./spinner-overlay.style.jsx";

const SpinnerOverlay = ({ height }) => (
  <SpinnerOverlayContainer height={height}>
    <SpinnerContainer />
  </SpinnerOverlayContainer>
);

export default SpinnerOverlay;

