import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  fetchCustomerAddressStart,
  deleteCustomerAddressStart,
  setCustomerAddressField,
  setActiveAddressID,
} from "redux/checkout/checkout-actions.js";
import {
  selectIsFetchingCustomerAddress,
  selectCustomerAddress,
  selectActiveAddressID,
  selectCustomerAddressError,
  selectAddressField,
} from "redux/checkout/checkout-selector.js";
import { selectIsCartPrimeOnly } from "redux/cart/cart-selector";
import FormInput from "components/form-input/form-input.jsx";
import {
  Title,
  InputContainer,
  CustomerAddressContainer,
  CustomerAddressItem,
  CustomerAddressText,
  DeleteBinIcon,
  TextArea,
  AddressSwapButton,
  NotebookIcon,
} from "./checkout-address.styles.jsx";
import SpinnerOverlay from "components/with-spinner/spinner-overlay.jsx";
import RetryBox from "components/retry-box/retry-box.jsx";

const CheckoutAddress = ({
  isFetchingCustomerAddress,
  customerAddress,
  setActiveAddressID,
  activeAddressID,
  customerAddressError,
  getCustomerAddress,
  deleteCustomerAddress,
  setCustomerAddressField,
  addressField,
  isCartPrimeOnly,
}) => {
  const {
    firstName,
    lastName,
    address,
    phoneNumber,
    isNewCustomerAddress,
  } = addressField;

  useEffect(() => {
    getCustomerAddress();
  }, [getCustomerAddress]);
  return (
    <>
      <Title>Delivery Address</Title>
      {isFetchingCustomerAddress ? (
        <SpinnerOverlay height="16rem" />
      ) : customerAddressError ? (
        <CustomerAddressContainer>
          <RetryBox handleErrorRetry={getCustomerAddress} />
        </CustomerAddressContainer>
      ) : customerAddress.length === 0 || isNewCustomerAddress ? (
        <>
          <InputContainer>
            <FormInput
              withBorder
              label="First Name"
              name="firstName"
              value={firstName}
              disabled={isCartPrimeOnly}
              handleChange={(e) =>
                setCustomerAddressField({ firstName: e.target.value })
              }
            />
            <FormInput
              withBorder
              label="Last Name"
              name="lastName"
              value={lastName}
              disabled={isCartPrimeOnly}
              handleChange={(e) =>
                setCustomerAddressField({ lastName: e.target.value })
              }
            />
            <FormInput
              withBorder
              label="Phone No"
              name="phoneNumber"
              value={phoneNumber}
              disabled={isCartPrimeOnly}
              handleChange={(e) =>
                setCustomerAddressField({ phoneNumber: e.target.value })
              }
            />
          </InputContainer>
          <TextArea
            name="Address"
            placeholder="Your Full Address"
            type="textarea"
            rows="6"
            value={address}
            spellcheck="false"
            disabled={isCartPrimeOnly}
            onChange={(e) =>
              setCustomerAddressField({ address: e.target.value })
            }
          />
          {customerAddress.length > 0 && (
            <AddressSwapButton
              disabled={isCartPrimeOnly}
              onClick={() => {
                setCustomerAddressField({ isNewCustomerAddress: false });
              }}
            >
              <NotebookIcon />
              Use Existing Address
            </AddressSwapButton>
          )}
        </>
      ) : (
        <>
          <CustomerAddressContainer>
            {customerAddress.map((customerAddress) => (
              <CustomerAddressItem
                key={customerAddress.id}
                selected={activeAddressID === customerAddress.id}
                disabled={isCartPrimeOnly}
                onClick={() => {
                  setActiveAddressID(customerAddress.id);
                }}
              >
                <CustomerAddressText>
                  {customerAddress.name}
                  <br />
                  {customerAddress.address_1}
                  <br />
                  {customerAddress.address_2}
                  <br />
                  {customerAddress.mobile}
                </CustomerAddressText>
                <DeleteBinIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteCustomerAddress(customerAddress.id);
                  }}
                />
              </CustomerAddressItem>
            ))}
          </CustomerAddressContainer>
          <AddressSwapButton
            disabled={isCartPrimeOnly}
            onClick={() => {
              setCustomerAddressField({ isNewCustomerAddress: true });
            }}
          >
            <NotebookIcon />
            Deliver To Another Address
          </AddressSwapButton>
        </>
      )}
    </>
  );
};

export const mapStateToProps = createStructuredSelector({
  isFetchingCustomerAddress: selectIsFetchingCustomerAddress,
  customerAddress: selectCustomerAddress,
  activeAddressID: selectActiveAddressID,
  customerAddressError: selectCustomerAddressError,
  addressField: selectAddressField,
  isCartPrimeOnly: selectIsCartPrimeOnly,
});

export const mapDispatchToProps = (dispatch) => ({
  getCustomerAddress: () => dispatch(fetchCustomerAddressStart()),
  setActiveAddressID: (activeAddressID) =>
    dispatch(setActiveAddressID(activeAddressID)),
  deleteCustomerAddress: (customerAddressID) =>
    dispatch(deleteCustomerAddressStart(customerAddressID)),
  setCustomerAddressField: (addressField) =>
    dispatch(setCustomerAddressField(addressField)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddress);
