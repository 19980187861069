import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import WithSpinner from "../../components/with-spinner/with-spinner.component.jsx";
import SubCategoriesOverview from "./subcategories-overview.jsx";
import { selectIsFetchingCategoriesList } from "../../redux/shop/shop-selector";

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsFetchingCategoriesList,
});

const SubCategoriesOverviewContainer = connect(mapStateToProps)(
  WithSpinner(SubCategoriesOverview)
);

export default SubCategoriesOverviewContainer;
