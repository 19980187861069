import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { selectParentCategoriesArray } from "redux/shop/shop-selector.js";
import {
  ParentCategoriesMenuContainer,
  MenuItemsContainer,
  MenuItem,
  Image,
  Text,
} from "./parent-categories-menu.styles.jsx";
import ContentLoader from "react-content-loader";

const ParentCategoriesMenu = ({ parentCategories, history, match }) => {
  return (
    <ParentCategoriesMenuContainer>
      <MenuItemsContainer>
        {parentCategories.map(({ name, image, color, slug, id }) => (
          <MenuItem
            key={id}
            onClick={() => history.push(`${match.url}shop/${slug}`)}
            color={color}
          >
            <Image
              src={`${image}`}
              alt={name}
              loader={
                <ContentLoader
                  height={128}
                  width={200}
                  speed={4}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <circle cx="100" cy="64" r="32" />
                </ContentLoader>
              }
            />
            <Text>
              <h3>
                {name.length > 13 ? name.slice(0, 13).concat("..") : name}
              </h3>
            </Text>
          </MenuItem>
        ))}
      </MenuItemsContainer>
    </ParentCategoriesMenuContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  parentCategories: selectParentCategoriesArray,
});

export default connect(mapStateToProps)(withRouter(ParentCategoriesMenu));
