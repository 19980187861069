import styled from "styled-components";

export const Container = styled.div`
  max-width: var(--container-width);
  width: 100%;
  margin: 10rem auto 8rem;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 98%;
    margin: 11rem auto 3rem;
  }

  @media screen and (max-width: 768px) {
    width: 97%;
    min-height: 80vw;
    margin: 14.5rem auto 3rem;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
    min-height: 80vw;
    margin: 14rem auto 3rem;
  }
`;
