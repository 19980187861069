export const localeListFetchStart = () => ({
  type: "LOCALE_LIST_FETCH_START",
});

export const localeListFetchSuccess = (localeMap) => ({
  type: "LOCALE_LIST_FETCH_SUCCESS",
  payload: localeMap,
});

export const localeListFetchError = (error) => ({
  type: "LOCALE_LIST_FETCH_ERROR",
  payload: error,
});

export const setActiveLocale = (activeCity, activeLocation) => ({
  type: "SET_ACTIVE_LOCALE",
  payload: { activeCity, activeLocation },
});

export const clearActiveLocale = () => ({
  type: "CLEAR_ACTIVE_LOCALE",
});

export const fetchPrimeStart = () => ({
  type: "FETCH_PRIME_START",
});

export const fetchPrimeSuccess = (primePlans) => ({
  type: "FETCH_PRIME_SUCCESS",
  payload: primePlans,
});

export const homePageFetchStart = () => ({
  type: "HOMEPAGE_FETCH_START",
});

export const homePageFetchSuccess = (homePage) => ({
  type: "HOMEPAGE_FETCH_SUCCESS",
  payload: homePage,
});

export const homePageFetchFailure = (error) => ({
  type: "HOMEPAGE_FETCH_FAILURE",
  payload: error,
});

export const categoriesListFetchStart = () => ({
  type: "CATEGORIES_LIST_FETCH_START",
});

export const categoriesListFetchSuccess = (categoriesMap) => ({
  type: "CATEGORIES_LIST_FETCH_SUCCESS",
  payload: categoriesMap,
});

export const categoriesListFetchFailure = (error) => ({
  type: "CATEGORIES_LIST_FETCH_FAILURE",
  payload: error,
});

export const searchStart = (searchQuery) => ({
  type: "SEARCH_START",
  payload: { searchQuery },
});

export const searchSuccess = ({
  productsMap,
  subCategoriesList,
  searchQuery,
}) => ({
  type: "SEARCH_SUCCESS",
  payload: { productsMap, subCategoriesList, searchQuery },
});

export const productsListFetchStart = (
  subCategoryURL,
  parentCategoryURL,
  perPageCount
) => ({
  type: "PRODUCTS_LIST_FETCH_START",
  payload: { subCategoryURL, parentCategoryURL, perPageCount },
});

export const productsListFetchSuccess = ({
  productsList,
  subCategoryURL,
  parentCategoryURL,
}) => ({
  type: "PRODUCTS_LIST_FETCH_SUCCESS",
  payload: {
    productsList,
    subCategoryURL,
    parentCategoryURL,
  },
});
export const productsListFetchNoResult = (
  subCategoryURL,
  parentCategoryURL
) => ({
  type: "PRODUCTS_LIST_FETCH_NO_RESULT",
  payload: { subCategoryURL, parentCategoryURL },
});
export const productsListFetchFailure = (
  error,
  subCategoryURL,
  parentCategoryURL
) => ({
  type: "PRODUCTS_LIST_FETCH_FAILURE",
  payload: { error, subCategoryURL, parentCategoryURL },
});

export const productsByBrandFetchStart = (brandURL) => ({
  type: "PRODUCTS_BY_BRAND_FETCH_START",
  payload: { brandURL },
});

export const productsByBrandFetchSuccess = (brandURL, productsList) => ({
  type: "PRODUCTS_BY_BRAND_FETCH_SUCCESS",
  payload: { brandURL, productsList },
});

export const productsByBrandFetchFailure = (brandURL) => ({
  type: "PRODUCTS_BY_BRAND_FETCH_FAILURE",
  payload: { brandURL },
});
