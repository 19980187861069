import useWindowSize from "hooks/useWindowSize.js";

export default function useTruncate(word, smLimit, lgLimit) {
  const { width } = useWindowSize();
  const isMobile = width <= 480;
  let limit;

  isMobile
    ? (limit = smLimit)
    : !isMobile && lgLimit
    ? (limit = lgLimit)
    : (limit = null);

  return word && limit && word.length > limit
    ? word.slice(0, limit).concat("..")
    : word;
}
