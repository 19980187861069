import React from 'react';
import SpinnerOverlay from './spinner-overlay.jsx'

const WithSpinner = (WrapperComponent) => ({isLoading, ...otherProps}) => {
	return isLoading ? (
		<SpinnerOverlay />
	) : (
		<WrapperComponent {...otherProps} />
	)
}

export default WithSpinner;