import { store } from "../redux/store";
let baseURL = `https://78wcv2vwc0.execute-api.ap-south-1.amazonaws.com/dev/api/v1`;
baseURL = `https://cmu523eg0i.execute-api.ap-south-1.amazonaws.com/prod/api/v1`;

const handleResponse = async (response) => {
  if (response.status === 401) {
    console.log("INVALID TOKEN");
    store.dispatch({
      type: "INVALID_ACCESS_TOKEN",
    });
  }
  if (response.ok) return await response.json();
  if (!response.ok) throw await response;
};

export const getCitiesAndLocations = async () =>
  handleResponse(await fetch(`${baseURL}/user/general/cities`));

export const registerUser = async (userName, phoneNo, reffererCode) =>
  handleResponse(
    await fetch(`${baseURL}/user/auth/signup`, {
      method: "POST",
      body: JSON.stringify({
        name: userName,
        mobile: phoneNo,
        refferal_code: reffererCode,
      }),
    })
  );

export const loginUser = async (phoneNo) =>
  handleResponse(
    await fetch(`${baseURL}/user/auth/login`, {
      method: "POST",
      body: JSON.stringify({
        mobile: phoneNo,
      }),
    })
  );

export const verifyOTP = async (phoneNo, OTP) =>
  handleResponse(
    await fetch(`${baseURL}/user/auth/verify-otp`, {
      method: "POST",
      body: JSON.stringify({
        mobile: phoneNo,
        otp: OTP,
      }),
    })
  );

export const getProfile = async (accessToken) =>
  handleResponse(
    await fetch(`${baseURL}/user/auth/profile`, {
      method: "GET",
      headers: {
        access_token: accessToken,
      },
    })
  );

export const getPrime = async (cityID) =>
  handleResponse(
    await fetch(`${baseURL}/user/primeplan/list`, {
      method: "POST",
      body: JSON.stringify({
        city_id: cityID,
      }),
    })
  );

export const getHomePage = async (cityID) =>
  handleResponse(
    await fetch(`${baseURL}/user/home/layout`, {
      method: "POST",
      body: JSON.stringify({
        city_id: cityID,
      }),
    })
  );

export const getCategoriesList = async (cityID) =>
  handleResponse(
    await fetch(`${baseURL}/user/categories/all`, {
      method: "POST",
      body: JSON.stringify({
        city_id: cityID,
      }),
    })
  );

export const getSearchResult = async (searchQuery, cityID) =>
  handleResponse(
    await fetch(`${baseURL}/user/products/web-search`, {
      method: "POST",
      body: JSON.stringify({
        text: searchQuery,
        city_id: cityID,
      }),
    })
  );

export const getProductsList = async (
  cityID,
  subCategoryID,
  parentCategoryID,
  perPageCount = 60
) => {
  perPageCount *= 2; // perPageCount is not exact, often returns lower perPageCount than specified as it dependent on variations and such. pulling more data to get enough items returned;
  return handleResponse(
    await fetch(`${baseURL}/user/products/list`, {
      method: "POST",
      body: JSON.stringify({
        city_id: cityID,
        category_id: parentCategoryID,
        sub_category_id: subCategoryID,
        page_num: 1,
        page_size: perPageCount,
        sort_by: 1,
        in_stock: true,
        brand_ids: [],
        price_filter_id: null,
      }),
    })
  );
};

export const getProductsListByBrand = async (cityID, brandID, pageNo = 1) => {
  return handleResponse(
    await fetch(`${baseURL}/user/products/brand-wise`, {
      method: "POST",
      body: JSON.stringify({
        city_id: cityID,
        page_num: pageNo,
        brand_id: brandID,
      }),
    })
  );
};

export const getUserCart = async (cartID, cityID, accessToken) =>
  handleResponse(
    await fetch(`${baseURL}/user/cart/get`, {
      method: "POST",
      headers: {
        access_token: accessToken,
      },
      body: JSON.stringify({
        city_id: cityID,
        cart_id: cartID,
        payment_method: 1,
      }),
    })
  );

export const addUpdateCart = async (
  variationID,
  cartItemCount,
  cityID,
  cartID = null
) =>
  handleResponse(
    await fetch(`${baseURL}/user/cart/upsert`, {
      method: "POST",
      body: JSON.stringify({
        variation_id: variationID,
        quantity: cartItemCount,
        city_id: cityID,
        cart_id: cartID,
      }),
    })
  );

export const checkoutOrder = async (
  cartID,
  cityID,
  locationID,
  accessToken,
  voucherCode,
  paymentMethod = 1
) =>
  handleResponse(
    await fetch(`${baseURL}/user/order/checkout`, {
      method: "POST",
      headers: {
        access_token: accessToken,
      },
      body: JSON.stringify({
        city_id: cityID,
        cart_id: cartID,
        location_id: locationID,
        payment_method: paymentMethod,
        address_id: null,
        apply_coin: true,
        offer_code: voucherCode,
      }),
    })
  );

export const convertCoin = async (count, accessToken) =>
  handleResponse(
    await fetch(`${baseURL}/user/coin/convert`, {
      method: "POST",
      headers: {
        access_token: accessToken,
      },
      body: JSON.stringify({
        count,
      }),
    })
  );

export const verifyVoucher = async (voucherCode, cityID) =>
  handleResponse(
    await fetch(`${baseURL}/user/offer/apply`, {
      method: "POST",
      body: JSON.stringify({
        city_id: cityID,
        offer_code: voucherCode,
      }),
    })
  );

export const addDeliveryAddress = async (delveryAddress, accessToken) =>
  handleResponse(
    await fetch(`${baseURL}/user/address/add`, {
      method: "POST",
      headers: {
        access_token: accessToken,
      },
      body: JSON.stringify(delveryAddress),
    })
  );

export const updateDeliveryAddress = async (
  addressID,
  delveryAddress,
  accessToken
) =>
  handleResponse(
    await fetch(`${baseURL}/user/address/update`, {
      method: "POST",
      headers: {
        access_token: accessToken,
      },
      body: JSON.stringify({ address_id: addressID, ...delveryAddress }),
    })
  );

export const getDeliveryAddress = async (cityID, locationID, accessToken) =>
  handleResponse(
    await fetch(`${baseURL}/user/address/list`, {
      method: "POST",
      headers: {
        access_token: accessToken,
      },
      body: JSON.stringify({
        city_id: cityID,
        location_id: locationID,
      }),
    })
  );

export const setDefaultCustomerAddress = async (addressID, accessToken) =>
  handleResponse(
    await fetch(`${baseURL}/user/address/default`, {
      method: "POST",
      headers: {
        access_token: accessToken,
      },
      body: JSON.stringify({
        address_id: addressID,
      }),
    })
  );

export const deleteCustomerAddress = async (addressID, accessToken) =>
  handleResponse(
    await fetch(`${baseURL}/user/address/delete`, {
      method: "POST",
      headers: {
        access_token: accessToken,
      },
      body: JSON.stringify({
        address_id: addressID,
      }),
    })
  );

export const getDeliveryTime = async () =>
  handleResponse(await fetch(`${baseURL}/user/general/next-slot`));

export const placeOrder = async (
  cartID,
  locationID,
  addressID,
  deliveryTime,
  paymentMethodID,
  accessToken
) =>
  handleResponse(
    await fetch(`${baseURL}/user/order/create`, {
      method: "POST",
      headers: {
        access_token: accessToken,
      },
      body: JSON.stringify({
        cart_id: cartID,
        location_id: locationID,
        address_id: addressID,
        slot_id: deliveryTime.id,
        slot_date: deliveryTime.dateString,
        payment_method: paymentMethodID,
        platform: "web",
      }),
    })
  );

export const getRazorpayKey = async (cityID, accessToken) =>
  handleResponse(
    await fetch(`${baseURL}/user/order/get-razorpay-key`, {
      method: "POST",
      headers: {
        access_token: accessToken,
      },
      body: JSON.stringify({
        city_id: cityID,
      }),
    })
  );

export const getPaymentStatus = async (orderID, paymentID, accessToken) =>
  handleResponse(
    await fetch(`${baseURL}/user/order/payment-status`, {
      method: "POST",
      headers: {
        access_token: accessToken,
      },
      body: JSON.stringify({
        order_id: orderID,
        payment_id: paymentID,
      }),
    })
  );

export const getUserOrderList = async (accessToken, orderStatus = 0) =>
  handleResponse(
    await fetch(`${baseURL}/user/order/list`, {
      method: "POST",
      headers: {
        access_token: accessToken,
      },
      body: JSON.stringify({
        page_num: 1,
        order_status: orderStatus,
      }),
    })
  );

export const getOrderDetail = async (orderID, accessToken) =>
  handleResponse(
    await fetch(`${baseURL}/user/order/detail`, {
      method: "POST",
      headers: {
        access_token: accessToken,
      },
      body: JSON.stringify({
        order_id: orderID,
      }),
    })
  );

export const cancelOrder = async (orderID, accessToken) =>
  handleResponse(
    await fetch(`${baseURL}/user/order/cancel`, {
      method: "POST",
      headers: {
        access_token: accessToken,
      },
      body: JSON.stringify({
        order_id: orderID,
      }),
    })
  );
