const INITIAL_STATE = {
  isFetchingHomePage: false,
  isFetchingCategoriesList: false,
};

const shopReducer = (state = INITIAL_STATE, action) => {
  const categoriesMap = state.categoriesMap || {};
  const search = state.search || {};
  const homePage = state.homePage || {};

  switch (action.type) {
    case "LOCALE_LIST_FETCH_START":
      return {
        ...state,
        isFetchingLocaleList: true,
      };

    case "LOCALE_LIST_FETCH_SUCCESS":
      return {
        ...state,
        isFetchingLocaleList: false,
        localeMap: action.payload,
      };

    case "SET_ACTIVE_LOCALE":
      const { activeCity, activeLocation } = action.payload;
      return {
        ...state,
        activeLocale: {
          city: activeCity,
          location: activeLocation,
        },
      };

    case "CLEAR_ACTIVE_LOCALE":
      return {
        ...state,
        activeLocale: null,
      };

    case "FETCH_PRIME_START":
      return {
        ...state,
        prime: {
          isFetchingPrime: true,
        },
      };

    case "FETCH_PRIME_SUCCESS":
      return {
        ...state,
        prime: {
          plans: action.payload,
          isFetchingPrime: false,
        },
      };

    case "HOMEPAGE_FETCH_START":
      return {
        ...state,
        isFetchingHomePage: true,
      };

    case "HOMEPAGE_FETCH_SUCCESS":
      return {
        ...state,
        homePage: action.payload,
        isFetchingHomePage: false,
        homePageFetchError: null,
      };

    case "HOMEPAGE_FETCH_FAILURE":
      return {
        ...state,
        isFetchingHomePage: false,
        homePageFetchError: action.payload,
      };

    case "CATEGORIES_LIST_FETCH_START":
      return {
        ...state,
        isFetchingCategoriesList: true,
      };

    case "CATEGORIES_LIST_FETCH_SUCCESS":
      return {
        ...state,
        categoriesMap: action.payload,
        isFetchingCategoriesList: false,
      };

    case "CATEGORIES_LIST_FETCH_FAILURE":
      return {
        ...state,
        isFetchingCategoriesList: false,
        categoriesListFetchError: action.payload,
      };

    case "SEARCH_START": {
      const { searchQuery } = action.payload;
      return {
        ...state,
        search: {
          ...search,
          [searchQuery]: {
            isFetchingSearchResult: true,
          },
        },
      };
    }

    case "SEARCH_SUCCESS": {
      const { productsMap, subCategoriesList, searchQuery } = action.payload;
      return {
        ...state,
        search: {
          ...search,
          [searchQuery]: {
            isFetchingSearchResult: false,
            productsMap,
            subCategoriesList,
          },
        },
      };
    }

    case "PRODUCTS_LIST_FETCH_START": {
      const { subCategoryURL, parentCategoryURL } = action.payload;
      const parentCategory = categoriesMap[parentCategoryURL] || {};
      const subCategoriesMap = parentCategory.subCategoriesMap || {};
      const subCategory = subCategoriesMap[subCategoryURL] || {};

      return {
        ...state,
        categoriesMap: {
          ...categoriesMap,
          [parentCategoryURL]: {
            ...parentCategory,
            subCategoriesMap: {
              ...subCategoriesMap,
              [subCategoryURL]: {
                ...subCategory,
                isFetchingProductsList: true,
              },
            },
          },
        },
      };
    }
    case "PRODUCTS_LIST_FETCH_SUCCESS": {
      const {
        productsList,
        subCategoryURL,
        parentCategoryURL,
      } = action.payload;
      const parentCategory = categoriesMap[parentCategoryURL] || {};
      const subCategoriesMap = parentCategory.subCategoriesMap || {};
      const subCategory = subCategoriesMap[subCategoryURL] || {};

      return {
        ...state,
        categoriesMap: {
          ...categoriesMap,
          [parentCategoryURL]: {
            ...parentCategory,
            subCategoriesMap: {
              ...subCategoriesMap,
              [subCategoryURL]: {
                ...subCategory,
                isFetchingProductsList: false,
                productsListFetchError: null,
                productsList: productsList,
                productsListFetchNoResult: false,
              },
            },
          },
        },
      };
    }
    case "PRODUCTS_LIST_FETCH_NO_RESULT": {
      const { subCategoryURL, parentCategoryURL } = action.payload;
      const parentCategory = categoriesMap[parentCategoryURL] || {};
      const subCategoriesMap = parentCategory.subCategoriesMap || {};
      const subCategory = subCategoriesMap[subCategoryURL] || {};

      return {
        ...state,
        categoriesMap: {
          ...categoriesMap,
          [parentCategoryURL]: {
            ...parentCategory,
            subCategoriesMap: {
              ...subCategoriesMap,
              [subCategoryURL]: {
                ...subCategory,
                isFetchingProductsList: false,
                productsListFetchNoResult: true,
              },
            },
          },
        },
      };
    }
    case "PRODUCTS_LIST_FETCH_FAILURE": {
      const { error, subCategoryURL, parentCategoryURL } = action.payload;
      const parentCategory = categoriesMap[parentCategoryURL] || {};
      const subCategoriesMap = parentCategory.subCategoriesMap || {};
      const subCategory = subCategoriesMap[subCategoryURL] || {};

      return {
        ...state,
        categoriesMap: {
          ...categoriesMap,
          [parentCategoryURL]: {
            ...parentCategory,
            subCategoriesMap: {
              ...subCategoriesMap,
              [subCategoryURL]: {
                ...subCategory,
                isFetchingProductsList: false,
                productsListFetchError: error,
                productsListFetchNoResult: false,
              },
            },
          },
        },
      };
    }
    case "PRODUCTS_BY_BRAND_FETCH_START": {
      const { brandURL } = action.payload;
      const featuredBrands = homePage?.featuredBrands || {};
      return {
        ...state,
        homePage: {
          ...homePage,
          featuredBrands: {
            ...featuredBrands,
            [brandURL]: {
              ...featuredBrands[brandURL],
              isFetchingProductsByBrand: true,
            },
          },
        },
      };
    }

    case "PRODUCTS_BY_BRAND_FETCH_FAILURE": {
      const { brandURL } = action.payload;
      const featuredBrands = homePage?.featuredBrands || {};
      return {
        ...state,
        homePage: {
          ...homePage,
          featuredBrands: {
            ...featuredBrands,
            [brandURL]: {
              ...featuredBrands[brandURL],
              isFetchingProductsByBrand: false,
              productsByBrandFetchError: true,
              productsList: [],
            },
          },
        },
      };
    }
    case "PRODUCTS_BY_BRAND_FETCH_SUCCESS": {
      const { brandURL, productsList } = action.payload;
      const featuredBrands = homePage?.featuredBrands || {};
      return {
        ...state,
        homePage: {
          ...homePage,
          featuredBrands: {
            ...featuredBrands,
            [brandURL]: {
              ...featuredBrands[brandURL],
              isFetchingProductsByBrand: false,
              productsByBrandFetchError: null,
              productsList,
            },
          },
        },
      };
    }
    default:
      return state;
  }
};

export default shopReducer;
