import styled from "styled-components";
import { ReactComponent as LogoText } from "assets/logo-text.svg";
import { ReactComponent as CheckSVG } from "assets/check.svg";

export const PrimeContainer = styled.div`
  background: #fff;
  padding: 4rem 4rem 6rem;
  border-radius: 25px;
  box-shadow: 0 0.5rem 1.5rem rgb(0 0 0 / 5%);
  max-width: 1280px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    padding: 4rem 1rem 6rem;
  }
  @media screen and (max-width: 480px) {
    padding: 2rem 1rem;
    border-radius: 10px;
    width: 95%;
  }
`;
export const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Logo = styled(LogoText)`
  width: 20rem;
  @media screen and (max-width: 480px) {
    width: 15rem;
  }
`;
export const CheckIcon = styled(CheckSVG)`
  height: 16px;
  width: 16px;
  fill: green;
`;
export const PrimeLogo = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 3rem;
  border-radius: 25px;
  color: green;
  background: #cbffd6;
  span {
    margin-left: 0.5rem;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 1px;
  }
`;
export const Title = styled.h3`
  font-size: 2.7rem;
  text-align: center;
  margin: 4rem 0;
  @media screen and (max-width: 480px) {
    margin: 2rem 0;
  }
`;
export const Body = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;
