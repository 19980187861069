import styled from "styled-components";

export const CartContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  user-select: none;
  width: 36rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 15px 10px;
  border: 1px solid #e7e7e7;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: white;
  button {
    align-self: center;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 768px) {
    position: relative;
    width: 100%;
    margin: 1rem auto 0;
    order: 1;
  }
`;
export const CartItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const CartItems = styled.div`
  margin-bottom: 1rem;
`;
export const CartStatus = styled.p`
  font-size: 2rem;
  margin: auto;
`;

export const CartListItemBorder = styled.hr`
  color: #e7e7e7;
  width: 90%;
  margin: 0 auto;
  border: none;
  border-bottom: 1px solid;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const CartInfo = styled.div`
  padding: 0rem 2rem 1.5rem;
`;

export const CartInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #777;
  margin-bottom: 0.6rem;
  &:last-child {
    margin-bottom: 0;
  }
`;
