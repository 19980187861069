import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgb(0 0 0 / 7%) 0px 5px 25px 2px;
  padding: 3rem;
  margin: 0 2rem;
  border-radius: 15px;

  @media screen and (max-width: 768px) {
    margin: 0 1rem;
  }

  h2 {
    font-size: 5rem;
    color: #5e666b;
    color: #4b4b4c;
    span {
      font-size: 3rem;
    }
  }
  h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  h4 {
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    padding: 1rem 0;
    border-bottom: 1px solid #e7e7e7;
    text-align: center;
    color: #333;
  }

  button {
    margin-top: 3rem;
  }

  &:nth-child(2) {
    background: linear-gradient(180deg, #ff5e01 0, #f33);
    @media screen and (max-width: 480px) {
      order: -1;
    }
    h2,
    h3,
    h4 {
      color: #fff;
      border: none;
    }
    button {
      background: #fff;
      color: #222;
      ${(props) =>
        props?.children[3]?.props.disabled && `text-decoration: line-through`}
    }
  }

  @media screen and (max-width: 480px) {
    margin: 2rem 1rem;
  }
`;
