import { updateCartCount } from "./cart-utils.js";

const INITIAL_STATE = {
  items: [],
  shown: false,
  cartID: null,
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TOGGLE_CART_VIEW":
      return {
        ...state,
        shown: !state.shown,
      };
    case "FETCH_USER_CART_SUCCESS":
      return {
        ...state,
        ...action.payload,
      };
    case "ADD_TO_CART_SUCCESS":
      return {
        ...state,
        items: action.payload,
      };
    case "ADD_CART_ID_SUCCESS": {
      return {
        ...state,
        cartID: action.payload.cartID,
      };
    }
    case "UPDATE_CART_ITEM_COUNT":
      return updateCartCount(state, action.payload);

    case "CLEAR_CART":
      return {
        shown: false,
        items: [],
      };
    default:
      return state;
  }
};

export default cartReducer;
