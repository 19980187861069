import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  LogoLink,
  Logo,
  LogoText,
  Nav,
  MenuToggle,
  Menu,
  MenuItemLink,
} from "./navbar.styles.jsx";
import ActiveLocale from "components/active-locale/active-locale.jsx";
import SearchBar from "components/search-bar/search-bar.jsx";
import CartIcon from "components/cart-icon/cart-icon.jsx";
import CartDropdown from "components/cart-dropdown/cart-dropdown.jsx";
import { selectCartVisibility } from "redux/cart/cart-selector.js";

const Navbar = ({ showCartDropdown }) => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
  };

  return (
    <Nav open={isHamburgerMenuOpen}>
      <LogoLink to="/">
        <Logo />
        <LogoText />
      </LogoLink>
      <ActiveLocale />
      <SearchBar />
      <CartIcon />
      <MenuToggle onClick={handleMenuToggle} open={isHamburgerMenuOpen}>
        <div></div>
        <div></div>
        <div></div>
      </MenuToggle>
      <Menu show={isHamburgerMenuOpen}>
        <MenuItemLink onClick={handleMenuToggle} to="/prime">
          Prime
        </MenuItemLink>
        <MenuItemLink onClick={handleMenuToggle} to="/orders">
          Orders
        </MenuItemLink>
        <MenuItemLink onClick={handleMenuToggle} to="/account">
          Account
        </MenuItemLink>
      </Menu>
      {showCartDropdown && <CartDropdown />}
    </Nav>
  );
};

const mapStateToProps = createStructuredSelector({
  showCartDropdown: selectCartVisibility,
});

export default connect(mapStateToProps)(Navbar);
