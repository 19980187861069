import styled from "styled-components";
import Img from "react-image";

export const MenuContainer = styled.div`
  padding: 1rem 0;
  //  margin: 4rem calc(-50vw + var(--container-width) / 2) 4rem; // Extend out of parent container
  @media screen and (max-width: 768px) {
    padding: 0;
    background: none;
    margin: 0 0.75rem;
  }
`;

export const MenuItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1550px;
  margin: auto;
  @media screen and (max-width: 768px) {
    overflow-x: scroll;
    justify-content: space-between;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  margin: 0 1rem;
  border-radius: 2rem;
  box-shadow: rgb(0 0 0 / 7%) 0px 5px 25px 2px;
  background: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 10%;
  padding: 1.25rem;
  cursor: pointer;
  border-radius: 6px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0 0 1rem 0;
    &:not(:last-child) {
      margin-right: 0.75rem;
    }
    background: none;
    box-sizing: border-box;
    padding: 0.5rem;
    flex: 0 0 calc(20% - 2.25rem);
    box-shadow: none;
  }
  @media screen and (max-width: 480px) {
    flex: 0 0 calc(25% - 2.25rem);
  }
`;

export const Image = styled(Img)`
  height: 70px;
  display: block;
  object-fit: contain;

  @media screen and (max-width: 768px) {
    height: 56px;
    width: 56px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin: 0;
  }
`;

export const Text = styled.div`
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  letter-spacing: 0.5px;
  margin: 0 0 0 1rem;

  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
    margin: 0.3rem 0 0 0;
    h3 {
      font-weight: 400;
    }
  }
`;
