import {
  all,
  call,
  put,
  select,
  take,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import {
  fetchUserCartSuccess,
  addItemToCartSuccess,
  addCartIDSuccess,
  updateCartItemCount,
  clearCart,
} from "./cart-actions.js";
import { fmtCartResponse } from "./cart-utils";
import { getUserCart, addUpdateCart } from "backend/api.js";

const selectCartItems = (state) => state.cart.items;
const selectCartID = (state) => state.cart.cartID;
const selectCityID = (state) => state.shop.activeLocale.city.id;

export function* fetchUserCart() {
  const cartID = yield select(selectCartID);
  const cityID = yield select(selectCityID);
  const userCart = yield getUserCart(cartID, cityID);
  yield put(fetchUserCartSuccess(fmtCartResponse(userCart)));
}

export function* onLoginSuccess() {
  //  yield takeLatest("LOGIN_SUCCESS", fetchUserCart);
}

export function* clearCartOnReset() {
  yield put(clearCart());
}

export function* onClearUserOrStore() {
  yield takeLatest(
    ["SIGN_OUT_SUCCESS", "CLEAR_ACTIVE_LOCALE"],
    clearCartOnReset
  );
}

export function* addToCart({ payload: cartItemToAdd }) {
  const cityID = yield select(selectCityID);
  const cartID = yield select(selectCartID);
  const cartItems = yield select(selectCartItems);
  const { variationID, cartItemCount } = cartItemToAdd;

  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.variationID === variationID
  );

  if (!existingCartItem) {
    yield put(
      addItemToCartSuccess([
        ...cartItems,
        { ...cartItemToAdd, cartItemCount: 1 },
      ])
    );
    if (cartID) {
      yield addUpdateCart(variationID, cartItemCount, cityID, cartID);
    } else {
      const {
        data: { cart_id: cartID },
      } = yield addUpdateCart(variationID, cartItemCount, cityID);
      yield put(addCartIDSuccess(cartID));
    }
  } else if (existingCartItem) {
    let quantity = existingCartItem.cartItemCount + 1;
    if (cartID) {
      yield put(
        updateCartItemCount({ ...existingCartItem, cartItemCount: quantity })
      );
      yield addUpdateCart(variationID, quantity, cityID, cartID);
    } else {
      yield put(
        updateCartItemCount({ ...existingCartItem, cartItemCount: quantity })
      );
      // Listen for CartID Success Call and check if payload matches, If so use the data from the payload to fire update.
      while (true) {
        const {
          payload: { cartID },
        } = yield take("ADD_CART_ID_SUCCESS");
        yield addUpdateCart(variationID, quantity, cartID, cityID);
      }
    }
  }
}

export function* onAddToCart() {
  yield takeEvery("ADD_TO_CART", addToCart);
}

export function* reduceFromCart({ payload: { variationID, quantity } }) {
  const cartItems = yield select(selectCartItems);
  const cartID = yield select(selectCartID);
  const cityID = yield select(selectCityID);

  for (let cartItem of cartItems) {
    if (cartItem.variationID === variationID) {
      quantity = quantity ?? cartItem.cartItemCount - 1;

      if (cartID) {
        yield put(
          updateCartItemCount({ ...cartItem, cartItemCount: quantity })
        ); // updateCartItemCount Removes Item From Redux State if quantity === 0
        yield addUpdateCart(variationID, quantity, cityID, cartID);
      } else {
        yield put(
          updateCartItemCount({ ...cartItem, cartItemCount: quantity })
        ); // updateCartItemCount Removes Item From Redux State if quantity === 0

        while (true) {
          const {
            payload: { cartID },
          } = yield take("ADD_CART_ID_SUCCESS");
          yield addUpdateCart(variationID, quantity, cityID, cartID);
        }
      }
    }
  }
}

export function* onReduceFromCart() {
  yield takeEvery("REDUCE_FROM_CART_START", reduceFromCart);
}

export default function* cartSagas() {
  yield all([
    call(onAddToCart),
    call(onReduceFromCart),
    call(onClearUserOrStore),
    call(onLoginSuccess),
  ]);
}
