import styled, { css } from "styled-components";
import { ReactComponent as PlaneSVG } from "assets/paper-plane.svg";
import { ReactComponent as PinSVG } from "assets/pin.svg";
import { ReactComponent as PhoneSVG } from "assets/telephone.svg";

export const ContactPageContainer = styled.div`
  display: flex;
  max-width: 920px;
  margin: 3rem auto 0;
  @media screen and (max-width: 480px) {
    flex-direction: column-reverse;
    margin: 0 auto;
  }
`;

export const Split = styled.div`
  flex: 0 0 50%;
  padding: 3rem 6rem;
  box-shadow: rgb(0 0 0 / 7%) 0px 5px 25px 2px;
  &:first-child {
    background: #fff;
    border-radius: 25px 0 0 25px;
  }
  &:last-child {
    border-radius: 0 25px 25px 0;
    background: linear-gradient(180deg, #ff7f35 0, #f33);
  }
  @media screen and (max-width: 480px) {
    padding: 2rem;
    &:first-child {
      margin-top: -1rem;
      padding-top: 3rem;
      border-radius: 0 0 15px 15px;
    }
    &:last-child {
      border-radius: 10px;
      padding: 3rem 4rem;
      background: linear-gradient(180deg, #ff5e01 0, #f33);
    }
  }
`;

export const Title = styled.h2`
  font-size: 3rem;
  margin-bottom: 3rem;
  color: #fff;
  @media screen and (max-width: 480px) {
    font-size: 2.6rem;
  }
`;

export const ContactForm = styled.form`
  & > * {
    margin-bottom: 2rem;
  }
`;

export const FormMessage = styled.textarea`
  background: none;
  border: 1px solid #d7d7d7;
  width: 100%;
  font-size: 2rem;
  padding: 1rem;
`;

export const ContactItem = styled.div`
  display: flex;
  margin: 1.5rem 0;
`;

export const IconCSS = css`
  fill: #fff;
  height: 18px;
  width: 18px;
  margin-right: 1rem;
`;
export const Type = styled.h3`
  font-size: 1.9rem;
  color: #fff;
`;

export const Info = styled.p`
  font-size: 2rem;
  color: #fff;
  @media screen and (max-width: 480px) {
    font-size: 1.9rem;
  }
`;

export const MailIcon = styled(PlaneSVG)`
  ${IconCSS};
`;

export const LocationIcon = styled(PinSVG)`
  ${IconCSS};
`;
export const PhoneIcon = styled(PhoneSVG)`
  ${IconCSS}
`;
