import React from "react";
import { Route, Redirect } from "react-router-dom";
import PrivateRoute from "components/private-route/privateRoute.jsx";
import Auth from "pages/auth/auth.jsx";
import SearchPage from "pages/search/search.jsx";
import HomePage from "pages/homepage/homepage.jsx";
import Prime from "pages/prime/prime.jsx";
import SubCategoriesOverviewContainer from "pages/subcategories-overview/subcategories-overview.container.jsx";
import SubCategory from "pages/subcategory/sub-category.jsx";
import HomePageProducts from "pages/homepage-products/homepage-products.jsx";
import ProductsByBrand from "pages/brands/brands.jsx";
import CheckoutContainer from "pages/checkout/checkout.container.jsx";
import OrderStatusContainer from "pages/orderstatus/orderstatus.container.jsx";
import Orders from "pages/orders/orders.jsx";
import OrderDetail from "pages/order-detail/order-detail.jsx";
import ConditionsPage from "pages/static/conditions.jsx";
import PrivacyPage from "pages/static/privacy.jsx";
import AboutPage from "pages/static/about.jsx";
import ContactPage from "pages/static/contact.jsx";
import AccountPage from "pages/account/account.jsx";

const Routes = ({ activeLocale }) => (
  <>
    <Route exact path="/conditions" render={() => <ConditionsPage />} />
    <Route exact path="/privacy" render={() => <PrivacyPage />} />
    <Route exact path="/about" render={() => <AboutPage />} />
    <Route exact path="/contact" render={() => <ContactPage />} />
    {activeLocale && (
      <>
        <Route exact path="/" render={() => <HomePage />} />
        <Route
          exact
          path="/search/:searchQuery"
          render={() => <SearchPage />}
        />
        <Route exact path="/shop" render={() => <Redirect to="/" />} />
        <Route
          exact
          path="/shop/:parentCategoryURL"
          render={() => <SubCategoriesOverviewContainer />}
        />
        <Route
          exact
          path={`/shop/:parentCategoryURL/:subCategoryURL`}
          render={() => <SubCategory />}
        />
        <Route exact path="/prime" render={() => <Prime />} />
        <Route exact path="/home/:url" render={() => <HomePageProducts />} />
        <Route exact path="/brands/:url" render={() => <ProductsByBrand />} />
        <Route exact path="/auth" render={() => <Auth />} />
        <PrivateRoute exact path="/checkout">
          <CheckoutContainer />
        </PrivateRoute>
        <PrivateRoute exact path="/orderstatus">
          <OrderStatusContainer />
        </PrivateRoute>
        <PrivateRoute exact path="/orders">
          <Orders />
        </PrivateRoute>
        <PrivateRoute exact path={`/orders/:orderID`}>
          <OrderDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/account">
          <AccountPage />
        </PrivateRoute>
      </>
    )}
  </>
);

export default Routes;
