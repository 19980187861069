export const setReffererCode = (reffererCode) => ({
  type: "SET_REFFERER_CODE",
  payload: reffererCode,
});

export const signUpStart = (userName, phoneNo) => ({
  type: "SIGNUP_START",
  payload: { userName, phoneNo },
});

export const signInStart = (phoneNo) => ({
  type: "SIGNIN_START",
  payload: { phoneNo },
});

export const loginFailure = (error) => ({
  type: "LOGIN_FAILURE",
  payload: error,
});

export const verifyOTPStart = (phoneNo, OTP) => ({
  type: "VERIFY_OTP",
  payload: { phoneNo, OTP },
});

export const verifyOTPFailure = (phoneNo) => ({
  type: "VERIFY_OTP_FAILURE",
  payload: { phoneNo },
});

export const loginSuccess = (userDetail) => ({
  type: "LOGIN_SUCCESS",
  payload: userDetail,
});

export const fetchUserProfileStart = () => ({
  type: "FETCH_USER_PROFILE_START",
});

export const fetchUserProfileSuccess = (userDetail) => ({
  type: "FETCH_USER_PROFILE_SUCCESS",
  payload: userDetail,
});

export const clearAuthError = () => ({
  type: "CLEAR_AUTH_ERROR",
});

export const signOut = () => ({
  type: "SIGN_OUT",
});

export const setUserPrime = () => ({
  type: "SET_USER_PRIME",
});

export const redeemCoinStart = (count) => ({
  type: "REDEEM_COIN_START",
  payload: { count },
});

export const redeemCoinSuccess = (coins) => ({
  type: "REDEEM_COIN_SUCCESS",
  payload: coins,
});

export const redeemCoinFailure = (error) => ({
  type: "REEDEEM_COIN_FAILURE",
  payload: error,
});
