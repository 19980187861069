import styled from "styled-components";

export const CollectionsOverviewContainer = styled.div``;

export const CollectionsErrorContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

