import styled from "styled-components";
import { ReactComponent as CashSVG } from "assets/money.svg";
import { ReactComponent as DebitCardSVG } from "assets/debit-card.svg";

export const CashIcon = styled(CashSVG)`
  height: 22px;
  width: 22px;
`;

export const DebitCardIcon = styled(DebitCardSVG)`
  height: 22px;
  width: 22px;
`;
