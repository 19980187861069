import { all, call, take, takeLatest, put, select } from "redux-saga/effects";
import {
  registerUser,
  loginUser,
  verifyOTP,
  getProfile,
  convertCoin,
} from "backend/api.js";
import {
  loginSuccess,
  loginFailure,
  verifyOTPFailure,
  fetchUserProfileSuccess,
  redeemCoinSuccess,
  redeemCoinFailure,
} from "./user-actions.js";

const selectAccessToken = (state) => state.user.access_token;
const selectReffererCode = (state) => state.user.reffererCode;

export function* signUp({ payload: { userName, phoneNo, cityID } }) {
  try {
    const reffererCode = yield select(selectReffererCode);
    yield registerUser(userName, phoneNo, reffererCode);
  } catch (error) {
    yield put(loginFailure(error));
  }
}

export function* onSignUpStart() {
  yield takeLatest("SIGNUP_START", signUp);
}

export function* signIn({ payload: { phoneNo } }) {
  try {
    yield loginUser(phoneNo);
  } catch (error) {
    yield put(loginFailure(error));
  }
}

export function* onSignInStart() {
  yield takeLatest("SIGNIN_START", signIn);
}
export function* verifyOTPSaga({ payload: { phoneNo, OTP } }) {
  try {
    const { data: userDetail } = yield verifyOTP(phoneNo, OTP);
    yield put(loginSuccess(userDetail));
  } catch (error) {
    yield put(verifyOTPFailure(phoneNo));
  }
}

export function* onVerifyOTPStart() {
  yield takeLatest("VERIFY_OTP", verifyOTPSaga);
}

export function* fetchUserProfile() {
  try {
    let accessToken = yield select(selectAccessToken);
    if (!accessToken) yield take("LOGIN_SUCCESS");
    accessToken = yield select(selectAccessToken);
    const { data: userDetail } = yield getProfile(accessToken);
    yield put(fetchUserProfileSuccess(userDetail));
  } catch (e) {
    console.log(e);
  }
}

export function* onFetchUserProfile() {
  yield takeLatest("FETCH_USER_PROFILE_START", fetchUserProfile);
}

export function* redeemCoin({ payload: { count } }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const { data: coins } = yield convertCoin(count, accessToken);
    yield put(redeemCoinSuccess(coins));
  } catch (e) {
    yield put(redeemCoinFailure(e));
  }
}

export function* onRedeemCoin() {
  yield takeLatest("REDEEM_COIN_START", redeemCoin);
}

export default function* userSagas() {
  yield all([
    call(onSignUpStart),
    call(onSignInStart),
    call(onVerifyOTPStart),
    call(onFetchUserProfile),
    call(onRedeemCoin),
  ]);
}
