import styled, { css, keyframes } from "styled-components";
import Img from "react-image";
import { Link } from "react-router-dom";

const pulseAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
`;
export const ProductPreviewCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
  user-select: none;
  position: relative;
  @media screen and (max-width: 480px) {
    min-height: 30rem;
  }
  @media screen and (min-width: 1024px) {
    min-height: 37rem;
  }
  ${(props) =>
    props.highlight &&
    css`
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.2) 3px 5px 25px;
    `}
`;

export const DiscountBox = styled.div`
  font-family: "Montserrat", sans-serif;
  position: absolute;
  background: linear-gradient(90deg, #ff5e01 0, #ff1313);
  color: #fff;
  font-size: 2.1rem;
  font-weight: 700;
  top: -0.15rem;
  left: 0;
  padding: 1rem 1.5rem 1.25rem 1rem;
  border-radius: 0 0 45px 0;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);
  text-align: center;
  z-index: 1;
  span {
    margin-left: 1rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 2rem;
    letter-spacing: 1px;
  }
  ${(props) =>
    props.unavailable &&
    `
		filter: grayscale(100%);
		opacity: .7;
	`}
`;

export const Image = styled(Img)`
  display: block;
  padding: 0.5rem;
  object-fit: contain;
  width: 100%;
  height: 16rem;
  overflow: hidden;
  ${(props) =>
    props.unavailable &&
    `
		filter: grayscale(100%);
	`}

  @media screen and (max-width: 480px) {
    height: 13rem;
  }
`;

export const Vendor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #ff5e01 0, #ff1313);
  color: #fff;
  padding: 0.6rem 2rem;
  font-size: 1.7rem;
  img {
    height: 24px;
    width: 24px;
    margin-right: 1.5rem;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 0;
`;

export const Title = styled.h3`
  font-size: 1.65rem;
  text-align: center;
  margin: 1rem 0.5rem 0;
  color: #333;
  letter-spacing: 0.4px;
  ${(props) =>
    props.skeleton &&
    css`
      width: 70%;
      margin-top: 1rem;
      border-radius: 25px;
      height: 1.5rem;
      background: linear-gradient(
        -90deg,
        #efefef 0%,
        #fcfcfc 50%,
        #efefef 100%
      );
      background-size: 400% 400%;
      animation: ${pulseAnimation} 1.2s ease-in-out infinite;
    `}
  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.1rem 0;
  ${(props) =>
    props.skeleton &&
    css`
      width: 55%;
      border-radius: 10px;
      height: 1.15rem;
      background: linear-gradient(
        -90deg,
        #efefef 0%,
        #fcfcfc 50%,
        #efefef 100%
      );
      background-size: 400% 400%;
      animation: ${pulseAnimation} 1.2s ease-in-out infinite;
      margin: 0;
    `}
  @media screen and (max-width: 480px) {
    margin: 0.5rem 0;
  }
`;

export const SalePrice = styled.h3`
  font-size: 2.1rem;
  font-weight: 400;
  margin: 0;
`;

export const Price = styled.h4`
  font-family: "Montserrat", sans-serif;
  font-size: 1.9rem;
  color: #f88379;
  margin-left: 0.7rem;

  & span {
    text-decoration: line-through;
  }
`;

export const PrimePrice = styled.h3`
  font-size: 1.75rem;
  color: #2ec370;
  margin-bottom: 1.5rem;
  font-weight: 400;
  @media screen and (max-width: 480px) {
    margin-bottom: 0.75rem;
  }
`;

export const PrimeLink = styled(Link)`
  margin-left: 0.5rem;
  color: #555;
`;
export const SilverCoin = styled.div`
  position: relative;
  img {
    height: 40px;
    width: 40px;
  }
  span {
    font-family: "Montserrat", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    color: #fff;
    background: #2ec370;
    position: absolute;
    right: 0.2rem;
    top: -0.2rem;
  }
`;
