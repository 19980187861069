import styled, { css } from "styled-components";

const ShrinkLabel = css`
  opacity: 0;
  color: grey;
`;

export const InputGroup = styled.div`
  position: relative;
`;
export const InputLabel = styled.label`
  color: #555;
  font-size: 1.9rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 5px;

  ${(props) => props.white && `color: #fff;`}
  ${(props) => props.withBorder && `left: 15px;`}
  ${(props) => !props.error && props?.value?.length && ShrinkLabel}
	
	${(props) =>
    props.error &&
    css`
      color: #f44336;
      left: unset;
      right: 15px;
    `}
	${(props) =>
    props.disabled &&
    css`
      color: #ccc !important;
      text-decoration: line-through;
    `}
`;

export const InputField = styled.input`
  color: #555;
  background: none;
  background-color: white;
  font-size: 18px;
  padding: 1rem 1rem 1rem 0.5rem;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #d7d7d7;

  ${(props) =>
    props.withBorder &&
    css`
      border: 1px solid #d7d7d7;
      border-radius: 5px;
      padding: 1rem 1.5rem;
    `}
  ${(props) =>
    props.white &&
    css`
      background: none;
      color: #fff;
      border-bottom: 1px solid #fff;
    `}
  ${(props) =>
    props.error &&
    css`
      border: 1px solid #f44336;
      border-radius: 5px;
      &::after {
        content: props?.errorMessage;
        display: block;
        color: #f44336;
      }
    `}
	
	${(props) =>
    props.disabled &&
    css`
      border: 1px solid #e7e7e7;
    `}

  &:focus {
    outline: none;
  }

  &[type="password"] {
    letter-spacing: 0.3em;
  }
`;
