import React from "react";
import { ProductsOverviewConatiner } from "./products-overview.styles.jsx";
import ProductPreviewCard from "components/product-preview-card/product-preview-card.jsx";
import SkeletonProductPreviewCard from "components/skeleton-product-preview-card/skeleton-product-preview-card.jsx";
import getProductsOverviewCount from "hooks/getProductsOverviewCount.js";
import { v4 as uuidv4 } from "uuid";

const ProductsOverview = ({ products, highlight, fullpage, isFetching }) => {
  const ProductsOverviewCount = getProductsOverviewCount();
  return (
    <ProductsOverviewConatiner highlight={highlight}>
      {products.map((item) => (
        <ProductPreviewCard {...item} key={uuidv4()} highlight={highlight} />
      ))}
      {(isFetching || products.length === 0) &&
        Array(fullpage ? ProductsOverviewCount * 2 : ProductsOverviewCount)
          .fill()
          .map((_) => <SkeletonProductPreviewCard key={uuidv4()} />)}
    </ProductsOverviewConatiner>
  );
};

export default ProductsOverview;
