import styled, { css } from "styled-components";
import { ReactComponent as MapSVG } from "assets/map-location.svg";
import { ReactComponent as NotebookSVG } from "assets/notebook.svg";
import { ReactComponent as BinSVG } from "assets/bin.svg";

export const Title = styled.div`
  font-size: 1.7rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 1.5rem 0;
  color: #777;
  letter-spacing: 1px;
`;

export const CustomerAddressContainer = styled.div`
  display: flex;
  overflow: auto;
  padding-bottom: 1rem;
  min-height: 15rem;
  justify-content: flex-start;
`;

export const CustomerAddressItem = styled.div`
  cursor: pointer;
  flex: 1 0 32.5%;
  border: 1px solid #d7d7d7;
  padding: 1.25rem 1rem;
  border-radius: 7px;
  color: #999;
  letter-spacing: 0.5px;
  margin-right: 1rem;
  position: relative;

  ${(props) =>
    props.selected &&
    css`
      border: 2px solid #2ec370;
      color: #666;
    `}

  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid #d7d7d7;
      color: #ccc;
      cursor: not-allowed;
      ${DeleteBinIcon} {
        display: none;
      }
    `}

  @media screen and (max-width:480px) {
    flex: 1 0 50%;
    margin-bottom: 0.6rem;
  }
`;
export const CustomerAddressText = styled.p`
  font-size: 1.6rem;
  line-height: 1.5;
`;

export const DeleteBinIcon = styled(BinSVG)`
  height: 44px;
  width: 44px;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  padding: 1rem;
`;
export const TextArea = styled.textarea`
  display: block;
  width: 100%;
  font-size: 1.9rem;
  font-family: inherit;
  font-weight: normal;
  padding: 2rem 1rem;
  margin: 2rem 0;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid #e7e7e7;
      background: #fff;
      &::placeholder {
        text-decoration: line-through;
      }
    `}
`;
export const InputContainer = styled.div`
  display: flex;
  width:100%;
  margin-botton: 1rem;
  & > * {
    margin-right: 2rem;
    flex: 1;
    &:last-child{
      margin-right:0;
    }
  }

  @media screen and (max-width:480px){
    flex-wrap: wrap;
    & > * {
      margin-right: 0;
      flex: 0 0 calc(50% - .5rem);
      &:first-child{
        margin-right: 1rem;
      }
      &:last-child{
        flex 0 0 100%;
        margin-top: 1rem;
      }
    }
  }
`;

export const AddressSwapButton = styled.button`
  display: flex;
  align-items: center;
  margin: 1rem 0 2rem 0;
  padding: 1.1rem 2.25rem;
  background: none;
  cursor: pointer;
  user-select: none;
  border: 1.5px solid #a7a7a7;
  border-radius: 10px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #777;
  letter-spacing: 0;

  ${(props) =>
    props.disabled &&
    css`
      color: #ccc;
      cursor: not-allowed;
      ${NotebookIcon} {
        display: none;
      }
    `}

  &:hover {
    border-color: #b7b7b7;
    border-size: 2px;
    color: #777;
    ${(props) =>
      props.disabled &&
      css`
        color: #ccc;
        border-color: #e7e7e7;
      `}
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-top: 2rem;
    border-width: 1px;
  }
`;
export const MapIcon = styled(MapSVG)`
  height: 22px;
  width: 22px;
  margin-right: 1.5rem;
`;

export const NotebookIcon = styled(NotebookSVG)`
  height: 22px;
  width: 22px;
  margin-right: 1.5rem;
`;
