import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { completeOrderStart } from "redux/checkout/checkout-actions.js";
import {
  selectAddressField,
  selectActivePaymentMethodID,
  selectActiveAddressID,
} from "redux/checkout/checkout-selector.js";
import {
  selectActiveDeliveryTime,
  selectIsProcessingOrder,
} from "redux/checkout/checkout-selector";
import CoinBanner from "components/coin-banner/coin-banner.jsx";
import CheckoutCart from "components/checkout-cart/checkout-cart.jsx";
import CheckoutAddress from "components/checkout-address/checkout-address.jsx";
import CheckoutTime from "components/checkout-time/checkout-time.jsx";
import CheckoutPayment from "components/checkout-payment/checkout-payment.jsx";
import CustomButton from "components/custom-button/custom-button.jsx";
import { CheckoutPage, CheckoutContainer } from "./checkout.styles.jsx";
import RazorpayContainer from "components/razorpay/razorpay.container.jsx";

const Checkout = ({
  history,
  addressField,
  activePaymentMethodID,
  activeAddressID,
  completeOrder,
  activeDeliveryTime,
  isProcessingOrder,
}) => {
  const [isOnlinePaymentModeActive, setIsOnlinePaymentModeActive] = useState(
    false
  );
  const {
    FirstName,
    LastName,
    Address,
    PhoneNumber,
    isNewCustomerAddress,
  } = addressField;

  const isDisabled = () => {
    return (
      !activeDeliveryTime?.id ||
      (!isNewCustomerAddress && !activeAddressID) ||
      (isNewCustomerAddress &&
        (FirstName === "" ||
          LastName === "" ||
          Address === "" ||
          PhoneNumber === ""))
    );
  };

  const handleSubmit = (event) => {
    if (isDisabled()) return;
    completeOrder(history);
    if (activePaymentMethodID === 2) {
      setIsOnlinePaymentModeActive(true);
    }
  };

  return (
    <>
      <CheckoutPage>
        <CheckoutCart />
        <CheckoutContainer>
          <CoinBanner />
          <CheckoutAddress />
          <CheckoutTime />
          <CheckoutPayment />
          <CustomButton
            disabled={isDisabled()}
            onClick={() => handleSubmit()}
            secondary
            green
            loader={isProcessingOrder}
          >
            Buy Now
          </CustomButton>
        </CheckoutContainer>
      </CheckoutPage>
      {isOnlinePaymentModeActive && <RazorpayContainer />}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  addressField: selectAddressField,
  activePaymentMethodID: selectActivePaymentMethodID,
  activeAddressID: selectActiveAddressID,
  activeDeliveryTime: selectActiveDeliveryTime,
  isProcessingOrder: selectIsProcessingOrder,
});

const mapDispatchToProps = (dispatch) => ({
  completeOrder: (history) => dispatch(completeOrderStart(history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);
