import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { categoriesListFetchStart } from "redux/shop/shop-actions.js";
import SubCategoriesInfinteOverview from "components/subcategories-infinite-overview/subcategories-infinite-overview.jsx";
import {
  selectIsFetchingCategoriesList,
  selectCategoriesListFetchError,
  selectSubCategoriesArray,
} from "redux/shop/shop-selector.js";

const SubCategoriesOverview = ({
  categoriesListFetch,
  isFetchingCategoriesList,
  subCategoriesList,
  categoriesListFetchError,
}) => (
  <SubCategoriesInfinteOverview
    subCategoriesListFetch={categoriesListFetch}
    isFetchingSubCategoriesList={isFetchingCategoriesList}
    subCategoriesList={subCategoriesList}
    subCategoriesListFetchError={categoriesListFetchError}
  />
);

const mapStateToProps = (state, ownProps) => ({
  isFetchingCategoriesList: selectIsFetchingCategoriesList(state),
  subCategoriesList: selectSubCategoriesArray(
    ownProps.match.params.parentCategoryURL
  )(state),
  categoriesListFetchError: selectCategoriesListFetchError(state),
});

const mapDispatchToProps = (dispatch) => ({
  categoriesListFetch: () => dispatch(categoriesListFetchStart()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubCategoriesOverview)
);
