import styled, { css } from "styled-components";
import { ReactComponent as AddToCartIconSVG } from "assets/cart.svg";
import { ReactComponent as PlusSVG } from "assets/plus-thin.svg";
import { ReactComponent as MinusSVG } from "assets/minus-thin.svg";
import { ReactComponent as CloseSVG } from "assets/close.svg";

export const Footer = styled.div`
  min-height: 5rem;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  border-top: 1px solid #e3e3e3;
  width: 100%;
  margin: 2rem 0 0 0;
  ${(props) =>
    props.highlight &&
    css`
      border-top: none;
      margin: 1rem 0;
    `}
  @media screen and (max-width: 480px) {
    margin: 1rem 0 0 0;
    ${(props) =>
      props.highlight &&
      css`
        margin: 0.5rem 0 1rem;
      `}
  }
`;

export const AddToCartButton = styled.div`
  color: #555;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  font-weight: 600;
  font-size: 1.5rem;
  background: none;
  border: 1px solid #e3e3e3;
  border-radius: 15px;
  padding: 0.4rem 2.5rem;

  @media screen and (max-width: 480px) {
    font-weight: 500;
  }

  &:hover {
    background: #efffdd;
  }

  &:active {
    transform: translateY(1.8px);
    box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.15);
  }

  ${(props) =>
    props.unavailable &&
    `
		cursor: default;
		border: none;
		
		&:hover{
			background:none;
		}

		&:active{
			transform: translate(0,0);
			box-shadow: 0 .5px 1px rgba(0,0,0,0);
		}
	`}
`;

export const AddToCartIcon = styled(AddToCartIconSVG)`
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
`;

export const ErrorIcon = styled(CloseSVG)`
  width: 12px;
  height: 12px;
  margin-right: 1rem;
`;

export const CartButtonOptions = styled.div`
	display: flex;
	justify-content: space-around;
	font-weight: 600;
	font-size: 1.4rem;
	width: 50%
	align-items: center;

	span{
		font-size: 1.85rem;
		user-select: none;
		font-family: 'Montserrat', sans-serif;
		font-weight:400;
	}

	& > * {
		display: block;
	}

	@media screen and (max-width: 768px){
		width: 70%;
	}
`;

const CartOptionIconStyle = css`
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  cursor: pointer;
  padding: 0.67rem;
`;

export const PlusIcon = styled(PlusSVG)`
  ${CartOptionIconStyle}
  fill: green;
  ${(props) =>
    props.unavailable &&
    `
		fill: gray;
		cursor: not-allowed;
	`}
`;

export const MinusIcon = styled(MinusSVG)`
  ${CartOptionIconStyle}
  fill: #f33;
  ${(props) =>
    props.disabled &&
    `
		fill: gray;
		cursor: not-allowed;
	`}
`;
