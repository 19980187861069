import React from 'react';
import {Button} from './custom-button.styles.jsx'


const CustomButton = React.forwardRef((props, ref) => (
  <Button ref={ref} {...props}>
    {props.children}
  </Button>
));


export default CustomButton
