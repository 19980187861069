export const checkoutOrderStart = (history) => ({
  type: "CHECKOUT_ORDER_START",
  payload: { history },
});

export const checkoutOrderSuccess = (deliveryTime) => ({
  type: "CHECKOUT_ORDER_SUCCESS",
  payload: { deliveryTime },
});

export const checkoutOrderFailure = (error) => ({
  type: "CHECKOUT_ORDER_FAILURE",
  payload: error,
});

export const redeemVoucherStart = (voucherCode) => ({
  type: "REDEEM_VOUCHER_START",
  payload: { voucherCode },
});

export const redeemVoucherSuccess = (voucherCode) => ({
  type: "REDEEM_VOUCHER_SUCCESS",
  payload: { voucherCode },
});

export const redeemVoucherFailure = () => ({
  type: "REEDEEM_VOUCHER_FAILURE",
});

export const clearVoucherState = () => ({
  type: "CLEAR_VOUCHER_STATE",
});

export const fetchCustomerAddressStart = () => ({
  type: "FETCH_CUSTOMER_ADDRESS_START",
});

export const fetchCustomerAddressSuccess = (customerAddress) => ({
  type: "FETCH_CUSTOMER_ADDRESS_SUCCESS",
  payload: customerAddress,
});

export const fetchCustomerAddressFailure = (error) => ({
  type: "FETCH_CUSTOMER_ADDRESS_FAILURE",
  payload: error,
});

export const setActiveAddressID = (activeAddressID) => ({
  type: "SET_ACTIVE_ADDRESS_ID",
  payload: activeAddressID,
});

export const setCustomerAddressField = (customerAddress) => ({
  type: "SET_CUSTOMER_ADDRESS_FIELD",
  payload: customerAddress,
});

export const clearCustomerAddressField = () => ({
  type: "CLEAR_CUSTOMER_ADDRESS_FIELD",
});

export const deleteCustomerAddressStart = (addressID) => ({
  type: "DELETE_CUSTOMER_ADDRESS_START",
  payload: { addressID },
});

export const deleteCustomerAddressSuccess = () => ({
  type: "DELETE_CUSTOMER_ADDRESS_SUCCESS",
});

export const setActiveDeliveryTime = (activeDeliveryTime) => ({
  type: "SET_ACTIVE_DELIVERY_TIME",
  payload: activeDeliveryTime,
});

export const setActivePaymentMethodID = (activePaymentID) => ({
  type: "SET_ACTIVE_PAYMENT_METHOD_ID",
  payload: activePaymentID,
});

export const completeOrderStart = (history) => ({
  type: "COMPLETE_ORDER_START",
  payload: { history },
});

export const completeOrderSuccess = (
  orderID,
  razorpayOrderID,
  earnedSilverCoins
) => ({
  type: "COMPLETE_ORDER_SUCCESS",
  payload: { orderID, razorpayOrderID, earnedSilverCoins },
});

export const setOrderStatus = (statusCode) => ({
  type: "SET_ORDER_STATUS",
  payload: statusCode,
});

export const fetchPaymentStatus = (rzpPaymentID, history) => ({
  type: "FETCH_PAYMENT_STATUS",
  payload: { rzpPaymentID, history },
});

export const onlinePaymentFailureRecoverState = (razorpayPaymentID) => ({
  type: "ONLINE_PAYMENT_FAILURE_RECOVER_STATE",
  payload: razorpayPaymentID,
});

export const fetchUserOrdersStart = () => ({
  type: "FETCH_USER_ORDERS_START",
});

export const fetchUserOrdersSuccess = (userOrders) => ({
  type: "FETCH_USER_ORDERS_SUCCESS",
  payload: userOrders,
});

export const fetchOrderDetailStart = (orderID) => ({
  type: "FETCH_ORDER_DETAIL_START",
  payload: orderID,
});

export const fetchOrderDetailSuccess = (orderID, orderDetail) => ({
  type: "FETCH_ORDER_DETAIL_SUCCESS",
  payload: { orderID, orderDetail },
});

export const cancelOrderStart = (orderID) => ({
  type: "CANCEL_ORDER_START",
  payload: { orderID },
});

export const cancelOrderSuccess = (orderID) => ({
  type: "CANCEL_ORDER_SUCCESS",
  payload: { orderID },
});
