import { all, call, put, select, take, takeLatest } from "redux-saga/effects";
import {
  checkoutOrder,
  verifyVoucher,
  getDeliveryAddress,
  addDeliveryAddress,
  setDefaultCustomerAddress,
  deleteCustomerAddress,
  placeOrder,
  getPaymentStatus,
  getUserOrderList,
  getOrderDetail,
  cancelOrder,
} from "backend/api.js";
import {
  checkoutOrderSuccess,
  redeemVoucherSuccess,
  redeemVoucherFailure,
  clearVoucherState,
  fetchCustomerAddressSuccess,
  fetchCustomerAddressFailure,
  deleteCustomerAddressSuccess,
  setActiveAddressID,
  setCustomerAddressField,
  clearCustomerAddressField,
  setActiveDeliveryTime,
  completeOrderSuccess,
  setOrderStatus,
  onlinePaymentFailureRecoverState,
  fetchOrderDetailStart,
  fetchUserOrdersSuccess,
  fetchOrderDetailSuccess,
  cancelOrderSuccess,
} from "./checkout-actions.js";
import { fmtCartResponse } from "redux/cart/cart-utils";
import { fmtDeliveryTime } from "redux/checkout/checkout-utils";
import { fetchUserCartSuccess, clearCart } from "redux/cart/cart-actions.js";
import { setUserPrime } from "redux/user/user-actions";
const selectAccessToken = (state) => state.user.access_token;
const selectCityID = (state) => state.shop.activeLocale.city.id;
const selectLocationID = (state) => state.shop.activeLocale.location.id;
const selectCartID = (state) => state.cart.cartID;
const selectVoucherCode = (state) => state.checkout.voucher?.voucherCode;
const selectAddressField = (state) => state.checkout.addressField;
const selectActiveAddressID = (state) => state.checkout.activeAddressID;
const selectActivePaymentID = (state) => state.checkout.activePaymentMethodID;
const selectActiveDeliveryTime = (state) => state.checkout.activeDeliveryTime;
const selectIsProcessingOrder = (state) => state.checkout.isProcessingOrder;
const selectOrderID = (state) => state.checkout.orderID;
// const selectIsCancellingOrder = (state) => state.checkout.isCancellingOrder;

export function* checkout() {
  try {
    let accessToken = yield select(selectAccessToken);
    if (!accessToken) yield take("LOGIN_SUCCESS");
    accessToken = yield select(selectAccessToken);
    const cityID = yield select(selectCityID);
    const locationID = yield select(selectLocationID);
    const cartID = yield select(selectCartID);
    const voucherCode = yield select(selectVoucherCode);
    const paymentID = yield select(selectActivePaymentID);

    if (!cartID) return; // Temp fix - prevent checkout firing before addCartID

    const {
      cart,
      address: customerAddress,
      dates: deliveryTime,
    } = yield checkoutOrder(
      cartID,
      cityID,
      locationID,
      accessToken,
      voucherCode,
      paymentID
    );
    yield put(fetchUserCartSuccess(fmtCartResponse(cart)));
    if (cart.prime) yield put(setUserPrime());
    if (customerAddress.id) {
      yield put(setActiveAddressID(customerAddress.id));
    } else {
      yield put(setCustomerAddressField({ isNewCustomerAddress: true }));
    }
    const deliveryTimeFmt = fmtDeliveryTime(deliveryTime);
    yield put(checkoutOrderSuccess(deliveryTimeFmt));
    yield put(setActiveDeliveryTime(deliveryTimeFmt[0]));
  } catch (error) {
    console.log(error);
  }
}

export function* onCheckout() {
  yield takeLatest("CHECKOUT_ORDER_START", checkout);
}

export function* onCheckoutStateChange() {
  yield takeLatest(
    [
      "ADD_TO_CART_SUCCESS",
      "UPDATE_CART_ITEM_COUNT",
      "SET_ACTIVE_PAYMENT_METHOD_ID",
      "REDEEM_VOUCHER_SUCCESS",
      "REEDEEM_VOUCHER_FAILURE",
      "CLEAR_VOUCHER_STATE",
    ],
    checkout
  );
}

export function* redeemVoucher({ payload: { voucherCode } }) {
  try {
    const cityID = yield select(selectCityID);
    yield verifyVoucher(voucherCode, cityID);
    yield put(redeemVoucherSuccess());
  } catch (error) {
    yield put(redeemVoucherFailure());
  }
}

export function* onRedeemVoucher() {
  yield takeLatest("REDEEM_VOUCHER_START", redeemVoucher);
}

export function* fetchCustomerAddress() {
  try {
    const cityID = yield select(selectCityID);
    const locationID = yield select(selectLocationID);
    const accessToken = yield select(selectAccessToken);
    const { data: customerAddress } = yield getDeliveryAddress(
      cityID,
      locationID,
      accessToken
    );
    yield put(fetchCustomerAddressSuccess(customerAddress));
  } catch (error) {
    yield put(fetchCustomerAddressFailure(error));
  }
}

export function* onFetchCustomerAddress() {
  yield takeLatest("FETCH_CUSTOMER_ADDRESS_START", fetchCustomerAddress);
}

export function* onDeleteCustomerAddressSuccess() {
  yield takeLatest("DELETE_CUSTOMER_ADDRESS_SUCCESS", fetchCustomerAddress);
}

export function* setActiveCustomerAddress({ payload: addressID }) {
  try {
    const accessToken = yield select(selectAccessToken);
    yield setDefaultCustomerAddress(addressID, accessToken);
  } catch (e) {
    console.log(e);
  }
}

export function* onSetActiveCustomerAddress() {
  yield takeLatest("SET_ACTIVE_ADDRESS_ID", setActiveCustomerAddress);
}

export function* deleteCustomerAddressSaga({ payload: { addressID } }) {
  try {
    const accessToken = yield select(selectAccessToken);
    yield deleteCustomerAddress(addressID, accessToken);
    yield put(deleteCustomerAddressSuccess());
  } catch (e) {
    console.log(e);
  }
}

export function* onDeleteCustomerAddress() {
  yield takeLatest("DELETE_CUSTOMER_ADDRESS_START", deleteCustomerAddressSaga);
}

export function* completeOrder({ payload: { history } }) {
  const cityID = yield select(selectCityID);
  const locationID = yield select(selectLocationID);
  const cartID = yield select(selectCartID);
  const addressField = yield select(selectAddressField);
  let addressID = yield select(selectActiveAddressID);
  const paymentID = yield select(selectActivePaymentID);
  const deliveryTime = yield select(selectActiveDeliveryTime);
  const accessToken = yield select(selectAccessToken);
  const {
    firstName,
    lastName,
    address,
    phoneNumber,
    isNewCustomerAddress,
  } = addressField;

  try {
    if (isNewCustomerAddress) {
      const addDeliveryAddressBody = {
        name: `${firstName} ${lastName}`,
        mobile: phoneNumber,
        location_id: locationID,
        city_id: cityID,
        address_1: address,
        address_2: "",
        type: 1,
        pincode: "",
      };

      const { address_id } = yield addDeliveryAddress(
        addDeliveryAddressBody,
        accessToken
      );
      addressID = address_id;
    }
    const {
      order_id: orderID,
      razorpay_order_id: razorpayOrderID,
      cart: { earned_silver_coins: earnedSilverCoins },
    } = yield placeOrder(
      cartID,
      locationID,
      addressID,
      deliveryTime,
      paymentID,
      accessToken
    );
    yield put(
      completeOrderSuccess(orderID, razorpayOrderID, earnedSilverCoins)
    );
    if (paymentID === 1) {
      yield put(clearCart());
      yield put(clearVoucherState());
      yield put(clearCustomerAddressField());
      yield put(setOrderStatus(4));
      history.push("/orderstatus");
    }
  } catch (error) {
    console.log(error);
    yield put(setOrderStatus(1));
    history.push("/orderstatus");
  }
}

export function* onCompleteOrder() {
  yield takeLatest("COMPLETE_ORDER_START", completeOrder);
}

export function* fetchPaymentStatus({ payload: { rzpPaymentID, history } }) {
  const accessToken = yield select(selectAccessToken);
  const orderID = yield select(selectOrderID);
  try {
    yield getPaymentStatus(orderID, rzpPaymentID, accessToken);
    yield put(clearCart());
    yield put(clearVoucherState());
    yield put(clearCustomerAddressField());
    yield put(setOrderStatus(4));
  } catch (error) {
    yield put(onlinePaymentFailureRecoverState(rzpPaymentID));
    yield put(setOrderStatus(3));
    history.push("/orderstatus");
    console.log("Payment Verification Failure", error);
  }
}

export function* onFetchPaymentStatus() {
  yield takeLatest("FETCH_PAYMENT_STATUS", fetchPaymentStatus);
}

export function* fetchUserOrders() {
  const isProcessingOrder = yield select(selectIsProcessingOrder);
  if (isProcessingOrder) {
    yield take("COMPLETE_ORDER_SUCCESS");
  }
  const accessToken = yield select(selectAccessToken);
  try {
    const { data: userOrders } = yield getUserOrderList(accessToken);
    yield put(fetchUserOrdersSuccess(userOrders));
  } catch (e) {
    console.log(e);
  }
}
export function* onFetchUserOrders() {
  yield takeLatest("FETCH_USER_ORDERS_START", fetchUserOrders);
}

export function* fetchOrderDetail({ payload: orderID }) {
  const accessToken = yield select(selectAccessToken);
  try {
    const { data: orderDetail } = yield getOrderDetail(orderID, accessToken);
    yield put(fetchOrderDetailSuccess(orderID, orderDetail));
  } catch (e) {
    console.log(e);
  }
}

export function* onFetchOrderDetail() {
  yield takeLatest("FETCH_ORDER_DETAIL_START", fetchOrderDetail);
}

export function* cancelOrderSaga({ payload: { orderID } }) {
  const accessToken = yield select(selectAccessToken);
  try {
    yield cancelOrder(orderID, accessToken);
    yield put(cancelOrderSuccess());
    yield put(fetchOrderDetailStart(orderID));
  } catch (e) {
    console.log(e);
  }
}

export function* onCancelOrder() {
  yield takeLatest("CANCEL_ORDER_START", cancelOrderSaga);
}

export default function* checkoutSagas() {
  yield all([
    call(onCheckout),
    call(onCheckoutStateChange),
    call(onRedeemVoucher),
    call(onFetchCustomerAddress),
    call(onSetActiveCustomerAddress),
    call(onCompleteOrder),
    call(onFetchPaymentStatus),
    call(onDeleteCustomerAddress),
    call(onDeleteCustomerAddressSuccess),
    call(onFetchUserOrders),
    call(onFetchOrderDetail),
    call(onCancelOrder),
  ]);
}
