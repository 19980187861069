import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ParentCategoriesMenuContainer from "components/parent-categories-menu/parent-categories-menu.container.jsx";
import BrandMenu from "components/brands-menu/brand-menu.jsx";
import { HomePageContainer } from "./homepage.styles.jsx";
import HeroSlider from "components/hero-slider/hero-slider.jsx";
import SubCategoriesInfinteOverview from "components/subcategories-infinite-overview/subcategories-infinite-overview.jsx";
import CollectionPreview from "components/collection-preview/collection-preview.jsx";
import { homePageFetchStart } from "redux/shop/shop-actions.js";
import {
  selectIsFetchingHomePage,
  selectHomePage,
} from "redux/shop/shop-selector.js";
import useWindowSize from "hooks/useWindowSize";

const HomePage = ({ homePageFetchStart, isFetchingHomePage, homePage }) => {
  useEffect(() => {
    homePageFetchStart();
  }, [homePageFetchStart]);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 480;

  const calcHighlightCount = (items) =>
    isMobile
      ? items.length >= 4
        ? items.slice(0, 4)
        : items.slice(0, 2)
      : items.slice(0, 4);

  return (
    <HomePageContainer>
      <ParentCategoriesMenuContainer />
      {/*
      <WideBanner>
        <h1>
          Stay home &amp; we deliver <br /> Your daily needs.
        </h1>
        <p>Start your daily shopping with Goodoor</p>
			</WideBanner>
			*/}
      {!isMobile && homePage?.twoColBannerBg ? (
        <HeroSlider heroBanner={homePage.twoColBannerBg} />
      ) : null}
      {homePage?.featured && (
        <CollectionPreview
          title="Featured Products"
          url="/home/featured"
          productsList={calcHighlightCount(homePage?.featured)}
          highlight
          isProductsListFetchError={null}
          handleErrorRetry={homePageFetchStart}
        />
      )}
      {isMobile && homePage?.mainBanner ? (
        <HeroSlider heroBanner={homePage.mainBanner} />
      ) : null}
      {homePage?.featuredBrands && (
        <BrandMenu menuItems={homePage.featuredBrands} />
      )}
      {homePage?.bestdeals && (
        <CollectionPreview
          title="Best Deals"
          url="/home/bestdeals"
          productsList={calcHighlightCount(homePage?.bestdeals)}
          highlight
          isProductsListFetchError={null}
          handleErrorRetry={homePageFetchStart}
        />
      )}
      {isMobile && homePage?.primeBanner && (
        <HeroSlider single heroBanner={homePage.primeBanner} />
      )}

      {homePage?.offerSale && (
        <SubCategoriesInfinteOverview
          subCategoriesListFetch={homePageFetchStart}
          isFetchingSubCategoriesList={isFetchingHomePage}
          subCategoriesList={homePage.offerSale}
          subCategoriesListFetchError={null}
        />
      )}
      {isMobile && homePage?.twoColBannerBg ? (
        <HeroSlider heroBanner={homePage.twoColBannerBg} />
      ) : null}
      {homePage?.popularItems && homePage?.popularItems && (
        <SubCategoriesInfinteOverview
          subCategoriesListFetch={homePageFetchStart}
          isFetchingSubCategoriesList={isFetchingHomePage}
          subCategoriesList={homePage.popularItems}
          subCategoriesListFetchError={null}
        />
      )}
      {isMobile && homePage?.extAdvertBanner ? (
        <HeroSlider single heroBanner={homePage.extAdvertBanner} />
      ) : null}
      {homePage?.featuredCategory && homePage?.featuredCategory && (
        <SubCategoriesInfinteOverview
          subCategoriesListFetch={homePageFetchStart}
          isFetchingSubCategoriesList={isFetchingHomePage}
          subCategoriesList={homePage.featuredCategory}
          subCategoriesListFetchError={null}
        />
      )}
      {isMobile && homePage?.fullBanner && (
        <HeroSlider single heroBanner={homePage.fullBanner} />
      )}
    </HomePageContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  isFetchingHomePage: selectIsFetchingHomePage,
  homePage: selectHomePage,
});

const mapDispatchToProps = (dispatch) => ({
  homePageFetchStart: () => dispatch(homePageFetchStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
