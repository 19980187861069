import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import WithSpinner from "../../components/with-spinner/with-spinner.component.jsx";
import RazorpayCompononent from "./razorpay.jsx";
import { selectRazorypayOrderID } from "../../redux/checkout/checkout-selector.js";

const RazorpayContainer = ({ isOrderID }) => (
  <RazorpayCompononent isLoading={!isOrderID} />
);

const mapStateToProps = createStructuredSelector({
  isOrderID: selectRazorypayOrderID,
});

export default connect(mapStateToProps)(WithSpinner(RazorpayContainer));
