import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { setActiveDeliveryTime } from "redux/checkout/checkout-actions.js";
import {
  selectDeliveryTime,
  selectActiveDeliveryTime,
} from "redux/checkout/checkout-selector.js";
import { selectIsCartPrimeOnly } from "redux/cart/cart-selector";
import {
  Title,
  SelectBoxGroup,
  SelectBoxItem,
} from "pages/checkout/checkout.styles.jsx";
import { DeliveryIcon } from "./checkout-time.styles.jsx";

const CheckoutTime = ({
  deliveryTime,
  setActiveDeliveryTime,
  activeDeliveryTime,
  isCartPrimeOnly,
}) => {
  return (
    <>
      <Title>Delivery Slot</Title>
      <SelectBoxGroup>
        {deliveryTime.length === 0 ? (
          <SelectBoxItem>No Active Delivery Slot</SelectBoxItem>
        ) : (
          deliveryTime.map((deliveryTime) => (
            <SelectBoxItem
              key={`${deliveryTime.date}-${deliveryTime.id}`}
              selected={
                deliveryTime.date === activeDeliveryTime.date &&
                deliveryTime.id === activeDeliveryTime.id
              }
              disabled={isCartPrimeOnly}
              onClick={() => setActiveDeliveryTime(deliveryTime)}
            >
              <DeliveryIcon disabled={isCartPrimeOnly} />
              <span>{deliveryTime.time.toUpperCase()}</span>
            </SelectBoxItem>
          ))
        )}
      </SelectBoxGroup>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  deliveryTime: selectDeliveryTime,
  activeDeliveryTime: selectActiveDeliveryTime,
  isCartPrimeOnly: selectIsCartPrimeOnly,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveDeliveryTime: (activeDeliveryTime) =>
    dispatch(setActiveDeliveryTime(activeDeliveryTime)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTime);
