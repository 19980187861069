import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as LogoSVG } from "assets/logo.svg";
import { ReactComponent as LogoTextSVG } from "assets/logo-text.svg";
import { ReactComponent as MailSVG } from "assets/paper-plane.svg";
import { ReactComponent as InstagramSVG } from "assets/instagram.svg";
import { ReactComponent as FacebookSVG } from "assets/facebook.svg";
import { ReactComponent as WhatsAppSVG } from "assets/whatsapp.svg";

export const FooterContainer = styled.div`
  margin-top: auto;
  padding: 5rem;
  background: #222;
  @media screen and (max-width: 480px) {
    padding: 5rem 3rem;
  }
`;

export const Container = styled.div`
  max-width: 1380px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Column = styled.div`
  @media screen and (max-width: 768px) {
    flex: 0 0 calc(50%);
    &:nth-child(even) {
      padding-left: 10rem;
    }
  }
  @media screen and (max-width: 480px) {
    flex: 0 0 100%;
    padding-left: 0 !important;
  }
  &:not(:last-child) {
    margin: 0 5rem 0 0;
    @media screen and (max-width: 768px) {
      margin: 0;
    }
  }
  & > * {
    margin: 1.15rem 0;
  }
`;

export const Logo = styled(LogoSVG)`
  fill: #fff;
  height: 30px;
  width: 30px;
  margin-right: 1rem;
`;

export const LogoText = styled(LogoTextSVG)`
  height: 30px;
  width: 120px;
  path {
    fill: #fff;
  }
`;
export const Info = styled.p`
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.5;
  @media screen and (max-width: 768px) {
    margin: 1.7rem 0;
  }
`;

export const LinkItem = styled(Link)`
  color: #fff;
  font-size: 2rem;
  display: block;
  font-weight: 400;
`;

export const ExternalLink = styled.a`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1.9rem;
  cursor: pointer;
`;

const IconCSS = css`
  height: 16px;
  width: 16px;
  fill: #fff;
  margin-right: 1.5rem;
`;

export const MailIcon = styled(MailSVG)`
  ${IconCSS}
`;

export const InstagramIcon = styled(InstagramSVG)`
  ${IconCSS}
`;

export const WhatsAppIcon = styled(WhatsAppSVG)`
  ${IconCSS}
`;

export const FacebookIcon = styled(FacebookSVG)`
  ${IconCSS}
`;
