import styled from "styled-components";
import { ReactComponent as ShoppingIcon } from "assets/trolley.svg";

export const CartIconContainer = styled.div`
  cursor: pointer;
  position: relative;
  @media screen and (min-width: 1024px) {
    order: 2;
  }
  @media screen and (max-width: 768px) {
    margin-right: 3.5rem;
  }
  @media screen and (max-width: 480px) {
    margin-right: 2.5rem;
  }
`;

export const CartIconSVG = styled(ShoppingIcon)`
  height: 42px;
  width: 42px;
  fill: #fff;
  @media screen and (max-width: 1024px) {
    height: 36px;
    width: 36px;
  }
`;
export const CartIconText = styled.span`
  font-family: "Montserrat", sans-serif;
  position: absolute;
  top: 5%;
  right: -22px;
  border-radius: 50%;
  height: 25px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2ec370;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  transform: translateX(-50%);
  @media screen and (max-width: 480px) {
    height: 22px;
    width: 22px;
  }
`;
