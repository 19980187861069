import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SubCategoryContainer, BreadCrumbs } from "./sub-category.styles.jsx";
import { productsListFetchStart } from "redux/shop/shop-actions.js";
import {
  selectCategoryName,
  selectSubCategoryName,
  selectIsFetchingProductsList,
  selectProductsListArray,
} from "redux/shop/shop-selector.js";
import ProductsOverview from "components/products-overview/products-overview.jsx";
import useTruncate from "hooks/truncate.js";

const SubCategory = ({
  productsListFetchStart,
  parentCategoryName,
  subCategoryName,
  isFectchingProductsList,
  products,
  history,
  match,
}) => {
  const { parentCategoryURL, subCategoryURL } = match.params;
  useEffect(() => {
    productsListFetchStart(subCategoryURL, parentCategoryURL);
  }, [productsListFetchStart, parentCategoryURL, subCategoryURL]);

  return (
    <SubCategoryContainer>
      <BreadCrumbs>
        <span onClick={() => history.push(`/`)}>Home </span>
        <span onClick={() => history.push(`/shop/${parentCategoryURL}`)}>
          {useTruncate(parentCategoryName, 14)}
        </span>
        <span onClick={() => history.push(match.url)}>
          {useTruncate(subCategoryName, 14)}
        </span>
      </BreadCrumbs>
      <ProductsOverview
        products={products}
        isFetching={isFectchingProductsList}
        fullpage
      />
    </SubCategoryContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  productsListFetchStart: (subCategoryURL, parentCategoryURL) =>
    dispatch(productsListFetchStart(subCategoryURL, parentCategoryURL)),
});

const mapStateToProps = (
  state,
  {
    match: {
      params: { subCategoryURL, parentCategoryURL },
    },
  }
) => ({
  parentCategoryName: selectCategoryName(parentCategoryURL)(state),
  subCategoryName: selectSubCategoryName(
    parentCategoryURL,
    subCategoryURL
  )(state),
  isFectchingProductsList: selectIsFetchingProductsList(
    subCategoryURL,
    parentCategoryURL
  )(state),
  products: selectProductsListArray(subCategoryURL, parentCategoryURL)(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubCategory)
);
