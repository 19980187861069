import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./user/user-reducer.js";
import cartReducer from "./cart/cart-reducer.js";
import shopReducer from "./shop/shop-reducer.js";
import checkoutReducer from "./checkout/checkout-reducer.js";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "cart", "checkout"],
};

const shopPersistConfig = {
  key: "shop",
  storage,
  whitelist: ["activeLocale"],
};

const rootReducer = combineReducers({
  user: userReducer,
  cart: cartReducer,
  shop: persistReducer(shopPersistConfig, shopReducer),
  checkout: checkoutReducer,
});

export default persistReducer(persistConfig, rootReducer);
