import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router";
import {
  OrderStatusContainer,
  OrderSuccessVector,
  OrderErrorVector,
  PaymentFailedVector,
  PaymentSuccessOrderFail,
  Body,
  Title,
  Text,
} from "./orderstatus.styles.jsx";
import CustomButton from "../../components/custom-button/custom-button.jsx";
import {
  selectOrderStatus,
  selectOrderID,
  selectRazorpayPaymentID,
  selectEarnedSilverCoins,
} from "../../redux/checkout/checkout-selector.js";
import {
  setOrderStatus,
  fetchPaymentStatus,
} from "../../redux/checkout/checkout-actions.js";

const OrderStatus = ({
  history,
  orderStatus,
  orderID,
  earnedSilverCoins,
  setOrderStatus,
  razorpayPaymentID,
  getPaymentStatus,
}) => {
  useEffect(() => {
    return () => {
      setOrderStatus(0); // O is OrderStatus for No Active Orders.
    };
  }, [setOrderStatus]);

  switch (orderStatus) {
    case 0:
      return <h1>No Active Orders</h1>;
    case 1:
      return (
        <OrderStatusContainer column>
          <OrderErrorVector />
          <Body>
            <Title>Sorry, Unable To Process Order!</Title>
            <CustomButton
              secondary
              red
              onClick={() => history.push("/checkout")}
            >
              Try Again
            </CustomButton>
          </Body>
        </OrderStatusContainer>
      );
    case 2:
      return (
        <OrderStatusContainer column>
          <PaymentFailedVector />
          <Body>
            <Title>Payment Failed!</Title>
            <CustomButton
              secondary
              red
              onClick={() => history.push("/checkout")}
            >
              Try Again
            </CustomButton>
          </Body>
        </OrderStatusContainer>
      );
    case 3:
      return (
        <OrderStatusContainer column>
          <PaymentSuccessOrderFail />
          <Body>
            <Title>Payment Verification Failed!</Title>
            <Text>
              It Appears the Payment might have been successful, But Either The
              payment Verification Failed Or Our Server Didn't Respond
            </Text>
            <CustomButton
              secondary
              red
              onClick={() => {
                getPaymentStatus(razorpayPaymentID, history);
              }}
            >
              Resend
            </CustomButton>
          </Body>
        </OrderStatusContainer>
      );
    case 4:
      return (
        <OrderStatusContainer>
          <OrderSuccessVector />
          <Body>
            <Title>Your Order Was Successfully Processed</Title>
            {earnedSilverCoins > 0 && (
              <Text>You Have Earned {earnedSilverCoins} Silver Coins</Text>
            )}
            <CustomButton
              secondary
              green
              onClick={() => history.push(`/orders/${orderID}`)}
            >
              View Order
            </CustomButton>
          </Body>
        </OrderStatusContainer>
      );
    default:
      return <div>No Status</div>;
  }
};

const mapStateToProps = createStructuredSelector({
  orderStatus: selectOrderStatus,
  orderID: selectOrderID,
  razorpayPaymentID: selectRazorpayPaymentID,
  earnedSilverCoins: selectEarnedSilverCoins,
});

const mapDispatchToProps = (dispatch) => ({
  setOrderStatus: (orderStatus) => dispatch(setOrderStatus(orderStatus)),
  getpaymentStatus: (rzpOrderID, rzpPaymentID, history) =>
    dispatch(fetchPaymentStatus(rzpOrderID, rzpPaymentID, history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderStatus)
);
