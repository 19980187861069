import React from "react";
import { connect } from "react-redux";
import { addItemToCart } from "../../redux/cart/cart-actions.js";
import {
  selectIsPrimeInCart,
  selectCartItemCount,
} from "../../redux/cart/cart-selector";
import { Card } from "./prime-card.styles.jsx";
import CustomButton from "../custom-button/custom-button.jsx";

const PrimeCard = ({ plan, addToCart, isPrimeInCart, cartItemCount }) => {
  return (
    <Card key={plan.id}>
      <h2>
        <span>₹</span>
        {plan.offer_price}
      </h2>
      <h3>{plan.validity}</h3>
      {plan.benefits.map((benefit, index) => (
        <h4 key={index}>{benefit}</h4>
      ))}
      <CustomButton
        secondary
        green
        disabled={isPrimeInCart && cartItemCount === 0}
        onClick={() => {
          if (isPrimeInCart || cartItemCount === 1) return;
          addToCart({
            productName: `${plan.name} - ${plan.validity}`,
            productImage: "/assets/badge.png",
            sellingPrice: plan.offer_price,
            primePrice: plan.offer_price,
            variationID: plan.id,
            cartItemCount: 1,
            isPrime: true,
          });
        }}
      >
        {cartItemCount === 1 ? "Added" : "Add To Cart"}
      </CustomButton>
    </Card>
  );
};

const mapStateToProps = (state, { plan: { id } }) => ({
  cartItemCount: selectCartItemCount(id)(state),
  isPrimeInCart: selectIsPrimeInCart(state),
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (productData) => dispatch(addItemToCart(productData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrimeCard);
