import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  addItemToCart,
  reduceItemFromCartStart,
} from "redux/cart/cart-actions.js";
import { selectIsPrimeUser } from "redux/user/user-selector";
import {
  CartListItemContainer,
  Image,
  Info,
  Title,
  Qty,
  Price,
  RemoveButtonContainer,
  RemoveButton,
} from "./cart-list-item.styles.jsx";
import useWindowSize from "hooks/useWindowSize.js";

const CartListItem = ({
  cartItem,
  reduceItemFromCart,
  addItemToCart,
  wide,
  isPrimeUser,
}) => {
  const { width } = useWindowSize();
  const {
    productName,
    sellingPrice,
    primePrice,
    productImage,
    cartItemCount,
  } = cartItem;

  return (
    <CartListItemContainer wide={wide}>
      <Image>
        <img src={productImage} alt={productName} />
      </Image>
      <Info>
        <Title>
          {!wide
            ? productName.length > 15
              ? productName.search(/[\u0D00-\u0D7F]/g) === 0
                ? productName.toLowerCase().slice(0, 17).concat("..")
                : width < 480
                ? productName.toLowerCase().slice(0, 20).concat("..")
                : productName.toLowerCase().slice(0, 23).concat("..")
              : productName.toLowerCase()
            : productName.length > 25
            ? productName.search(/[\u0D00-\u0D7F]/g) === 0
              ? productName.toLowerCase().slice(0, 22).concat("..")
              : productName.toLowerCase().slice(0, 28).concat("..")
            : productName.toLowerCase()}
        </Title>
        <Qty>
          <span onClick={() => reduceItemFromCart(cartItem)}>&#10094;</span>
          {cartItemCount}
          <span onClick={() => addItemToCart(cartItem)}>&#10095;</span>
        </Qty>
        <Price>
          ₹
          {((isPrimeUser ? primePrice : sellingPrice) * cartItemCount).toFixed(
            2
          )}
        </Price>
        <RemoveButtonContainer>
          <RemoveButton
            onClick={() => reduceItemFromCart({ ...cartItem, quantity: 0 })}
          >
            &#10006;
          </RemoveButton>
        </RemoveButtonContainer>
      </Info>
    </CartListItemContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  isPrimeUser: selectIsPrimeUser,
});

const mapDispatchToProps = (dispatch) => ({
  reduceItemFromCart: (item) => dispatch(reduceItemFromCartStart(item)),
  addItemToCart: (item) => dispatch(addItemToCart(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartListItem);
