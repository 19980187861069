import React, { useState } from "react";
import {
  CollectionsOverviewContainer,
  CollectionsErrorContainer,
} from "./subcategories-infinite-overview.styles.jsx";
import RetryBox from "components/retry-box/retry-box.jsx";
import SpinnerOverlay from "components/with-spinner/spinner-overlay.jsx";
import SubCategoryPreview from "components/subcategory-preview/subcategory-preview.jsx";
import InfiniteScroll from "react-infinite-scroller";
import useProductsOverviewCount from "hooks/getProductsOverviewCount";

const SubCategoriesInfiniteOverview = ({
  isFetchingSubCategoriesList,
  subCategoriesListFetch,
  subCategoriesList,
  subCategoriesListFetchError,
}) => {
  const [subCategoriesPaginated, setSubCategoriesPaginated] = useState([]);
  const [loaderState, setLoaderState] = useState({
    per: 3,
    page: 1,
    hasMore: true,
  });
  const { per, page, hasMore } = loaderState;

  const loadSubCategoriesPaginated = () => {
    const paginate = (array, page_size, page_number) => {
      --page_number;
      const sliced = array.slice(
        page_number * page_size,
        (page_number + 1) * page_size
      );
      if (sliced.length === 0) {
        setLoaderState((prevState) => ({ ...prevState, hasMore: false }));
        return [];
      }
      return sliced;
    };
    const data = paginate(subCategoriesList, per, page);
    setSubCategoriesPaginated([...subCategoriesPaginated, ...data]);
  };

  const loadMore = () => {
    setLoaderState(
      (prevState) => ({ ...prevState, page: prevState.page + 1 }),
      loadSubCategoriesPaginated()
    );
  };

  const productsOverviewCount = useProductsOverviewCount();

  if (isFetchingSubCategoriesList) {
    return <SpinnerOverlay />;
  } else if (subCategoriesListFetchError) {
    return (
      <CollectionsErrorContainer>
        <RetryBox handleErrorRetry={subCategoriesListFetch} />
      </CollectionsErrorContainer>
    );
  } else {
    return (
      <CollectionsOverviewContainer>
        <InfiniteScroll pageStart={0} loadMore={loadMore} hasMore={hasMore}>
          {subCategoriesPaginated.map((subCategory) => (
            <SubCategoryPreview
              subCategory={subCategory}
              productsOverviewCount={productsOverviewCount}
              key={subCategory.id}
            />
          ))}
        </InfiniteScroll>
      </CollectionsOverviewContainer>
    );
  }
};

export default SubCategoriesInfiniteOverview;
