import styled from "styled-components";
import Img from "react-image";

export const OrderDetailContainer = styled.div`
  background: #fff;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.05);
  max-width: 880px;
  margin: 0 auto;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  button {
    margin-left: auto;
    @media screen and (max-width: 480px) {
      order: 1;
    }
  }
  h3:first-child {
    @media screen and (max-width: 480px) {
      margin-top: 2rem;
    }
  }
  h3:last-child {
    margin-left: 2rem;
    @media screen and (max-width: 480px) {
      margin-left: 0;
    }
  }
`;

export const Title = styled.div`
  font-size: 1.7rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 1.5rem 0;
  color: #777;
  letter-spacing: 1px;
`;

export const OrderCart = styled.div`
  max-height: 27.5rem;
  overflow-y: scroll;
`;

export const CartItem = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
  border-bottom: 1px solid #e7e7e7;

  @media screen and (max-width: 480px) {
    padding: 1.5rem 1rem;
  }

  h3:nth-child(2) {
    flex: 0 0 40%;
  }
  h3 {
    margin-left: 2rem;
    min-width: 5rem;
    @media screen and (max-width: 480px) {
      margin-left: 1rem;
      min-width: 4rem;
    }
  }
  h3:last-child {
    margin-left: auto;
  }
`;

export const Image = styled(Img)`
  height: 32px;
  width: 32px;
`;

export const Text = styled.h3`
  font-size: 1.7rem;
  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export const Address = styled.p`
  margin-bottom: 2.5rem;
  line-height: 1.5;
  font-size: 1.6rem;
  margin-left: 0.75rem;
`;
