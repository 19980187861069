import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ProductsByBrandContainer, ErrorContainer } from "./brands.styles.jsx";
import {
  selectFeaturedBrands,
  selectProductsByBrandError,
  selectProductsByBrandList,
} from "redux/shop/shop-selector.js";
import ProductsOverview from "components/products-overview/products-overview.jsx";
import {
  homePageFetchStart,
  productsByBrandFetchStart,
} from "redux/shop/shop-actions.js";
import RetryBox from "components/retry-box/retry-box.jsx";

const ProductsByBrand = ({
  featuredBrands,
  productsList,
  homePageFetch,
  productsByBrandFetch,
  productsByBrandFetchError,
  match,
}) => {
  const brandURL = match.params.url;
  const brandID = featuredBrands?.[brandURL]?.id || null;
  useEffect(() => {
    if (productsList.length === 0) productsByBrandFetch(brandURL);
  }, [productsByBrandFetch, productsList.length, brandURL]);

  useEffect(() => {
    if (!brandID) homePageFetch();
  }, [homePageFetch, brandID]);

  return (
    <ProductsByBrandContainer>
      {productsByBrandFetchError ? (
        <ErrorContainer>
          <RetryBox handleErrorRetry={productsByBrandFetch} />
        </ErrorContainer>
      ) : (
        <ProductsOverview products={productsList} />
      )}
    </ProductsByBrandContainer>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { url },
    },
  }
) => ({
  featuredBrands: selectFeaturedBrands(state),
  productsList: selectProductsByBrandList(url)(state),
  productsByBrandFetchError: selectProductsByBrandError(url)(state),
});

const mapDispatchToProps = (dispatch) => ({
  homePageFetch: () => dispatch(homePageFetchStart()),
  productsByBrandFetch: (brandURL) =>
    dispatch(productsByBrandFetchStart(brandURL)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductsByBrand)
);
