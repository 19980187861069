import React from "react";
import { PageContainer } from "./common.styles.jsx";

const ConditionsPage = () => (
  <PageContainer>
    <h1>TERMS AND CONDITIONS</h1>
    <h3>Personal Information</h3>
    <p>
      Goodoor Industries Pvt Ltd is the licensed owner of the brand goodoor and
      the website goodoor.in. As a visitor to the Site/Application Customer you
      are advised to please read the Privacy Policy carefully. By accessing the
      services provided by the Site/Application you agree to the collection and
      use of your data by Goodoor Industries Pvt Ltd in the manner provided in
      this Privacy Policy. As part of the registration process on the
      Application, Goodoor Industries Pvt Ltd may collect the following
      personally identifiable information about you: Name including first and
      last name, alternate email address, mobile phone number and contact
      details, Postal code, Demographic profile (like your age, gender,
      occupation, education, address etc.) and information about the pages on
      the site you visit/access, the links you click on the site, the number of
      times you access the page and any such browsing information.
    </p>
    <h3>Eligibility</h3>
    <p>
      Services of the Site/Application would be available to only select
      geographies in kerala, India. Persons who are "incompetent to contract"
      within the meaning of the Indian Contract Act, 1872 including
      un-discharged insolvents etc. are not eligible to use the
      Site/Application. If you are a minor i.e. under the age of 18 years but at
      least 13 years of age you may use the Site only under the supervision of a
      parent or legal guardian who agrees to be bound by these Terms of Use. If
      your age is below 18 years your parents or legal guardians can transact on
      behalf of you if they are registered users. You are prohibited from
      purchasing any material which is for adult consumption and the sale of
      which to minors is prohibited.
    </p>
    <h3>Account Registration &amp; Obligations</h3>
    <p>
      All buyers need to sign up and login for putting orders on the
      Site/Application. You must hold your account and registration info present
      day and correct for communications related to your purchases from the
      utility. By agreeing to the terms and situations, the consumer consents to
      receive promotional communication and newsletters upon registration.
    </p>
    <h3>Pricing</h3>
    <p>
      All the products listed on the Site/Application will be sold at MRP unless
      otherwise specified. The prices mentioned at the time of ordering will be
      the prices charged on the date of the delivery. Although prices of most of
      the products do not fluctuate on a daily basis but some of the commodities
      and fresh food prices do change on a daily basis. In case the prices are
      higher or lower on the date of delivery not additional charges will be
      collected or refunded as the case may be at the time of the delivery of
      the order.
    </p>
    <h3>Cancellation by Site / Customer</h3>
    <p>
      You as a consumer can cancel your order each time up to the cut-off time
      of the slot for that you have positioned an order by calling our customer
      service. In this sort of case we can refund any bills already made by
      means of you for the order. If we suspect any fraudulent transaction by
      means of any customer or any transaction which defies the terms &amp;
      conditions of using the internet site/utility, we at our sole discretion
      could cancel such orders. We will preserve a negative listing of all
      fraudulent transactions and customers and might deny get admission to to
      them or cancel any orders located by using them
    </p>
    <h3>Shipping &amp; Delivery</h3>
    <p>
      Products ordered through goodoor application for delivery are shipped
      through our delivery executives. For delivery purpose customers personal
      information will transfer to delivery executives. In normal case, we
      deliver products to you within 12 hours. The shipping time mentioned in
      the application/site are approximate and we can not guarantee them.
      Customers are recommended to place an order early on if they have a
      deadline to meet. Normally goodoor site/application has two delivery
      slots. 10am-1pm &amp; 3pm-6pm. There may be delay will happen by dint of
      weather conditions.
    </p>
    <h3>Return &amp; Refunds</h3>
    <p>
      We have a "no questions asked return and refund policy" which entitles all
      our members to return the product at the time of delivery if due to some
      reason they are not satisfied with the quality or freshness of the
      product(Genuine reasons only accepted). We will take the returned product
      back with us and issue new products to customer. In case of cancellation,
      the amount will be transferred to the customer within 5 working days.
    </p>
    <h3>You Agree and Confirm</h3>
    <p>
      That within the occasion that a non-delivery occurs as a result of a
      mistake via you (i.e. Wrong call or cope with or any other wrong
      statistics) any greater value incurred by means of Goodoor for redelivery
      shall be claimed from you. That you will use the offerings furnished by
      using the Site/application, its affiliates, specialists and shriveled
      organizations, for lawful functions most effective and follow all
      applicable laws and policies at the same time as using and transacting at
      the Site/software. You will provide authentic and genuine facts in all
      instances in which such data is requested of you. Goodoor reserves the
      right to verify and validate the information and different information
      provided by using you at any point of time. If upon confirmation your info
      are observed no longer to be real (totally or in part), it has the right
      in its sole discretion to reject the registration and debar you from the
      usage of the Services and /or other affiliated websites/application with
      out earlier intimation in any way. That you're accessing the offerings to
      be had in this Site/Application and transacting at your sole chance and
      are the use of your best and prudent judgment earlier than entering into
      any transaction via this Site. That the deal with at which shipping of the
      product ordered through you is to be made may be accurate and right in all
      respects. That earlier than putting an order you'll test the product
      description carefully. By placing an order for a product you settle to be
      certain by means of the situations of sale covered in the item's
      description.
    </p>
    <h3>You May Not Use The Site / Application for The Following Purposes</h3>
    <p>
      Disseminating any unlawful, harassing, libelous, abusive, threatening,
      harmful, vulgar, obscene, or otherwise objectionable material.
      Transmitting material that encourages conduct that constitutes a criminal
      offence or results in civil liability or otherwise breaches any relevant
      laws, regulations or code of practice. Gaining unauthorized access to
      other computer systems. Interfering with any other person's use or
      enjoyment of the Site.
    </p>
    <h3>Pictures &amp; Colours</h3>
    <p>
      Goodoor have made each effort to show the goods and its shades at the web
      site/application as correctly as viable. Goodoor does not guarantee nor
      take obligation for versions in pictorial representations for fresh foods
      and color version due to technical reasons. The images of some products
      are not real. They are only used for pictorial representation and
      marketing.
    </p>
    <h3>Modifications of Terms and Conditions of Service</h3>
    <p>
      Goodoor Industries Pvt Ltd may at any time regulate the Terms &amp;
      Conditions of Use of the application without any previous notification to
      you. You can get admission to the state-of-the-art model of these Terms
      &amp; Conditions at any given time on the Site. You should regularly
      assessment the Terms &amp; Conditions at the Site/utility. In the occasion
      the modified Terms &amp; Conditions isn't ideal to you, you ought to stop
      using the Service. However, in case you hold to apply the Service you
      shall be deemed to have agreed to just accept and abide by means of the
      modified Terms &amp; Conditions of Use of this Site/Application.
    </p>
    <h3>Indemnity</h3>
    <p>
      As a Customer, I hereby agree upon to defend, indemnify and hold harmless
      Goodoor Industries Pvt Ltd, its employees, directors, officers, agents and
      their successors and assigns from and against any and all claims,
      liabilities, damages, losses, costs and expenses, including attorney's
      fees, caused by or arising out of claims based upon my actions or
      inactions, which may result in any loss or liability to Goodoor or any
      third party including but not limited to breach of any warranties,
      representations or undertakings or in relation to the non-fulfilment of
      any obligations under this User Agreement or arising out of violation of
      any applicable laws, regulations including but not limited to Intellectual
      Property Rights, payment of statutory dues and taxes, claim of libel,
      defamation, violation of rights of privacy or publicity, loss of service
      to other subscribers and infringement of intellectual property or other
      rights.   This clause shall survive the expiry or termination of this User
      Agreement. We may terminate this User Agreement at any time, without
      notice or liability to Goodoor Industries Pvt Ltd.
    </p>
  </PageContainer>
);

export default ConditionsPage;
